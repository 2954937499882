import React, { useState, useEffect, useContext  } from 'react';

import TablesTable from './tablesTable';


function TablesHeader(props) {

  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');
  const [search, setSearch] = useState('');


  const addModal = () => {
    setShowAddModal(!showAddModal);
  }

  
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
    }
  };

  const handleSearchChange = (e) => {
    if(e.target.value == ''){
      setSearch(e.target.value)
    }
  };
  



  
  return (
    <div className='card'>

      
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" 
        onKeyDown={(e) => handleSearch(e)}
        onChange={(e) => handleSearchChange(e)}>
        </input>


      <select className="form-control dropDown" onChange={(e) => setIsActive(e.target.value)} >
      <option value='true' >Active</option>
      <option value='false'>Deleted</option>
      </select>


      <button onClick={addModal} type="button" className="btn btn-add"> Add  </button>



      </div>

 
      <TablesTable
      showAddModal={showAddModal}
      addModal={addModal}
      isActive={isActive} 
      search={search} />
      

    </div>
  )
}

export default TablesHeader;
