import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import moment from 'moment';

import ScaleAdd from './crud/scaleAdd';
import ScaleEdit from './crud/scaleEdit';
import ScaleTrash from './crud/scaleTrash';
import ScaleRecover from './crud/scaleRecover';


import { DataContext } from '../../init/getData';

import toast from 'react-hot-toast';

import delete_png from '../../images/trash.png';

import { FiEdit } from "react-icons/fi";
import { FcUndo, FcSurvey   } from "react-icons/fc";

import { FcSettings } from "react-icons/fc";

const dataFormat = require("../../dynamic/date");


function ScaleTable(props) {

  const { api, userData } = useContext(DataContext);

  const { isActive, showAddModal, addModal, search } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);
  const timeoutRef = useRef(null);  

//-----------------------------------------------------------------



  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(!showTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 
  function dateFormatter(e) {
    const date = new Date(e.value);
    const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
    return formattedDate;
  }

 
  useEffect(() => {



  let newColumnDefs = [
    { field: 'id', flex: 0.5},
    { field: 'name'},
    { field: 'digits', flex: 0.6},
    { field: 'flag', flex: 0.6},
    { field: 'code', flex: 0.6},
    { field: 'price', flex: 0.6},
    { field: 'weight', flex: 0.7},
    { field: 'weightPoint'},
    { field: 'moneyPoint'},
    { field: 'countPoint'},
  ];



  setColumnDefs(newColumnDefs); 

  if(isActive == 'true'){
    newColumnDefs.push(
      {field: 'edit', headerName: 'Edt', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
        <FiEdit size={18} style={{cursor: 'pointer'}} onClick={() => editModal(data)} />
        )},
      
      {field: 'delete', headerName: 'Del', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
        <input type="image" src={delete_png} onClick={e => trashModal(data)} width={20} style={{ paddingTop: '10px'}} />
      )},
      );
    }else{
      newColumnDefs.push(      
        {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
          <FcUndo size={25} style={{cursor: 'pointer'}} onClick={() => recoverModal(data)} />
        )},
        );
  
    }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive]);


  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/scale/getAll`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive]);


useEffect(() => { 

  if (search.length > 0) {
  setSearchState(true);

  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  timeoutRef.current = setTimeout(() => {

  var datasource = {
  getRows(params) {

  let data = {
  isActive: isActive,
  search: search,
  };

  api.get(`/category/getall`, {params: data}).then(response => {
  params.successCallback(response.data.data, response.data.totalRecords);
  if(!response.data.status){
  toast.error('Failed to get Data')
  }})}}
  gridApi.api.setServerSideDatasource(datasource);
  }, 1000);
  }else{
  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  if (searchState) onGridReady(gridApi);
  }
  }, [search]);
  
  
  return (
    <div>     
    
    <ScaleAdd showModal={showAddModal}  toggleModal={addModal}  refreshTable={refreshTable} tittle={'Scale'} />
    <ScaleEdit showModal={showEditModal} toggleModal={editModal} refreshTable={refreshTable} tittle={'Scale'}  idRow={idRow}  />
    <ScaleTrash showModal={showTrashModal} toggleModal={trashModal} refreshTable={refreshTable} tittle={'Scale'} idRow={idRow}  />
    <ScaleRecover showModal={showRecoverModal} toggleModal={recoverModal} refreshTable={refreshTable} tittle={'Scale'} idRow={idRow}  />
   
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={200}
        cacheBlockSize={200}
        />
      </div>

      
    </div>
  );
}

export default ScaleTable;
