import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import EndOfDayModal from '../modals/endOfDayModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { creditFormatter, debitFormatter } from '../../components/formatters/defFormatter';

import { Input, Tag, Button } from 'antd';
import AntdSelect from 'antd/lib/select';
import { FcKindle } from "react-icons/fc";

import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';

import { ClockCircleOutlined, HddOutlined, UserOutlined } from '@ant-design/icons';

import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash } from "react-icons/bi";
import { FaDollarSign, FaLink } from "react-icons/fa";
import { FaTurkishLiraSign } from "react-icons/fa6";
import { TbTruckDelivery } from "react-icons/tb";

import { DataContext } from '../../init/getData';
const { TextArea } = Input;

function Sales(props) {

  let { type, isOrder } = props;


  const { userData, settingData, serverApi, localApi, devData, refAllUserData, allUserData, refAccountData, accountData } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const createdBy = userData.id;
  const userRole = userData.role;


  let api;

  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }



  // !server and icloud then local
  // server then local
  // !server and !icloud then server
  useEffect(() => {
    setFilter(prevFilter => ({ ...prevFilter, isOrder: isOrder }));
  }, [type]);




  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today, isOrder: isOrder, createdBy: userRole.viewAllSales? null : createdBy });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEndOfDayModal, setShowEndOfDayModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showCartModal, setShowCartModal] = useState(false);

  const [name, setName] = useState('');
  const [region, setRegion] = useState('');
  const [address, setAddress] = useState('');
  const [delivery, setDelivery] = useState('');
  const [totalUsd, setTotalUsd] = useState(0);
  const [totalLbp, setTotalLbp] = useState(0);
  const [conditions, setConditions] = useState([]);

  const table = 'transaction';
  const title = 'Sales';


  //Get Data refs 
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!devData.isCloud) {
          await refAllUserData();
          await refAccountData();
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);

  const deliveryData = accountData.filter(item => item.type === 'Delivery');

  // Delivery Options
  const deliveryOptions = deliveryData?.map(item => ({
    value: item.id,
    label: `${item?.name} [${item?.phone}]`,
  }))
  deliveryOptions.push({ value: null, label: 'All ' + devData.deliveryName })
  const selectedDelivery = deliveryOptions.filter(option => option.value === filter.deliveryId);


  // Users Options
  const userOptions = allUserData?.map(item => ({
    value: item.id,
    label: item?.name,
  }))
  userOptions.push({ value: null, label: 'All Users' })
  const selectedUser = userOptions.filter(option => option.value === filter.createdBy);


  //change Type
  useEffect(() => {
    setFilter(prevFilter => ({ ...prevFilter, type: type, }));
  }, [type]);



  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }


  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const endOfDayModal = (e) => {
    if (!showEndOfDayModal) {
      setIdRow(e);
    }
    setShowEndOfDayModal(!showEndOfDayModal);
  }

  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
      };

      transformedData.push(newItem);
    });

    return {transformedData};
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { 
      title: 'Account', 
      dataIndex: 'account', 
      render: (account, record) => {
        if (devData.isCloud) {
          return record?.accountNameCloud;
        } else {
          return account?.name;
        }
      }
    },
    { 
      title: devData.deliveryName, 
      dataIndex: 'delivery', 
      render: (delivery, record) => {
        if (devData.isCloud) {
          return record?.deliveryNameCloud;
        } else {
          return delivery?.name;
        }
      }
    },
    { title: 'Total In', dataIndex: 'total', width: '8%', render: debitFormatter },
    { title: 'Total Out', dataIndex: 'total', width: '8%', render: creditFormatter },
    { title: 'Discount %', dataIndex: 'discount', width: '8%', render: numberFormatter},
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'User', dataIndex: 'created', render: (created) => (created?.name) },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'View',
          width: '5%',
          render: (_, record) => (
            <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
          ),
        },
        {
          title: 'Trash',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          )
        }
      );
    } else {
      newColumnDefs.push({
        title: 'View',
        width: '5%',
        render: (_, record) => (
          <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
        ),
      },
    //  {
   //     title: 'Del',
     //   width: '5%',
    //    render: (_, record) => (
     //     <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />
    //    )
    // }
    );
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);





  const formView = (<div>

  </div>);




  const modalFooter = (
    <div>


    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        {data?.summary?.totalSalesCredit > 0 &&
          <span className='table-footer footer-yellow'>Credit: {numberFormatter(data?.summary?.totalSalesCredit, 2)} {settingData.currency}</span>
        }
        <span className='table-footer footer-green'>Cash:   {numberFormatter(data?.summary?.totalSalesCash, 2)} {settingData.currency}</span>
      </div>
    </div>

  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading && filter.type &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />


            <DatePicker setFilter={setFilter} filter={filter} />

            {userRole.viewAllSales &&
              <DataFilter selectedOption={selectedUser} setFilter={setFilter} dataOptions={userOptions} filterBy={'createdBy'} placeHolder={'All Users'} />
            }

            {(isOrder && !devData.isCloud) &&
              <DataFilter selectedOption={selectedDelivery} setFilter={setFilter} dataOptions={deliveryOptions} filterBy={'deliveryId'} placeHolder={'All '+ devData.deliveryName} />
            }



            {!devData.isServer && devData.isCloud &&
              <CustomButton tittle={'end of day'} color={'#e64450'} method={() => setShowEndOfDayModal(!showEndOfDayModal)} />
            }

          </div>



          <SimpleTable
            filter={filter}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}  
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, cart: idRow.cart, transaction: idRow, isCloud: devData.isCloud }}
          />

          {!devData.isServer && devData.isCloud &&
            <EndOfDayModal
              showModal={showEndOfDayModal}
              toggleModal={endOfDayModal}
              idRow={idRow}
              table={table}
              title={title}
              refreshTable={refreshTableFunction}
              api={api}
            />
          }


          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, cart: idRow.cart, transaction: idRow, isCloud: devData.isCloud }}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow?.id }}
          />


          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />


        </>
      }

    </div>
  )
}

export default Sales;
