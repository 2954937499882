import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../../init/getData';
import { CarryOutOutlined, CheckOutlined, FormOutlined, FolderOutlined } from '@ant-design/icons';
import { Select, Switch, Tree } from 'antd';
import { array_1 } from './account_1';
import { array_2 } from './account_2';

import Loader from '../../components/loader/loader';


function Chart() {

  const { api, userData, chartData, refChartData } = useContext(DataContext);

  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refChartData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error Getting Data');
      }
    };
    fetchData();
  }, []);

  function filterDataByLanguage(data, language) {
    const filteredData = [];

    data.forEach(item => {
        const title = item[language];
        if (title) {
            filteredData.push({ id: item.id, title: title });
        }
    });

    return filteredData;
}

const dataArray = [
  { id: 10, title: ' 1' },

  { id: 101, title: ' 1-0' },
  { id: 102, title: ' 1-1' },

  { id: 10101, title: ' 1-0-0' },
  { id: 10102, title: ' 1-0-1' },
  { id: 10103, title: ' 1-0-2' },

  { id: 10201, title: ' 1-1-1' },

  { id: 11, title: ' 2' },
  { id: 110, title: ' 2-1' },
  { id: 11001, title: ' 2-1-1' },
  { id: 11002, title: ' 2-1-2' },
];

const treeData = [
  {
    title: '1',
    key: '1',
    icon: <CarryOutOutlined />,
    children: [
      {
        title: '1-0',
        key: '0-0-0',
        icon: <CarryOutOutlined />,
        children: [
          {
            title: 'leaf',
            key: '0-0-0-0',
            icon: <CarryOutOutlined />,
          },
          {
            title: (
              <>
                <div>multiple line title</div>
                <div>multiple line title</div>
              </>
            ),
            key: '0-0-0-1',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'leaf',
            key: '0-0-0-2',
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        icon: <CarryOutOutlined />,
        children: [
          {
            title: 'leaf',
            key: '0-0-1-0',
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: 'parent 1-2',
        key: '0-0-2',
        icon: <CarryOutOutlined />,
        children: [
          {
            title: 'leaf',
            key: '0-0-2-0',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'leaf',
            key: '0-0-2-1',
            icon: <CarryOutOutlined />,
            switcherIcon: <FormOutlined />,
          },
        ],
      },
    ],
  },
  {
    title: 'parent 2',
    key: '0-1',
    icon: <CarryOutOutlined />,
    children: [
      {
        title: 'parent 2-0',
        key: '0-1-0',
        icon: <CarryOutOutlined />,
        children: [
          {
            title: 'leaf',
            key: '0-1-0-0',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'leaf',
            key: '0-1-0-1',
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
];


  var _a = useState(true),
  showLine = _a[0],
  setShowLine = _a[1];
var _b = useState(false),
  showIcon = _b[0],
  setShowIcon = _b[1];
var _c = useState(true),
  showLeafIcon = _c[0],
  setShowLeafIcon = _c[1];

  if(!isLoading){

  const filteredData =  filterDataByLanguage(chartData, 'english');

  function convertToTreeData(dataArray) {
    const treeData = [];
  
    const findChildren = (parentId) => {
      const children = [];
      for (const item of dataArray) {
        if (Math.floor(item.id / 100) === parentId) {
          const node = {
            title: item.title,
            key: item.id.toString(),
            icon: <CarryOutOutlined />,
          };
          node.children = findChildren(item.id);
          children.push(node);
        }
      }
      return children;
    };
  
    const topLevelParents = dataArray.filter(item => Math.floor(item.id / 100) === item.id / 100);
    const mainParents = topLevelParents.slice(0, 2); // Extracting the first two main parents
  
    for (const item of mainParents) {
      const node = {
        title: item.title,
        key: item.id.toString(),
        icon: <CarryOutOutlined />,
      };
      node.children = findChildren(item.id);
      treeData.push(node);
    }
  
    return treeData;
  }

  const treeData =  convertToTreeData(dataArray);
  console.log("Tree Data:", treeData);

  var onSelect = function (selectedKeys, info) {
    console.log('selected', selectedKeys, info);
  };

  var handleLeafIconChange = function (value) {
    if (value === 'custom') {
      return setShowLeafIcon(React.createElement(CheckOutlined, null));
    }

    if (value === 'true') {
      return setShowLeafIcon(true);
    }

    return setShowLeafIcon(false);
  };

  return React.createElement(
    'div',
    null,
    React.createElement(
      'div',
      { style: { marginBottom: 16 } },
      'showLine: ',
      React.createElement(Switch, { checked: !!showLine, onChange: setShowLine }),
      React.createElement('br', null),
      React.createElement('br', null),
      'showIcon: ',
      React.createElement(Switch, { checked: showIcon, onChange: setShowIcon }),
      React.createElement('br', null),
      React.createElement('br', null),
      'showLeafIcon: ',
      ' ',
      React.createElement(
        Select,
        { defaultValue: 'true', onChange: handleLeafIconChange },
        React.createElement(Select.Option, { value: 'true' }, 'True'),
        React.createElement(Select.Option, { value: 'false' }, 'False'),
        React.createElement(Select.Option, { value: 'custom' }, 'Custom icon')
      )
    ),
    React.createElement(Tree, {
      showLine: showLine ? { showLeafIcon: showLeafIcon } : false,
      showIcon: showIcon,
      defaultExpandedKeys: ['0-0-0'],
      onSelect: onSelect,
      treeData: treeData,
    })
  );
}
};

export default Chart;
