import JsBarcode from 'jsbarcode';
import moment from 'moment';
import { numberFormatter } from './numberFormatter';
import { roundedNumberFormatter } from '../components/formatters/numberFormatter';

const dataFormat = require("../dynamic/date");
const dateNow = new Date(dataFormat.getDateFormatted());
const currentDate = moment.utc(dateNow).format('DD-MM-YY hh:mm A');

const print = async (printData) => {
  const { cart, total, settingData, tva, discount, userName, invoiceId, customer, delivery, devData } = printData;

  let totalTvapaid = 0;
  for (const product of cart) {
    if (product.isTva) {
      totalTvapaid += parseFloat((product.totalPrice * settingData.tva / 100));
    }
  }

  const form = document.createElement("form");

  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, invoiceId, { format: 'CODE128', width: 1, height: 40, displayValue: false });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();
  

  form.innerHTML = `
  <style>
  @media print {
    @page {
      margin: 0;
    }
    body {
      margin: 0;
      padding: 0;
    }
  }
  .receipt {
    width: 95%;
    margin: 0;
    padding: 1%;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
  }
  .header {
    text-align: center;
    margin-bottom: 10px; /* Further reduced bottom margin */
    border-bottom: 1px solid #414448;
    color: black;
    padding-bottom: 10px; /* Minimal padding */
  }
  .header h1,
  .header p {
    margin: 0;
  }
  .header h1 {
    margin-bottom: 2px; /* Reduced margin */
  }
  .header p {
    margin-top: 0;
  }
  .details {
    margin-bottom: 0; /* Removed bottom margin */
    border-bottom: 1px solid #414448;
    padding-bottom: 5px; /* Slightly more padding for visual separation */
    color: black;
    font-size: 1rem;
    width: 100%;
  }
  .detailsFlex {
    display: flex;
    justify-content: space-between;
    color: black;
  }
  .items {
    margin-bottom: 10px; /* Reduced bottom margin */
    padding-bottom: 2px; /* Minimal padding */
    color: black;
  }
  .table {
    width: 100%;
    margin-top: 5px; /* Reduced top margin */
    font-size: 1rem;
  }
  .th {
    padding-top: 5px; /* Reduced top padding */
    text-align: left;
    font-weight: bold;
    color: black;
  }
  .td {
    text-align: left;
    color: black;
    max-width: 180px;
    word-wrap: break-word;
    white-space: normal;
  }
  .discount {
    border-top: 1px solid #414448;
    font-size: 1rem;
    margin-top: 2px; /* Minimized margin between sections */
    padding-top: 2px; /* Minimal padding */
    color: black;
  }
  .summary {
    margin-top: -2px; /* Adjusted negative margin */
    padding-top: 2px; /* Minimal padding */
    font-size: 1rem;
    color: black;
  }
  .total {
    font-weight: 700;
    font-size: 1rem;
  }
  .footer {
    text-align: center;
    font-size: 0.9em;
    color: black;
    padding-top: 2px; /* Minimal padding */
    border-top: 1px solid #ddd;
    width: 100%;
  }
    .footer p {
    margin-bottom: 20px;
}
  .barcode {
    display: block;
    margin: 10px auto;
    width: 150px;
    height: 50px;
  }
  .date {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 5px; /* Reduced bottom margin */
    color: black;
  }
</style>


    <div class="receipt">  
      <header class="header">
        <h1>${settingData.printingName}</h1>
        <p>${settingData.printingAddress}</p>
        <p>${settingData.printingPhone}</p>
      </header>

      <section class="details">
        <div class="detailsFlex">
          <p><strong>Served By:</strong> ${userName}</p>
          <p><strong>Invoice#:</strong> ${invoiceId}</p>
        </div>
      </section>

      ${(customer && customer.value) || (delivery && delivery.value) ? `
        <section class="details">
          ${customer && customer.value ? `
            <div class="detailsFlex">
              <p><strong>Customer:</strong> ${customer.name}</p>
              <p><strong>Phone:</strong> ${customer.phone}</p>
            </div>
            <p><strong>Address:</strong> ${customer.address}</p>
          ` : ''}
          ${delivery && delivery.value ? `
            <div class="detailsFlex">
              <p><strong>${devData.deliveryName}:</strong> ${delivery.name}</p>
              <p><strong>Phone:</strong> ${delivery.phone}</p>
            </div>
          ` : ''}
        </section>
      ` : ''}

      <section class="items">
        <table class="table">
          <thead>
            <tr>
              <th class="th">Item</th>
              <th class="th">Qty</th>
              <th class="th">Dis</th>
              <th class="th">Price</th>
            </tr>
          </thead>
          <tbody>
            ${cart.map((product) => `
              <tr key="${product.id}">
                <td class="td">${product.name}</td>
                <td class="td" >x${product.quantity}</td>
                <td class="td" >${numberFormatter(product.discount, 2)}%</td>
                <td class="td" >${!settingData.isRated ? numberFormatter(product.totalPrice, 2) : roundedNumberFormatter(product.totalPrice * settingData.rate)}</td>
              </tr>
              ${product.details ? `<tr><td colspan="4">${product.details}</td></tr>` : ''}`).join('')}
          </tbody>
        </table>
      </section>

  <section class="discount">
    <div class="detailsFlex">
      <p><strong>Discount:</strong></p>
      <p>${discount}%</p>
    </div>
  </section>

  ${settingData?.defaultTva ? `
    <section class="summary">
      <div class="detailsFlex">
        <p><strong>TVA ${settingData?.tva}%:</strong></p>
        ${!settingData?.isRated ? 
          `<p>${numberFormatter(totalTvapaid)}${settingData?.currency}</p>` : 
          `<p>${roundedNumberFormatter(totalTvapaid * settingData?.rate)}${settingData?.ratedCurrency}</p>`
        }
      </div>
    </section>` : ''
  }

  <section class="summary">
  <div class="detailsFlex">
    <p class="total"><strong>Total:</strong></p>
    <p style="text-align: right; color: black;">
      <!-- Display Total Amount First -->
      <span>${numberFormatter(total, 2)} ${settingData?.currency}</span>
      <br>
      <!-- Conditional Rendering -->
      <span>
        <!-- Check if rated -->
        ${settingData?.isRated ? 
          `${roundedNumberFormatter(total * settingData?.rate, 2)} ${settingData?.ratedCurrency}` : 
          (
            settingData?.showRateOnInvoice ? 
              `${settingData?.rateMultiply ? 
                `${roundedNumberFormatter(total * settingData?.rate, 2)} ${settingData?.ratedCurrency}` : 
                `${numberFormatter(total / settingData?.rate, 2)} ${settingData?.ratedCurrency}`
              }` : 
              ''
          )
        }
      </span>
    </p>
  </div>
</section>




    <footer class="footer">
     <p class="date"}>${currentDate}</p>
    ${barcodeDataUrl ? `<img src="${barcodeDataUrl}" alt="Barcode" class="barcode" />` : ''}
    <p style="text-align: center; color: black;">By Riser Software</p>
    <p style="text-align: center; color: black; margin-top: -20px;">Whatsapp: 78937719</p>
    <p style="text-align: center; color: black; margin-top: -20px;">www.riserteam.com</p>
  </footer>
      </div>`;
  
  const newWindow = window.open("", "_blank", "width=800,height=600");
  newWindow.document.body.appendChild(form);
  await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();
  newWindow.close();
};

const prints = async (printData) => {

  const {cart, total, settingData, tva, discount, userName, invoiceId, customer, delivery} = printData;

  let totalTvapaid = 0;
  for (const product of cart) {
    if (product.isTva) {
    totalTvapaid += parseFloat((product.totalPrice * settingData.tva / 100))
  }
  }

  const form = document.createElement("formid");


  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, invoiceId, {format: 'CODE128', height: 20, displayValue: false, });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();

  form.innerHTML = `

  <div>
    <p style="text-align: center; font-weight: bold;font-size: 20px;">${settingData.printingName}</p>
    <p style="text-align: center; font-size: 13px;">${settingData.printingAddress}</p>
    <p style="text-align:center;"><label >${settingData.printingPhone}</label></p>

    <div style="border-bottom: 1px dashed #000; margin: 10px 0;"></div>

    <div style="display: flex; justify-content: space-between;">
      <a style="text-align:left; font-size: 13px; margin-top: 0;">Served By: ${userName}</a>
    </div>

    <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>

    ${customer && customer.value ?
      `<p style="text-align:left; font-size: 13px;">Customer: &nbsp; &nbsp;${customer.name}</p>
          <p style="text-align:left; font-size: 13px;">Phone: &nbsp; &nbsp;${customer.phone}</p>
          <p style="text-align:left; font-size: 13px;">Address: &nbsp; &nbsp;${customer.address}</p>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

    ${delivery && delivery.value ?
      `<div style="display: flex; justify-content: space-between;">
          <a style="text-align:left; font-size: 13px; margin-top: 0;">Delivery: ${delivery.name}</a>
          <a style="text-align:right; font-size: 13px; margin-bottom: 0;">Price: ${numberFormatter(delivery.price, 2)}${settingData.currency}</a>
          </div>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

    <table style="width: 100%">
      <thead>
        <tr>
          <th style='float: left'>Item</th>
          <th>Qnty</th>
          <th>Dis</th>
          <th>Price</th>
        </tr>
      </thead>

      <tbody>
        ${cart.map((product) => `
          <tr key="${product.id}">
            <td>${product.name}</td>
            <td style="text-align: center;">x${product.quantity}</td>
            <td style="text-align: center;">${numberFormatter(product.discount, 2)}% </td>
            <td style="text-align: center">${numberFormatter(product.totalPrice, 2)}</td>
          </tr>
          ${product.details ? `
          <tr key="${product.id + 999}">
            <td>${product.details}</td>
          </tr>
        ` : ''}
        `).join('')}
      </tbody>
    </table>

    <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>

    ${settingData.defaultTva ?
      `
      <p style="text-align:center; font-size: 10px;">TVA: &nbsp; &nbsp;${settingData.tva}  %</p>
      <p style="text-align:center; font-size: 10px;">Total Tva: &nbsp; &nbsp;${numberFormatter(totalTvapaid, 2)} ${settingData.currency} </p>
      ` : ''
    }

    <p style="text-align:center; font-size: 13px;">Discount: &nbsp; &nbsp;${numberFormatter(discount, 2)} %</p>
    <p style="text-align:center;">Total: &nbsp; &nbsp;${numberFormatter(total, 2)} ${settingData.currency}</p>

    ${settingData.isRated ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${settingData.isRated && settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${!settingData.isRated && !settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total / settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }
  


    <br>
      <p style="text-align: center; margin-bottom: 0px;">${currentDate}</p>
      <p style="text-align: center; margin-bottom: 100px;">Thank you for you visit !!</p>
  </div>
  `;



  const newWindow = window.open();
  newWindow.document.body.appendChild(form);
  if (settingData.printDirect) {
    //webContents.print({silent: false, printBackground: false, deviceName: ''})

  } else {
    await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();
  }

  newWindow.close();





};

const printProductSalesReport = (data) => {
    //console.log(data)
  }

const printBarcode = async (printData) => {

  const {barcode, barCodeSize} = printData;

  const form = document.createElement("formid");

  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, barcode, {format: 'CODE128', height: 150, displayValue: true, });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();

  form.innerHTML = `
  <div>
    <img src="${barcodeDataUrl}" width="${barCodeSize}px" alt="Barcode" style="display: block; margin: 10px auto;">
  </div>
  `;



  const newWindow = window.open();
  newWindow.document.body.appendChild(form);

  await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();


  newWindow.close();





};

const printLabel = async (printData) => {

  const {product, settingData, size} = printData;

  const form = document.createElement("formid");

  // Generate barcode for invoiceId
  //const canvas = document.createElement('canvas');
  //JsBarcode(canvas, barcode, {format: 'CODE128', height: 50, displayValue: true, });
  //const barcodeDataUrl = canvas.toDataURL();

  const name = product.name;
  const price = product.price;

  form.innerHTML = `
  <div>
    <p style="font-size: ${size}px">${name} ${numberFormatter(price, 2)}${settingData.currency}</p>
  </div>
  `;


  const newWindow = window.open();
  newWindow.document.body.appendChild(form);

  await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();


  newWindow.close();





};

  export {
    print,
    printProductSalesReport,
    printBarcode,
    printLabel,
};

