import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import moment from 'moment';

import TableAdd from './crud/tablesAdd';
import TableEdit from './crud/tablesEdit';
import TablesTrash from './crud/tablesTrash';
import TableRecover from './crud/tablesRecover';


import { DataContext } from '../../init/getData';

import toast from 'react-hot-toast';


import delete_png from '../../images/trash.png';
import { FiEdit } from "react-icons/fi";
import { FcUndo, FcSurvey   } from "react-icons/fc";



function TablesTable(props) {

  const { api, userData } = useContext(DataContext);

  const { isActive, showAddModal, addModal, search } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);


//-----------------------------------------------------------------



  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(!showTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 
  function dateFormatter(e) {
    const date = new Date(e.value);
    const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
    return formattedDate;
  }

 
  useEffect(() => {



  let newColumnDefs = [
    { field: 'id'},
    { field: 'name'},
    { field: 'position'},
    { field: 'details'},
  ];



  setColumnDefs(newColumnDefs); 

  if(isActive == 'true'){
    
  newColumnDefs.push(
  
    {field: 'edit', headerName: 'Edt', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
      <FiEdit size={18} style={{cursor: 'pointer'}} onClick={() => editModal(data)} />
      )},
    
    {field: 'delete', headerName: 'Del', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={delete_png} onClick={e => trashModal(data)} width={20} style={{ paddingTop: '10px'}} />
    )},
    );
  }else{
    newColumnDefs.push(      
      {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
        <FcUndo size={25} style={{cursor: 'pointer'}} onClick={() => recoverModal(data)} />
      )},
      );

  }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive, search]);


  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          search: search,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/tables/getAll`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive, search]);


  
  
  return (
    <div>     
    
    <TableAdd showModal={showAddModal}  toggleModal={addModal}  refreshTable={refreshTable} tittle={'Table'} />
    <TableEdit showModal={showEditModal} toggleModal={editModal} refreshTable={refreshTable} tittle={'Table'}  idRow={idRow}  />
    <TablesTrash showModal={showTrashModal} toggleModal={trashModal} refreshTable={refreshTable} tittle={'Table'} idRow={idRow}  />
    <TableRecover showModal={showRecoverModal} toggleModal={recoverModal} refreshTable={refreshTable} tittle={'Table'} idRow={idRow}  />
   
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={60}
        cacheBlockSize={60}
        />
      </div>

      
    </div>
  );
}

export default TablesTable;
