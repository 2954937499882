import toast, { Toaster } from 'react-hot-toast';
import Quantity from '../crud/quantity';



// Search Barcode
const handleBarcode = (event, salableData, setShowProducts, barCodeSearch, setBarCodeSearch, handleAddToCart) => {


  if (event.key === 'Enter') {

    if (parseInt(barCodeSearch.substring(0, 2), 10) === 99) {
      handleScale(salableData, barCodeSearch, handleAddToCart)
    }
    else {

      let filteredProducts = [];
      let filteredBarcode = [];

      filteredProducts = salableData.filter(product => {
        return product.name.toLowerCase().includes(barCodeSearch.toLowerCase());
      });


      filteredBarcode = salableData.filter(product => {
        return product.barcode && product.barcode.toLowerCase() === barCodeSearch.toLowerCase();
      });

      if (filteredBarcode.length > 0) {
        handleAddToCart(filteredBarcode[0], 1);
      }

      if (filteredProducts.length > 0) {
        setShowProducts(filteredProducts);
      }
    }

    setBarCodeSearch('');
  }

};


//  Scale
const handleScale = (salableData, barCodeSearch, handleAddToCart) => {

  let BarCodeProducts = [];

  const barcode = barCodeSearch.substring(2, 7);
  const price = barCodeSearch.substring(7, 12);
  const weight = parseFloat(barCodeSearch.substring(12, 17)) / 1000;
  const digitCheckSum = barCodeSearch.substring(17, 18);


  BarCodeProducts = salableData.filter(product => {
    return product.barcode === barcode;
  });

  if (BarCodeProducts.length > 0) {
    let ScaleProduct = { ...BarCodeProducts[0] };
    ScaleProduct.price = BarCodeProducts[0].price;
    handleAddToCart(ScaleProduct, weight);
  }



};

export {
  handleBarcode,
};