import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Switch } from 'antd';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import  { exportedVisibleDevTabs } from '../../../devTab/devRole/crud/tabsPermission';

const TabsPermission = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle, idRow } = props;

  const { wareHouseData } = useContext(DataContext);


  const [loadingEffect, setLoadingEffect] = useState(false);

  const [settings, setSettings] = useState({});


  useEffect(() => {
    if (idRow) {
      setSettings(
        {
          dashboardTab: idRow.dashboardTab,
          newSaleTab: idRow.newSaleTab,
          dineInTab: idRow.dineInTab,
          salesTab: idRow.salesTab,
          productTab: idRow.productTab,
          accountTab: idRow.accountTab,
          transactionTab: idRow.transactionTab,
          productionTab: idRow.productionTab,
          reportTab: idRow.reportTab,
          cashManagementTab: idRow.cashManagementTab,
          stockManagementTab: idRow.stockManagementTab,
          settingTab: idRow.settingTab,
        }
      )

    }
  }, [idRow]);

  const handleSubmit = async () => {
    setLoadingEffect(true);

    const id = idRow.id;

    let res = await api.post(`/role/editPermissions`, JSON.stringify({ id, settings }))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshTable();
      toggleModal();
    }
    else {
      toast.error(res.data.message);
    }

    setLoadingEffect(false);
  }

  const handleChange = (name, value) => {
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  }



  return (
    <Modal show={showModal} backdrop="static" size='md' onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2939" }}>
        <Modal.Title style={{ color: "white" }}>{idRow.name} Tabs Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{ marginBottom: '10%', marginTop: '5%', alignItems: 'center' }}>
        {exportedVisibleDevTabs.dashboardTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Dashboard Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('dashboardTab', e)} checked={settings.dashboardTab} />
          </Form.Group>
        )}


        {exportedVisibleDevTabs.newSaleTab && (
          <Form.Group as={Row} className="mb-9" >
            <Form.Label column sm={5}>NewSale Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('newSaleTab', e)} checked={settings.newSaleTab} />
          </Form.Group>
        )}


          {exportedVisibleDevTabs.dineInTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Dine In Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('dineInTab', e)} checked={settings.dineInTab} />
          </Form.Group>
            )}

          {exportedVisibleDevTabs.salesTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Sales Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('salesTab', e)} checked={settings.salesTab} />
          </Form.Group>
                )}

          {exportedVisibleDevTabs.productTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Product Tab </Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('productTab', e)} checked={settings.productTab} />
          </Form.Group>
          )}

          
          {exportedVisibleDevTabs.accountTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Account Tab </Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('accountTab', e)} checked={settings.accountTab} />
          </Form.Group>
              )}

          
          {exportedVisibleDevTabs.transactionTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Transaction Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('transactionTab', e)} checked={settings.transactionTab} />
          </Form.Group>
            )}


          {exportedVisibleDevTabs.productionTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Production Tab </Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('productionTab', e)} checked={settings.productionTab} />
          </Form.Group>
          )}

          {exportedVisibleDevTabs.reportTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Reports Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('reportTab', e)} checked={settings.reportTab} />
          </Form.Group>
          )}

          {exportedVisibleDevTabs.cashManagementTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Cash Mgmnt Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('cashManagementTab', e)} checked={settings.cashManagementTab} />
          </Form.Group>

            )}

          {exportedVisibleDevTabs.stockManagementTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Stock Mgmnt Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('stockManagementTab', e)} checked={settings.stockManagementTab} />
          </Form.Group>
              )}

            {exportedVisibleDevTabs.settingTab && (
          <Form.Group as={Row} className="mb-9">
            <Form.Label column sm={5}>Setting Tab</Form.Label>
            <Switch defaultChecked onChange={(e) => handleChange('settingTab', e)} checked={settings.settingTab} />
          </Form.Group>
            )}


          <Form.Group as={Row} className="mb-9">

          </Form.Group>






        </Form>


        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>

          <button type="button" className="btn btn-primary" onClick={handleSubmit}>
            Save Data
          </button>

          {loadingEffect && (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          )}

        </div>


      </Modal.Body>
    </Modal>
  )
}


export default TabsPermission;