import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

const PrinterRecover = (props) => {

const { api, userData, refreshPrinter } = useContext(DataContext);
const { showModal, toggleModal, idRow, refreshTable, tittle } = props;

const [loadingEffect, setLoadingEffect] = useState(false);


  const trigger = async () => {
    setLoadingEffect(true);

    let data = {id: idRow.id}
    let res = await api.post(`printer/recover`, JSON.stringify(data));
    if(res.data.status){
      toast.success(res.data.message);
      refreshPrinter();
      refreshTable();
      toggleModal();
    }else{
      toast.error(res.data.message);
    }

    setLoadingEffect(false);
  }
  

    
  return (
    <Modal show={showModal} onHide={toggleModal} className='Delete_modal'>
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>Recover {tittle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {tittle} Will be Recovered ,
      are you sure ?
      
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>NO</button>
        <button className="btn btn-primary" onClick={trigger}>Yess !!
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>
      </Modal.Footer>
    </Modal>
  );

  }
export default PrinterRecover;
