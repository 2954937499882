

import moment from 'moment';

const dateHourFormatter = (date, type, closingHour) => {
    
  let newDate = new Date(date);
  let utcToDate = '';

  if(type == 'to'){
    if(closingHour > 0){
      utcToDate = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()+1, closingHour, 0, 0, 0);
    }else{
      utcToDate = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 23, 59, 59, 999);
    }
  
  }else{
    utcToDate = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), closingHour, 0, 0, 0);
  }

  let DateFormatted = new Date(utcToDate);
  return DateFormatted;

}


const dateFormatter = (date, type) => {
    
  let newDate = new Date(date);
  let utcToDate = '';

  if(type == 'to'){
    utcToDate = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 23, 59, 59, 999);
  }else{
    utcToDate = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 0, 0, 0, 0);
  }

  let DateFormatted = new Date(utcToDate);
  return DateFormatted;

}


const defaultDateGetter = (defaultDate) => {

  const today = new Date();
  let fromDate = '';
  let toDate = '';
    
if(defaultDate == 'daily'){
  fromDate = today.toISOString().substr(0, 10);
  toDate = today.toISOString().substr(0, 10);

}else
if(defaultDate == 'monthly'){

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  fromDate = firstDayOfMonth.toISOString().substr(0, 10);
  toDate = lastDayOfMonth.toISOString().substr(0, 10);

}else
if(defaultDate == 'yearly'){
  const firstDayOfYear = new Date(today.getFullYear(), 0, 2);
  const lastDayOfYear = new Date(today.getFullYear(), 11, 32);
  fromDate = firstDayOfYear.toISOString().substr(0, 10);
  toDate = lastDayOfYear.toISOString().substr(0, 10);
}

return {

  fromDate,
  toDate,
}

}


function dateViewerFormatter(e) {
  const date = new Date(e.value);
  const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
  return formattedDate;
}

function dateTableFormatter(e) {
  const date = new Date(e);
  const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
  return formattedDate;
}



function datePrintFormatter(e) {
  const date = new Date(e);
  const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
  return formattedDate;
}


function datePrintReportFormatter(e) {
  const date = new Date(e);
  const formattedDate = moment.utc(date).format('DD-MM-YY');
  return formattedDate;
}




export {
  dateHourFormatter,
  dateFormatter,
  defaultDateGetter,
  dateViewerFormatter,
  datePrintFormatter,
  datePrintReportFormatter,
  dateTableFormatter,
};