import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';


const Variable = (props) => {

  const { showModal, toggleModal, setVariant, product, userData, settingData} = props;

  const [stock, setStock] = useState(false);

  const userRole = userData.role;

    // Get total Stock
    useEffect(() => {   
      if(product.variants){
      const wareHouseSale = parseInt(userRole.wareHouseSaleId);
      if(product.variants){
      product.variants.forEach((variant) => {
        if (variant.isStock) {
            let totalStockForVariant = 0;
            variant.stocks.forEach((stockItem) => {
                if (stockItem.wareHouseId === wareHouseSale) {
                    totalStockForVariant += parseInt(stockItem.stock, 10); // Assuming stock is a string, converting to int
                }
            });
            variant.totalStock = totalStockForVariant;
             }
         });
      }
      setStock(true)
    }
    }, [product]);

  
  const handleSubmit = async (variant) => {
    setVariant(variant);
    toggleModal();
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Select Option</Modal.Title>
      </Modal.Header>
      <Modal.Body>


      {/* Products */}
      {product.variants && (
        
<div>
<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: product.variants.length === 1 ? 'flex-end' : 'flex-start' }}>
   
    {stock && product.variants.map((variant, index) => (
      (!variant.isStock || (variant.isStock && variant.totalStock > 0)) && (
        <div className='card' key={variant.id} style={{ justifyContent: 'center', textAlign: 'center', width: '31%', height: '100px', marginLeft: '1', marginBottom: '10px', cursor: 'pointer'}} onClick={() => handleSubmit(variant)} >
          <div style={{ fontSize: '15px', fontWeight:'bold', textAlign: 'center'}}>{variant.name}</div>
          <div style={{ fontSize: '13px', textAlign: 'center'}}>{variant.isStock ? `Stock: ${variant.totalStock}` : null}</div>
          <div style={{ fontSize: '15px', textAlign: 'center', userSelect: 'none', color: 'green', fontFamily : 'sans-serif' }}>{variant.type === 'variable' ? "options" : variant.price.toLocaleString() + " "+ settingData.currency}</div>
        </div>
      )
    ))}

</div>
</div>
    )}




      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={toggleModal}>
          Close
        </button>
      </div>    


      </Modal.Body>
      </Modal>
      )
}


export default Variable;