import React, { useState, useEffect, useContext, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { Button, Checkbox, Form, Input } from 'antd';
import { printBarcode, printLabel } from '../../dynamic/print';
import Select from 'react-select';
import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';

function Barcode() {

  const { settingData, userData, setAccess, salableData, refSalableData } = useContext(DataContext);

  const [showAddModal, setShowAddModal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState('true');

  const [barcode, setBarcode] = useState('');
  const [product, setProduct] = useState('');
  const [size, setSize] = useState('');
  const [barCodeSize, setBarCodeSize] = useState('');
  const barcodeRef = useRef(null);

  useEffect(() => {
    JsBarcode(barcodeRef.current, barcode || 0);
  }, [barcode]);

  // Get Salable data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        await refSalableData();
        setIsLoading(false)
      } catch (error) {
        console.error('Error Getting Data');
      }
    };
    fetchData();
  }, []);


  //Products options
  const productOptions = salableData
    .filter(item => item.type !== 'compound')
    .map(item => ({
      value: item.id,
      label: `${item.sku ? ` (${item.sku}) ` : ''}  ${item.name} ${item.barcode ? ` [${item.barcode}]` : ''}`,
      id: item.id,
      name: item.name,
      category: item.category.name,
      productId: item.id,
      type: item.type,
      name: item.name,
      barcode: item.barcode,
      cost: item.cost,
      isStock: item.isStock,
      stock: item.totalStock,
      price: item.price,
    }))

  return (
    <div className='card' style={{ display: 'flex', height: '88vh', flexDirection: 'row' }}>



      <div className='card' style={{ flex: '1', marginTop: '10vh', height: '60vh' }}>
        <h3>Barcode Generator</h3>
        <svg ref={barcodeRef} />

        <Input placeholder="Basic usage" size='large' value={barcode} onChange={e => setBarcode(e.target.value)} />

        <Input type="number" style={{ marginTop: '5%' }} placeholder="barCodesize" size='large' value={barCodeSize} onChange={e => setBarCodeSize(e.target.value)} />

        <Button type="primary" size="large" onClick={() => printBarcode({ barcode, barCodeSize })}
          style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', backgroundColor: '#1d7790', marginBottom: '0', width: '50%' }}>Print</Button>
      </div>



      <div className='card' style={{ flex: '1', marginTop: '10vh', height: '60vh' }}>
        <h3 >Barcode Label Printer</h3>

        <a style={{ marginBottom: '15%' }}>Choose Product</a>

        <Select
          options={productOptions}
          isSearchable={true}
          placeholder="Select"
          onChange={setProduct}
          value={product}
        />

        <Input type="number" style={{ marginTop: '5%' }} placeholder="size" size='large' value={size} onChange={e => setSize(e.target.value)} />


        <Button type="primary" size="large" onClick={() => printLabel({ product, settingData, size })}
          style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', backgroundColor: '#1d7790', marginBottom: '0', width: '50%' }}>Print</Button>
      </div>

    </div >



  )
}

export default Barcode;
