import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import { DataContext } from '../../../init/getData';
import { Switch } from 'antd';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PagesPermission from '../../role/crud/pagesPermission';

export let exportedVisibleTabs = {};    

const DevPagesPermission = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle, idRow } = props;

  const { wareHouseData } = useContext(DataContext);




  const [settings, setSettings] = useState({});
  const [loadingEffect, setLoadingEffect] = useState(false);

  const [visibleTabs, setVisibleTabs] = useState({
    productPage: true,
    categoryPage: true,
    stockControlPage: true,

    customerPage: true,
    supplierPage: true,
    expensePage: true,
    wareHousePage: true,
    deliveryPage: true,


    customerAccountPage: true,
    supplierAccountPage: true,
    expenseAccountPage: true,
    deliveryAccountPage: true,
    expenseInvoicePage: true,
    salesInvoicePage: true,
    purchaseInvoicePage: true,

    stockActivityPage: true,
    stockValuePage: true,
    warrantyPage: true,

    //report tab
    productSalesPage: true,
    allTransactionsPage: true,
    incomeStatementPage: true,
    cashFlowPage: true,

    generalPage: true,
    userPage: true,
    rolePage: true,
    saleSettingPage: true,
    piSettingPage: true,
    productSettingPage: true,
    lisencePage: true,
  });
  


  
  useEffect(() => {
    if (idRow) {
      setSettings(
        {
          productPage: idRow.productPage,
          categoryPage: idRow.categoryPage,
          stockControlPage: idRow.stockControlPage,

          customerPage: idRow.customerPage,
          supplierPage: idRow.supplierPage,
          expensePage: idRow.expensePage,
          wareHousePage: idRow.wareHousePage,
          deliveryPage: idRow.deliveryPage,

    
          customerAccountPage: idRow.customerAccountPage,
          supplierAccountPage: idRow.supplierAccountPage,
          expenseAccountPage: idRow.expenseAccountPage,
          deliveryAccountPage: idRow.deliveryAccountPage,
          expenseInvoicePage: idRow.expenseInvoicePage,
          salesInvoicePage: idRow.salesInvoicePage,
          purchaseInvoicePage: idRow.purchaseInvoicePage,

          stockActivityPage: idRow.stockActivityPage,
          stockValuePage: idRow.stockValuePage,
          warrantyPage: idRow.warrantyPage,

          //report tab
          productSalesPage: idRow.productSalesPage,
          allTransactionsPage: idRow.allTransactionsPage,
          incomeStatementPage: idRow.incomeStatementPage,
          cashFlowPage: idRow.cashFlowPage,

          generalPage: idRow.generalPage,
          userPage: idRow.userPage,
          rolePage: idRow.rolePage,
          saleSettingPage: idRow.saleSettingPage,
          piSettingPage: idRow.piSettingPage,
          productSettingPage: idRow.productSettingPage,
          lisencePage: idRow.lisencePage,
        }
      );




    }
  }, [idRow]);

  useEffect(() => {
    setVisibleTabs(settings); // Sync visibleTabs with settings
  }, [settings]);

  useEffect(() => {
    exportedVisibleTabs = { ...visibleTabs }; // Spread to ensure a new reference is assigned
  }, [visibleTabs]);
  

  const handleSubmit = async () => {
    setLoadingEffect(true);

    const id = idRow.id;

    let res = await api.post(`/role/editPermissions`, JSON.stringify({ id, settings }))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshTable();
      toggleModal();

    }
    else {
      toast.error(res.data.message);
    }

    setLoadingEffect(false);
  }


  const handleChange = (name, value) => {
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(visibleTabs.productPage);
  }



  return (
    <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>{idRow.name} Pages Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row', marginBottom: '10%' }}>
        
          {/* Product Tab  */}
  
        
          <Form style={{ width: '25%' }}>

            <h4 style={{ textAlign: 'left' }}>Product Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Products</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('productPage', e)} checked={settings.productPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Category</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('categoryPage', e)} checked={settings.categoryPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Stock Control</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('stockControlPage', e)} checked={settings.stockControlPage} />
            </Form.Group>

          </Form>
        

          {/* Account Tab  */}
          <Form style={{ width: '25%' }}>

            <h4 style={{ textAlign: 'left' }}>Accounts Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>


            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Customer</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('customerPage', e)} checked={settings.customerPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Supplier</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('supplierPage', e)} checked={settings.supplierPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Expense</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('expensePage', e)} checked={settings.expensePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>WareHouse</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('wareHousePage', e)} checked={settings.wareHousePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Delivery</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('deliveryPage', e)} checked={settings.deliveryPage} />
            </Form.Group>



          </Form>


          {/* Transaction Tab  */}
          <Form style={{ width: '25%' }}>


            <h4 style={{ textAlign: 'left' }}>Transaction Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Customer</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('customerAccountPage', e)} checked={settings.customerAccountPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Supplier</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('supplierAccountPage', e)} checked={settings.supplierAccountPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Expense</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('expenseAccountPage', e)} checked={settings.expenseAccountPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Delivery</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('deliveryAccountPage', e)} checked={settings.deliveryAccountPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Expense Invoice</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('expenseInvoicePage', e)} checked={settings.expenseInvoicePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Sales Invoice</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('salesInvoicePage', e)} checked={settings.salesInvoicePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Purchase Invoice</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('purchaseInvoicePage', e)} checked={settings.purchaseInvoicePage} />
            </Form.Group>

          </Form>


          {/* Report Tab  */}
          <Form style={{ width: '25%' }}>


            <h4 style={{ textAlign: 'left' }}>Report Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Product Sales</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('productSalesPage', e)} checked={settings.productSalesPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>All Transactions</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('allTransactionsPage', e)} checked={settings.allTransactionsPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Cash Flow</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('cashFlowPage', e)} checked={settings.cashFlowPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Income Statement</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('incomeStatementPage', e)} checked={settings.incomeStatementPage} />
            </Form.Group>

          </Form>



        </div>

        <div style={{ userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row', marginBottom: '10%' }}>

          {/* Stock managment Tab  */}
          <Form style={{ width: '25%' }}>


            <h4 style={{ textAlign: 'left' }}>Report Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Stock Activity</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('stockActivityPage', e)} checked={settings.stockActivityPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Stock Value</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('stockValuePage', e)} checked={settings.stockValuePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Warranty</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('warrantyPage', e)} checked={settings.warrantyPage} />
            </Form.Group>

          </Form>

          {/* Settings Tab  */}
          <Form style={{ width: '25%' }}>


            <h4 style={{ textAlign: 'left' }}>Setting Tab</h4>
            <div style={{ borderTop: '1px solid #cccccc', margin: '10px 0', marginTop: '4%', marginBottom: '15%' }}></div>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>General</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('generalPage', e)} checked={settings.generalPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Users</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('userPage', e)} checked={settings.userPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Role</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('rolePage', e)} checked={settings.rolePage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Sale Settings</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('saleSettingPage', e)} checked={settings.saleSettingPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Pi Settings</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('piSettingPage', e)} checked={settings.piSettingPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Product Settings</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('productSettingPage', e)} checked={settings.productSettingPage} />
            </Form.Group>

            <Form.Group as={Row} className="mb-9">
              <Form.Label column sm={8}>Lisence</Form.Label>
              <Switch defaultChecked onChange={(e) => handleChange('lisencePage', e)} checked={settings.lisencePage} />
            </Form.Group>

          </Form>
    



        </div>

        

        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>

          <button type="button" className="btn btn-primary" onClick={handleSubmit}>
            Save Data
          </button>

          {loadingEffect && (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          )}
        </div>
    
        
      </Modal.Body>
    </Modal>

    
    
  )
}


export default DevPagesPermission;