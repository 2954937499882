


// In isOut
function isOutFormatter(e) {
   
  if(!e.data.isOut){
   return <><span className='type-green'>{e.value}</span></>
  }else {
   return <><span className='type-red'> {e.value}</span></>
  }
  

}





// Sales Product Report
function isOutFormatterTransaction(e) {
   
  if(!e.data.transaction.isOut){
   return <><span className='type-green'>Order</span></>
  }else {
   return <><span className='type-yellow'>Return</span></>
  }
  

}


// Sales Product Report
function isOutFormatterStockActivity(e) {
   
  if(!e.data.transaction.isOut){
   return <><span className='type-red'>{e.data.transaction.type}</span></>
  }else {
   return <><span className='type-green'>{e.data.transaction.type}</span></>
  }
  
}



// Return Credit Cash
function orderFormatter(e) {
  if(e.data.isOut && !e.data.isCredit){
   return <><span className='type-red'>{e.value}</span></>
  }else if(e.data.isCredit){
   return <><span className='type-yellow'> {e.value}</span></>
  }else {
    return <><span className='type-green'> {e.value}</span></>
   }
  

}


// Return Credit Cash
function piFormatter(e) {
  
  if(e.data.isCredit){
   return <><p className='type-yellow'> Credit</p></>
  }else {
    return <p className='type-red'> Cash</p>
   }
  

}



export {
  isOutFormatter,
  isOutFormatterTransaction,
  isOutFormatterStockActivity,
  orderFormatter,
  piFormatter,
};