import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DataContext } from '../../../init/getData';

const ScaleAdd = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;
  
  const { showModal, toggleModal, tittle, refreshTable } = props;

  const {refreshCategory} = useContext(DataContext);

  
  
  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("Form");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    
    let res = await api.post(`/scale/add`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
    refreshCategory();
    form.reset();
    refreshTable();
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add {tittle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Form onSubmit={handleSubmit} id='Form'>


    <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <input type="text"  name="name" id="name" className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
      <Form.Label>Barcode length</Form.Label>
      <input type="number"  name="digits" id="digits" className="form-control"></input>
    </Form.Group>

  </Row>



  <Row className="mb-3" style={{ marginTop: '2%' }}>

    <Form.Group as={Col}>
      <Form.Label>Flag</Form.Label>
      <input type="number"  name="flag" id="flag" className="form-control"></input>
    </Form.Group>

    <Form.Group as={Col}>
      <Form.Label>Code</Form.Label>
      <input type="number"  name="code" id="code" className="form-control"></input>
    </Form.Group>

  </Row>


  <Row className="mb-3" style={{ marginTop: '2%' }}>

<Form.Group as={Col}>
  <Form.Label>Total</Form.Label>
  <input type="number"  name="price" id="price" className="form-control"></input>
</Form.Group>

<Form.Group as={Col}>
  <Form.Label>Weight</Form.Label>
  <input type="number"  name="weight" id="weight" className="form-control"></input>
</Form.Group>

</Row>



<Row className="mb-3" style={{ marginTop: '2%' }}>

<Form.Group as={Col}>
  <Form.Label>Money Point</Form.Label>
  <input type="number"  name="moneyPoint" id="moneyPoint" className="form-control"></input>
</Form.Group>

<Form.Group as={Col}>
  <Form.Label>Weight Point</Form.Label>
  <input type="number"  name="weightPoint" id="weightPoint" className="form-control"></input>
</Form.Group>

<Form.Group as={Col}>
  <Form.Label>Count Point</Form.Label>
  <input type="number"  name="countPoint" id="countPoint" className="form-control"></input>
</Form.Group>

</Row>





    <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>

      </Form>

      </Modal.Body>
      </Modal>
      )
}


export default ScaleAdd;