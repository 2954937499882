import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import { DataContext } from '../../init/getData';


const TotalDiscount = (props) => {

  const { showModal, toggleModal, changeFinalDiscount} = props;

  const { api,  settingData, userData } = useContext(DataContext);
  const userRole = userData.role;


  const handleSubmit = async (e) => {
    e.preventDefault();
    let value= parseFloat(document.getElementById('value').value);

    changeFinalDiscount(value);
    toggleModal();
  
  }

  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add Discount</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form onSubmit={handleSubmit} id='addForm'>
   
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">&nbsp; &nbsp; &nbsp; &nbsp;%</label>
      <div className="col-sm-10">
      <input type="number" min="0" max={userRole.allowedDiscount} step="any" defaultValue='' name="value" id="value" className="form-control" autoFocus></input>
      </div>
      </div>
    

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Add Discount"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default TotalDiscount;