import React, { useState, useEffect, useRef, createContext } from 'react';
import toast, {Toaster} from 'react-hot-toast';

import Menu from '../menu/menu';


export const CartContext = createContext();

const CartData = (props) => {

  const {settingData, userData} = props;

  const [allCart, setCart] = useState([[]]);  
 
  const [editCartArray, setEditCartArray] = useState([[]]);           // Store Edit Cart for quantity comparison for stock
  const [editModeIdArray, setEditModeIdArray] = useState([0]);        // Edit Mode Id
  const [editModeArray, setEditModeArray] = useState([false]);        // Enable Edit Mode

  const [returnModeArray, setReturnModeArray] = useState([false]);    // Enable Return Mode

  const [tvaArray, setTvaArray] = useState([settingData.defaultTva]);      
  const [wholeSaleArray, setWholeSaleArray] = useState([false]);              // Store Tva 
  const [finalDiscountArray, setFinalDiscountArray] = useState([0]);  // Store Discount
  const [total, setTotal] = useState(0);                              // Store Total
  const [finalNetTotal, setFinalNetTotal] = useState(0);              // Store Final Total

  const [selectedTab, setSelectedTab] = useState(0);

  const [tabArray, setTabArray] = useState([0]);

  const [isPrint, setIsPrint] = useState(true);




  const mainTab ={
    cart: [],
    isPrint: true,
    saleData : {
      createdBy: userData.id,
      wareHouseId: userData.role.wareHouseSaleId,
      accountId: null,
      total: 0,
      rate: settingData.rate,
      tva: 0,
      type: null,
      isOut: false,
      isCredit: false,
      isPaid: true,
      discount: 0,
      deliveryId: null,
    },
    options:{
      isTva: false,
      isReturn: false,
    }
  }

  const [tab, setTab] = useState([mainTab]);  

  // Final Discount
  const setFinalDiscount = (index, discount) => {
    const updatedFinalDiscountArray = [...finalDiscountArray];
    updatedFinalDiscountArray[index] = discount;
    setFinalDiscountArray(updatedFinalDiscountArray);
  }

  // Return Mode
  const setReturnMode = (index) => {
    const updatedReturnArray = [...returnModeArray];
    updatedReturnArray[index] = !updatedReturnArray[index];
    setReturnModeArray(updatedReturnArray);
  }


  // Set Tva
  const setTva = (index) => {
    const updatedTvaArray = [...tvaArray];
    updatedTvaArray[index] = !updatedTvaArray[index];
    setTvaArray(updatedTvaArray);
  }

    // Set WholeSale
    const setWholeSale = (index) => {
      const updatedWholeSaleArray = [...wholeSaleArray];
      updatedWholeSaleArray[index] = !updatedWholeSaleArray[index];
      setWholeSaleArray(updatedWholeSaleArray);
    }


  // Edit Mode
  const setEditMode = (id , cart, isReturn, discount) => {

    if (editModeIdArray.includes(id)) {
      toast.error('already opened in editor');
      return;
    }

    addNewTab();
    const index = tabArray.length;

    setCartAtIndex( index, cart);

    const updatedEditArray = [...editModeArray];
    const updatedEditIdArray = [...editModeIdArray];
    const updatedFinalDiscountArray = [...finalDiscountArray];
    const updatedRetrunModeArray = [...returnModeArray];
    const updatedEditCartArray = [...editCartArray];

    updatedEditArray[index] = !updatedEditArray[index];
    setEditModeArray(updatedEditArray);

    updatedEditIdArray[index] = id;
    setEditModeIdArray(updatedEditIdArray);

    updatedFinalDiscountArray[index] = parseFloat(discount);
    setFinalDiscountArray(updatedFinalDiscountArray);

    updatedRetrunModeArray[index] = isReturn;
    setReturnModeArray(updatedRetrunModeArray);

    updatedEditCartArray[index] = cart;
    setEditCartArray(updatedEditCartArray);

  }


  // Set Cart
  const setCartAtIndex = (index, newArray) => {
    const updatedCart = [...allCart];
    updatedCart[index] = newArray;
    setCart(updatedCart);
  }





    // Add new Tab
    const addNewTab = () => {
  
      console.log('added')
      if (tabArray.length > 5) {
        toast.error('max 6 tabs');
        return;
      }
      const updatedReturnModeArray = [...returnModeArray, false];
      setReturnModeArray(updatedReturnModeArray);
  
      const updatedEditModeArray = [...editModeArray, false];
      setEditModeArray(updatedEditModeArray);
  
      const updatedEditModeIdArray = [...editModeIdArray, 0];
      setEditModeIdArray(updatedEditModeIdArray);
  
      const updatedFinalDiscountArray = [...finalDiscountArray, 0];
      setFinalDiscountArray(updatedFinalDiscountArray);

      const updatedTvaArray = [...tvaArray, settingData.defaultTva];
      setTvaArray(updatedTvaArray);

      const updatedEditCartArray = [...editCartArray, []];
      setEditCartArray(updatedEditCartArray);

      const index = tabArray.length;
    
      const updatedTabArray = [...tabArray, index];
      setTabArray(updatedTabArray);
      setCartAtIndex(index, []);
      openTab(index)
    }
  
 

  // Open Tab
  const openTab = (numberOfTab) => {
    setSelectedTab(numberOfTab)
  }

  // Remove Tab
  const removeTab = () => {

    console.log('remove')
    const updatedTabs = tabArray.filter((_, index) => index !== selectedTab);
    setTabArray(updatedTabs);

    const updatedReturnModeArray = returnModeArray.filter((_, index) => index !== selectedTab);
    setReturnModeArray(updatedReturnModeArray);

    const updatedEditModeArray = editModeArray.filter((_, index) => index !== selectedTab);
    setEditModeArray(updatedEditModeArray);

    const updatedEditModeIdArray = editModeIdArray.filter((_, index) => index !== selectedTab);
    setEditModeIdArray(updatedEditModeIdArray);

    const updatedFinalDiscountArray = finalDiscountArray.filter((_, index) => index !== selectedTab);
    setFinalDiscountArray(updatedFinalDiscountArray);

    const updatedCartArray = allCart.filter((_, index) => index !== selectedTab);
    setCart(updatedCartArray);

    const updatedTvaArray = tvaArray.filter((_, index) => index !== selectedTab);
    setTvaArray(updatedTvaArray);

    const updatedEditCartArray = editCartArray.filter((_, index) => index !== selectedTab);
    setEditCartArray(updatedEditCartArray);

    openTab(0);
  }

  return (
    <div>

<CartContext.Provider 
      value={{
        allCart,
        setCart,
        setCartAtIndex,
        total,
        setTotal,
        finalDiscountArray,
        setFinalDiscount,
        editModeIdArray,
        setEditMode,
        editModeArray,
        setFinalNetTotal,
        finalNetTotal,
        setReturnMode,
        returnModeArray,
        selectedTab,
        addNewTab,
        openTab,
        removeTab,
        tabArray,
        tvaArray,
        setTva,
        editCartArray,
        setIsPrint,
        isPrint,
        wholeSaleArray,
        setWholeSale,

        tab,
        setTab,
        }}>

  

          <Menu />



    </CartContext.Provider>
    </div>
  );
}
export default CartData;
