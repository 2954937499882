
import {  Input, Tag, Button } from 'antd';
  
  
  function numberFormatter(number, decimal) {
    
    if (!number) {
      return 0;
    }

    if(isNaN(decimal)){
      decimal = 2;
    }else{
      decimal = parseFloat(decimal);
    }

    number = parseFloat(number);
    const roundedNumber = Number(number.toFixed(decimal)); // Round to two decimal places
    let formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(decimal);
    formattedNumber = parseFloat(formattedNumber).toLocaleString();
    return formattedNumber;
  }


    
  function roundedNumberFormatter(number) {
    if (!number) {
      return 0;
    }
  
    number = parseFloat(number);
    
    // Round to the nearest 1,000
    const roundedNumber = Math.round(number / 1000) * 1000;
  
    // Format the number with commas as thousands separators
    const formattedNumber = roundedNumber.toLocaleString();
  
    return formattedNumber;
  }
  

  function decimalFormatter(number, decimal) {
    number = parseFloat(number);
    decimal = parseFloat(decimal);
    const roundedNumber = Number(number.toFixed(decimal)); // Round to two decimal places
    let formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(decimal);
    return formattedNumber;
  }

  
  const totalFormatter = (e, record) => {
    return(<>
      <Tag  className='tag'>{record.totalUsd? numberFormatter(record.totalUsd, 2) : 0}$ { record.totalLbp > 0 ? '+ ' + numberFormatter(record.totalLbp, 2)+'L' : ''}  </Tag>
    </>) 
  }

  const finalFormatter = (e, record) => {
    return(<>
      <Tag  className='tag'>{record.finalUsd}$  { record.totalLbp > 0 ? '+ ' + numberFormatter(record.totalLbp, 2)+'L' : ''}</Tag>
    </>) 
  }



  export {
    numberFormatter,
    totalFormatter,
    finalFormatter,
    decimalFormatter,
    roundedNumberFormatter,
  };