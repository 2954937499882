import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import DropDownFilter from '../../components/filters/dropDownFilter';

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { creditFormatter, debitFormatter } from '../../components/formatters/defFormatter';

import { Input, Tag, Button } from 'antd';
import AntdSelect from 'antd/lib/select';
import { FcKindle } from "react-icons/fc";

import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';

import { ClockCircleOutlined, HddOutlined, UserOutlined } from '@ant-design/icons';

import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash } from "react-icons/bi";
import { FaDollarSign, FaLink } from "react-icons/fa";
import { FaTurkishLiraSign } from "react-icons/fa6";
import { TbTruckDelivery } from "react-icons/tb";

import { DataContext } from '../../init/getData';
const { TextArea } = Input;

function ProductSalesByInvoice(props) {

  const { userData, settingData, serverApi, localApi, devData, refDeliveryData, refAllUserData, allUserData } = useContext(DataContext);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [data, setData] = useState();
  const createdBy = userData.id;
  const userRole = userData.role;
  const defaultWareHouseId = userData?.role?.defaultWareHouseId;

  let api;

  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }

  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({
    search: '', isActive: 'true', fromDate: today, toDate: today,
    createdBy: null, type: ['Sale', 'Return', 'SalesInvoice', 'SalesReturn'], wareHouseId: defaultWareHouseId, createdBy: null
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showCartModal, setShowCartModal] = useState(false);


  const table = 'transaction';
  const title = 'ProductSalesByInvoice';



  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }


  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
        date: item.date,
      };

      transformedData.push(newItem);
    });

    const summary = {
      TotalSales: getSummary.totalSales,
      TotalCost: getSummary.totalCost,
      TotalProfit: getSummary.totalProfit,
    }
    return { transformedData, summary };
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '4%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter, width: '10%' },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: devData.deliveryName, dataIndex: 'delivery', render: (delivery) => (delivery?.name) },
    { title: 'Total In', dataIndex: 'total', width: '8%', render: debitFormatter },
    { title: 'Total Out', dataIndex: 'total', width: '8%', render: creditFormatter },
    { title: 'Discount %', dataIndex: 'discount', width: '8%', render: numberFormatter },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'Cost', dataIndex: 'cost', render: numberFormatter },
    { title: 'Profit', dataIndex: 'profit', render: numberFormatter },
    { title: 'User', dataIndex: 'created', render: (created) => (created?.name) },
  ];

  useEffect(() => {


    newColumnDefs.push(
      {
        title: 'View',
        width: '5%',
        render: (_, record) => (
          <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
        ),
      },

    );

    setColumnDefs(newColumnDefs);

  }, []);





  const formView = (<div>

  </div>);




  const modalFooter = (
    <div>


    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        {data?.summary &&
          <>
            <span className='table-footer footer-one'>TotalOrders:   {numberFormatter(data?.totalRecords, 2)}</span>
            <span className='table-footer footer-green'>TotalSales:   {numberFormatter(data?.summary?.totalSales, 2)} {settingData.currency}</span>
            <span className='table-footer footer-red'>Cost: {numberFormatter(data?.summary?.totalCost, 2)} {settingData.currency}</span>
            <span className='table-footer footer-green'>Profit:   {numberFormatter(data?.summary?.totalProfit, 2)} {settingData.currency}</span>
          </>
        }
      </div>
    </div>

  );


  return (
    <div className='card'>


      <div>

        <SearchFilter setFilter={setFilter} />
        <DatePicker setFilter={setFilter} filter={filter} />

        <DropDownFilter setFilter={setFilter} filterBy={'createdBy'} placeHolder={'All User'} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
      </div>

      
      {loadingCounter < 2 &&
        <Loader />
      }

      {loadingCounter == 2 &&
        <>




          <SimpleTable
            filter={filter}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, cart: idRow.cart, transaction: idRow, isCloud: devData.isCloud }}
          />


          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, cart: idRow.cart, transaction: idRow, isCloud: devData.isCloud }}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow?.id }}
          />


          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />


        </>
      }

    </div>
  )
}

export default ProductSalesByInvoice;
