import React, { useState, useEffect, useContext  } from 'react';


function IsOrderFilter(props) {

  const {setFilter} = props;

 
  return (
    <div >

         
<select name="groupId" className="form-control dropDown" id="groupId" 
        onChange={ (e) => setFilter(prevFilter => ({
          ...prevFilter,
          isOrder: e.target.value
        }))
         }
         >
          <option value='false'>Invoice</option>
          <option value='true'>Order</option>
        </select>
        

    </div>
  )
}

export default IsOrderFilter;
