import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DataContext } from '../../../init/getData';

const RoleEdit = (props) => {

  const { api, userData, refreshRole, wareHouseData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const {showModal, toggleModal, idRow, refreshTable, tittle, setEditedCustomer} = props;
  const {refreshCustomer, roleData} = useContext(DataContext);

  const [wareHouseSale, setWareHouseSale] = useState({value: '', label: ''});
  const [ defaultwareHouse, setDefaultwareHouse] = useState({value: '', label: ''});

  useEffect(() => {
    if(idRow.wareHouseSale ){
      setDefaultwareHouse({value: idRow.wareHouseSale.id , label: idRow.wareHouseSale.name})
      setWareHouseSale( {value: idRow.wareHouseSale.id , label: idRow.wareHouseSale.name})
    }
  }, [idRow]);

  
      // Category Options for dropDown
      const roleOptions = roleData.map(item => ({
        value: item.id,
        label: item.name,
      }));

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("editForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['id'] = idRow.id; 
    data['wareHouseSaleId'] = wareHouseSale.value; 
    data['defaultWareHouseId'] = defaultwareHouse.value; 
    
    let res = await api.post(`/role/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      refreshRole();
      toggleModal();
    }else{
      toast.error(res.data.message);
    }

    form.reset();
  }
 


        // Category Options for dropDown
        const wareHouseOptions = wareHouseData.map(item => ({
          value: item.id,
          label: item.name,
        }));




    return (
      <Modal show={showModal} backdrop="static" onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit} id='editForm'>


          <Row className="mb-3" style={{ marginTop: '2%' }}>

            <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <input type="text"  name="name" id="name" className="form-control" defaultValue={idRow.name}></input>
              </Form.Group>



            </Row>


          <Row className="mb-3" style={{ marginTop: '2%' }}>

          <Form.Group as={Col}>
            <Form.Label>Sales WareHouse</Form.Label>
              <Select
              options={wareHouseOptions}
              isSearchable={true}
              placeholder="Select WareHouse"
              value={wareHouseSale}
              onChange={setWareHouseSale}
              />
          </Form.Group>
          </Row>

          <Row className="mb-3" style={{ marginTop: '2%' }}>

            <Form.Group as={Col}>
              <Form.Label>Default wareHouse</Form.Label>
                <Select
                options={wareHouseOptions}
                isSearchable={true}
                placeholder="Select WareHouse"
                value={defaultwareHouse}
                onChange={setDefaultwareHouse}
                />
            </Form.Group>
            </Row>


      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>

      </Form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default RoleEdit;
