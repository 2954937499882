
import { ClockCircleOutlined, HddOutlined, TruckOutlined, LikeOutlined, RetweetOutlined, CloseCircleOutlined  } from '@ant-design/icons';
import {  Input, Tag, Button } from 'antd';

const statusFormatter = (status, record, statusModal) => {
  
  if(status == 'new'){
      return <Tag color="gold"  icon={<ClockCircleOutlined />} 
      onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{status}</Tag>   
  }else
  if(status == 'wareHouse'){
    return <Tag  color="processing" icon={<HddOutlined />}
     onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{record.created.branch.name} {status}</Tag>   
  }else
  if(status == 'outForDelivery'){
    return <Tag  color="magenta" icon={<TruckOutlined />}
     onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{status}</Tag>   
  }else
  if(status == 'Delivered'){
    return <Tag  color="green" icon={<LikeOutlined />}
     onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{status}</Tag>   
  }else
  if(status == 'Returned'){
    return <Tag  color="error" icon={<RetweetOutlined />}
     onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{status}</Tag>   
  }else
  if(status == 'Canceled'){
    return <Tag  color="volcano" icon={<CloseCircleOutlined />}
     onClick={() => statusModal(record)} style={{cursor: 'pointer'}}>{status}</Tag>   
  }

}

export  { statusFormatter};