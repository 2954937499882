import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../init/getData';
import { datePrintFormatter } from '../../dynamic/dateFormatter';

const BackUp = (props) => {

  const { api, userData, devSetting } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

const { showModal, toggleModal, idRow, refreshTable } = props;

const [loadingEffect, setLoadingEffect] = useState(false);


const handleSubmit = async () => {
  setLoadingEffect(true);
  const currentDate = datePrintFormatter(new Date());
  const isWebApp = devSetting?.isWebApp;

  try {

    const res = await api.get(`backup/backupData`, {
      params: { isWebApp },
      responseType: 'blob'
    });

      if (res.status === 200) {
  
          const blob = new Blob([res.data], { type: res.headers['content-type'] });

      
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = currentDate;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success('Download started');
          toggleModal();
      } else {
          toast.error('Download failed');
      }

  } catch (error) {
      console.error('Download error:', error);
      toast.error('Error initiating download');
  }

  setLoadingEffect(false);
};

  

    
  return (
    <Modal show={showModal} backdrop="static" onHide={toggleModal} className='Delete_modal'>
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>Back Up Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to back up database ?
      
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>NO</button>
        <button className="btn btn-primary" onClick={handleSubmit}>Yes
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>
      </Modal.Footer>
    </Modal>
  );

  }
export default BackUp;
