import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import DevTabsPermission from './crud/tabsPermission';
import DevPagesPermission from './crud/pagesPermission';
import ActionsPermission from './crud/actionsPermission';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function DevRole(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, refWareHouseData, wareHouseData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true' });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showTabPermissionModal, setShowTabPermissionModal] = useState(false);
  const [showPagePermissionModal, setShowPagePermissionModal] = useState(false);
  const [showRoleActionsModal, setShowRoleActionsModal] = useState(false);


  const [defaultWareHouse, setDefaultwareHouse] = useState([]);
  const [wareHouseSale, setWareHouseSale] = useState([]);
  const [role, setRole] = useState([]);

  const table = 'role';
  const title = 'Role';

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refWareHouseData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);

  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const wareHouseWithNull = [...wareHouseOptions];

  wareHouseWithNull.push({
    value: null,
    label: 'All WareHouse'
  })


  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const resetForm = () => {
    setRole({})
    setDefaultwareHouse([])
    setWareHouseSale([])
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const tabPermissionModal = (e) => {
    if (!showTabPermissionModal) {
      setIdRow(e);
    }
    setShowTabPermissionModal(!showTabPermissionModal);
  }

  const pagePermissionModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowPagePermissionModal(!showPagePermissionModal);
  }

  const roleActionModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowRoleActionsModal(!showRoleActionsModal);
  }

  // idRow Change
  useEffect(() => {

    const defualtWareHouseId = wareHouseWithNull.find(option => option.value === idRow?.defaultWareHouseId);
    setDefaultwareHouse(defualtWareHouseId);
  
    const wareHouseSaleId = wareHouseWithNull.find(option => option.value === idRow?.wareHouseSaleId);
    setWareHouseSale(wareHouseSaleId);
    setRole(
      {
        name: idRow?.name || '',
      }
    )
  }, [idRow]);

    // handle select change
    const handleSelectChange = (selectedOption, name) => {
      if (name == 'defaultWareHouseId') {
        setDefaultwareHouse(selectedOption)
      } else if (name == 'wareHouseSaleId') {
        setWareHouseSale(selectedOption)
      }
      setRole((prev) => ({
        ...prev,
        [name]: selectedOption.value,
      }));
    };


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setRole((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        name: item.name,
      };

      transformedData.push(newItem);
    });

    return { transformedData };
  };



  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '5%' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Sale From', dataIndex: 'wareHouseSale', render: (wareHouseSale) => (wareHouseSale?.name) },
    { title: 'Default WareHouse', dataIndex: 'defaultWareHouse', render: (defaultWareHouse) => (defaultWareHouse?.name || 'All WareHouse') },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'Tabs',
          width: '5%',
          render: (_, record) => (
            <FcViewDetails onClick={e => tabPermissionModal(record)} size={20} style={{ cursor: 'pointer' }} />
          ),
        },
        {
          title: 'Pages',
          width: '5%',
          render: (_, record) => (
            <FcRules onClick={e => pagePermissionModal(record)} size={20} style={{ cursor: 'pointer' }} />
          ),
        },
        {
          title: 'Actions',
          width: '5%',
          render: (_, record) => (
            <FcFlowChart onClick={e => roleActionModal(record)} size={20} style={{ cursor: 'pointer' }} />
          ),
        },
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '5%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={role.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Row>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Sales WareHouse</Form.Label>
          <Select
            options={wareHouseOptions}
            isSearchable={true}
            placeholder="select"
            value={wareHouseSale}
            onChange={(e) => handleSelectChange(e, 'wareHouseSaleId')}
            required
          />
        </Form.Group>
      </Row>

      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Default wareHouse</Form.Label>
          <Select
            options={wareHouseWithNull}
            isSearchable={true}
            placeholder="select"
            value={defaultWareHouse}
            onChange={(e) => handleSelectChange(e, 'defaultWareHouseId')}
            required
          />
        </Form.Group>
      </Row>

    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (
    <></>
  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />
            <AddButton addModal={addModal} />

          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />


          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ role }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, role }}
          />



          <DevTabsPermission showModal={showTabPermissionModal} toggleModal={tabPermissionModal} tittle={'Role'} refreshTable={refreshTableFunction} idRow={idRow} />
          <DevPagesPermission showModal={showPagePermissionModal} toggleModal={pagePermissionModal} tittle={'Role'} refreshTable={refreshTableFunction} idRow={idRow} />
          <ActionsPermission showModal={showRoleActionsModal} toggleModal={roleActionModal} tittle={'Role'} refreshTable={refreshTableFunction} idRow={idRow} />



        </>
      }

    </div>
  )
}

export default DevRole;
