import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import { NavDropdown } from 'react-bootstrap';

import CostChangesHeader from './costChanges/costChangesHeader';
import ProductSales from './productSales/productSales';
import ProductSalesByInvoice from './productSalesByInvoice/productSalesByInoivce';
import IncomeStatement from './incomeStatement/incomeStatement';
import AllTransactions from './allTransactions/allTransactions';
import CashFlow from './cashFlow/cashFlow';


import { DataContext } from '../init/getData';

import { FcPackage, FcSurvey, FcSalesPerformance, FcApproval, FcDeployment, FcComboChart } from "react-icons/fc";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { MdOutlineDiscount } from "react-icons/md";
import { TiArrowRepeatOutline } from "react-icons/ti";

function ReportTab(props) {

  const navigate = useNavigate();

  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('allTransactions');

  useEffect(() => {
    if (userRole.stockActivityPage) {
      navigate('/reportTab/allTransactions');
    }
  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        {userRole.allTransactionsPage &&
          <NavLink to="allTransactions" onClick={() => setActiveTab('allTransactions')}>
            <div className={`${activeTab === 'allTransactions' ? 'tab-six active-link' : 'tab-six'}`} style={{ width: 'auto' }}>
              <FcComboChart size={35} />
              <span className="tab-text">All Transactions</span>
            </div>
          </NavLink>
        }

        {userRole.productSalesPage &&
          <div onClick={() => setActiveTab('productSales')} className={`${activeTab === 'productSales' ? 'tab-four active-link' : 'tab-four'}`}>
            <MdOutlineDiscount size={30} color={'#ffccbc'} />
            <NavDropdown title={<span className="navDropdown">Product Sales</span>} >
              <NavDropdown.Item onClick={() => navigate("productSalesByItems")} style={{ color: 'white !important' }}>By Items</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("ProductSalesByInvoice")} style={{ color: 'white !important' }}>By Invoice</NavDropdown.Item>
            </NavDropdown>
          </div>
        }

        {userRole.cashFlowPage &&
          <NavLink to="cashFlow" onClick={() => setActiveTab('cashFlow')}>
            <div className={`${activeTab === 'cashFlow' ? 'tab-six active-link' : 'tab-six'}`} style={{ width: 'auto' }}>
              <TiArrowRepeatOutline size={33} />
              <span className="tab-text">Cash Flow</span>
            </div>
          </NavLink>
        }



        {userRole.incomeStatementPage &&
          <NavLink to="incomeStatement" onClick={() => setActiveTab('incomeStatement')}>
            <div className={`${activeTab === 'incomeStatement' ? 'tab-seven active-link' : 'tab-seven'}`} style={{ width: 'auto' }}>
              <FcSurvey size={28} />
              <span className="tab-text">Income Statement</span>
            </div>
          </NavLink>
        }

      </div>


      <Routes>

        <Route path='costChanges' element={<CostChangesHeader />} />

        <Route path='productSalesByItems' element={<ProductSales type={'item'} />} />
        <Route path='ProductSalesByInvoice' element={<ProductSalesByInvoice />} />
        <Route path='allTransactions' element={<AllTransactions />} />
        <Route path='cashFlow' element={<CashFlow />} />
        <Route path='incomeStatement' element={<IncomeStatement />} />

      </Routes>

    </div>
  );
}

export default ReportTab;
