import React, { useState, useEffect, useContext  } from 'react';

import CostChangesTable from './costChangesTable';
import { DataContext } from '../../init/getData';
import { defaultDateGetter, dateHourFormatter} from '../../dynamic/dateFormatter';
import { numberFormatter } from '../../dynamic/numberFormatter';
import { FcMoneyTransfer } from "react-icons/fc";


function CostChangesHeader(props) {

  const [showAddModal, setShowAddModal] = useState(null);

  const [search, setSearch] = useState('');

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [total, setTotal] = useState(0);

  const {settingData, userData} = useContext(DataContext);
  const closingHour = settingData.closingHour;


  const addModal = () => {
    setShowAddModal(!showAddModal);
  }

  
  useEffect(() => {

    const getDate = defaultDateGetter(settingData.defaultDate);
    const fromDate = getDate.fromDate;
    const toDate = getDate.toDate;
    document.getElementById('fromDate').value = fromDate;
    document.getElementById('toDate').value = toDate;

    setFromDate(dateHourFormatter(fromDate, 'from', closingHour));
    setToDate(dateHourFormatter(toDate, 'to', closingHour));

  }, [])



  
  return (
    <div className='card'>

      
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>



        <div className="col-sm-10">
        <input type="date" name="fromDate" id="fromDate"  className="form-control date"
        onChange={(e) => setFromDate(dateHourFormatter(e.target.value, 'from', closingHour))}   ></input>

        <input type="date" name="toDate" id="toDate" className="form-control date" 
        onChange={(e) => setToDate(dateHourFormatter(e.target.value, 'to', closingHour))} ></input>
        </div>


      <button onClick={addModal} type="button" className="btn btn-add"> Add  </button>



      </div>

 
      <CostChangesTable
      showAddModal={showAddModal}
      addModal={addModal}
      fromDate={fromDate}
      toDate={toDate}
      setTotal={setTotal}
      search={search} />
      

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3vh' }}>

    {total >= 0 &&
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#2eb85c', color: 'white', marginLeft: '20px', padding:'5px' }}>
    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' ,  marginRight: '5px'}}>
    <a>Total: {numberFormatter(total, 2)} {settingData.currency}</a>
    </div>
    </div>
     }

    {total < 0 &&
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#e6424e', color: 'white', marginLeft: '20px', padding:'5px' }}>
    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' ,  marginRight: '5px'}}>
    <a>Total: {numberFormatter((total*-1), 2)} {settingData.currency}</a>
    </div>
    </div>
    }

    </div>

    </div>
  )
}

export default CostChangesHeader;
