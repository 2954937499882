import React, { useState, useEffect, useRef, useContext } from 'react';
import toast, {Toaster} from 'react-hot-toast';

import { Modal } from 'react-bootstrap';

//images

import { FcRefresh } from "react-icons/fc";


import { print, printIp } from "../../dynamic/print";

import Select from 'react-select';
import { DataContext } from '../../init/getData';

import TableSales from './tableSales/tableSales';


const SalesModal = (props) => {

  const { showModal, toggleModal, tableId} = props;
  const {api,  userData} = useContext(DataContext);

  const [cart, setCart] = useState(null);

  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;
  

  useEffect( () => {
    async function fetchData() {
    if(tableId){
    let data = {
      tableId: tableId.id,
    };
    
    let res = await api.get(`/dineIn/getCart`, {params: data});
  if(res.data.status){
    setCart(res.data.data)
    
  }else{
    toast.error('Failed, Contact Support');
  }  
    }
  }
    fetchData();
  }, [tableId]);






  return (

    <Modal show={showModal} size="xl" backdrop="static" onHide={toggleModal}  >
      <div style={{ width: '130%', marginLeft: '-15%'}}>
  

    <Modal.Body style={{  height: '10vh', marginTop: '-20px'}}>

    {cart && 
    <TableSales
    toggleModal={toggleModal}
    cart={cart}
    setCart={setCart}
    tableId={tableId}
    />
     }

    </Modal.Body>

    </div>
      </Modal>
  );
};

export default SalesModal;
