import React, { useEffect, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { DataContext } from '../../init/getData';

function AsyncDropDownFilter(props) {
  const { setFilter, filterBy, placeHolder, setLoadingCounter, filter } = props;

  const {
    refWareHouseData, refCategory, refProductData, refAllUserData, refAccountData,
    wareHouseData, categoryData, productData, allUserData, accountData
  } = useContext(DataContext);

  // Get the appropriate data fetching function
  const fetchData = async () => {
    try {
      if (filterBy === 'wareHouseId') {
        await refWareHouseData();
      } else if (filterBy === 'categoryId') {
        await refCategory();
      } else if (filterBy === 'productId') {
        await refProductData();
      } else if (filterBy === 'createdBy') {
        await refAllUserData();
      } else if (filterBy === 'accountId' || filterBy === 'deliveryId' || filterBy === 'bankId') {
        await refAccountData();
      }
      setLoadingCounter();
    } catch (error) {
      console.error('Error refreshing data. Contact support.');
    }
  };

  // Trigger fetching data on component mount and when dependencies change
  useEffect(() => {
    fetchData();
  }, [filter.accountType, filterBy]);

  // Get the relevant data based on the filter type
  let getData = [];
  let filterSelected = null;

  if (filterBy === 'wareHouseId') {
    getData = wareHouseData;
    filterSelected = filter.wareHouseId;
  } else if (filterBy === 'categoryId') {
    getData = categoryData;
    filterSelected = filter.categoryId;
  } else if (filterBy === 'productId') {
    getData = productData;
    filterSelected = filter.productId;
  } else if (filterBy === 'createdBy') {
    getData = allUserData;
    filterSelected = filter.createdBy;
  } else if (filterBy === 'accountId') {
    getData = filter.accountType ? accountData?.filter(account => account.type === filter.accountType) : accountData;
    filterSelected = filter.accountId;
  } else if (filterBy === 'deliveryId') {
    getData = accountData?.filter(account => account.type === 'Delivery');
    filterSelected = filter.deliveryId;
  } else if (filterBy === 'bankId') {
    getData = accountData?.filter(account => account.type === 'Bank');
    filterSelected = filter.bankId;
  }

  // Function to load options asynchronously based on user input

const loadOptions = async (inputValue, callback) => {
  const options = getData?.filter(item =>
    item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
    (item.barcode && item.barcode.toLowerCase().includes(inputValue.toLowerCase())) // Search by barcode
  ).map(item => ({
    value: item.id,
    label: filterBy === 'productId' ? `${item.name} [${item.barcode}]` : 
           (filterBy === 'accountId' || filterBy === 'bankId') ? `${item.name} [${item.phone}]` : item.name,
  }));

  callback(options);
};


  // Find selected value based on filter
  const selectedValue = getData?.find(item => item.id === filterSelected);

  return (
    <div>
      <AsyncSelect
        className='dropDownMedium'
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        isSearchable
        placeholder={placeHolder}
        value={selectedValue ? { value: selectedValue.id, label: filterBy === 'productId' ? `${selectedValue.name} [${selectedValue.barcode}]` : selectedValue.name } : null}
        onChange={selectedOption =>
          setFilter(prevFilter => ({
            ...prevFilter,
            [filterBy]: selectedOption ? selectedOption.value : null,
          }))
        }
      />
    </div>
  );
}

export default AsyncDropDownFilter;
