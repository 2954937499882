import React, {  useState, useEffect, useContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import TablesHeader from './tables/tablesHeader';
import DineIn from './dineIn/dineIn';


import { DataContext } from '../init/getData';

import { FcPackage, FcOrgUnit, FcSettings, FcApproval, FcSurvey, FcHome } from "react-icons/fc";

function TablesTab(props) {

  const navigate = useNavigate();
  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('dineIn');

  useEffect(() => {

    navigate('/tablesTab/dineIn');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>


      <NavLink to="dineIn" onClick={() => setActiveTab('dineIn')}>
          <div className={`${activeTab === 'dineIn' ? 'tab-two active-link' : 'tab-two'}`}>
          <FcPackage size={30}/>
            <span className="tab-text">dineIn</span>
          </div>
        </NavLink>

        <NavLink to="tables" onClick={() => setActiveTab('tables')}>
          <div className={`${activeTab === 'tables' ? 'tab-two active-link' : 'tab-two'}`}>
          <FcPackage size={30}/>
            <span className="tab-text">tables</span>
          </div>
        </NavLink>


        

      </div>

 
      <Routes>

        <Route path='tables' element= {<TablesHeader/>}/>
        <Route path='dineIn' element= {<DineIn/>}/>
        
      </Routes>

    </div>
  );
}

export default TablesTab;
