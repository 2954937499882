import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import RoleAdd from './crud/roleAdd';
import RoleEdit from './crud/roleEdit';
import RoleTrash from './crud/roleTrash';
import RoleRecover from './crud/roleRecover';

import TabsPermission from './crud/tabsPermission';
import ActionsPermission from './crud/actionsPermission';
import PagesPermission from './crud/pagesPermission';

import toast from 'react-hot-toast';

import delete_png from '../../images/trash.png';
import { FiEdit } from "react-icons/fi";
import { FcUndo, FcSurvey , FcFlowChart, FcRules, FcViewDetails } from "react-icons/fc";

import { DataContext } from '../../init/getData';

function RoleTable(props) {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { isActive, showAddModal, addModal, search } = props;

  const {settingData, deliveryData} = useContext(DataContext);

  const [showTabPermissionModal, setShowTabPermissionModal] = useState(false);
  const [showPagePermissionModal, setShowPagePermissionModal] = useState(false);
  const [showRoleActionsModal, setShowRoleActionsModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);
  const timeoutRef = useRef(null);

//-----------------------------------------------------------------


const tabPermissionModal = (e) => {
  if (e != undefined) {
    setIdRow(e);
  }
  setShowTabPermissionModal(!showTabPermissionModal);
}

const pagePermissionModal = (e) => {
  if (e != undefined) {
    setIdRow(e);
  }
  setShowPagePermissionModal(!showPagePermissionModal);
}

const roleActionModal = (e) => {
  if (e != undefined) {
    setIdRow(e);
  }
  setShowRoleActionsModal(!showRoleActionsModal);
}

  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(!showTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 

  
  

  useEffect(() => {



  let newColumnDefs = [
    { field: 'id', flex : 0.5},
    { field: 'name' },
    { field: 'wareHouseSale.name'},
    { field: 'defaultWareHouse.name'},
  ];



  setColumnDefs(newColumnDefs);

  if(isActive == 'true'){

  newColumnDefs.push(

    {field: 'tabs', headerName: 'Tabs', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
      <FcViewDetails onClick={e => tabPermissionModal(data)} size={20} style={{  cursor: 'pointer' }} />)},

      {field: 'pages', headerName: 'Pages', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
        <FcRules onClick={e => pagePermissionModal(data)} size={20} style={{  cursor: 'pointer' }} />)},

      {field: 'actions', headerName: 'Actions', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
        <FcFlowChart onClick={e => roleActionModal(data)} size={20} style={{  cursor: 'pointer' }} />)},


    {field: 'edit', headerName: 'Edt', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
      <FiEdit size={18} style={{cursor: 'pointer'}} onClick={() => editModal(data)} />
      )},
    
    {field: 'delete', headerName: 'Del', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={delete_png} onClick={e => trashModal(data)} width={20} style={{ paddingTop: '10px'}} />
    )},
    );
  }else{
    newColumnDefs.push(      
      {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
        <FcUndo size={25} style={{cursor: 'pointer'}} onClick={() => recoverModal(data)} />
      )},
      );

  }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive]);


  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);




  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/role/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive]);
  


useEffect(() => { 

  if (search.length > 0) {
  setSearchState(true);

  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  timeoutRef.current = setTimeout(() => {

  var datasource = {
  getRows(params) {

  let data = {
  isActive: isActive,
  search: search,
  };

  api.get(`/customer/getall`, {params: data}).then(response => {
  params.successCallback(response.data.data, response.data.totalRecords);
  if(!response.data.status){
  toast.error('Failed to get Data')
  }})}}
  gridApi.api.setServerSideDatasource(datasource);
  }, 1000);
  }else{
  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  if (searchState) onGridReady(gridApi);
  }
  }, [search]);
  
  
  return (
    <div>     
    
    <RoleAdd showModal={showAddModal}  toggleModal={addModal}tittle={'Role'} refreshTable={refreshTable} deliveryData={deliveryData} />
    <RoleEdit showModal={showEditModal} toggleModal={editModal} tittle={'Role'} refreshTable={refreshTable}  idRow={idRow} deliveryData={deliveryData} />
    <RoleTrash showModal={showTrashModal} toggleModal={trashModal} tittle={'Role'} refreshTable={refreshTable} idRow={idRow} />
    <RoleRecover showModal={showRecoverModal} toggleModal={recoverModal} tittle={'Role'} refreshTable={refreshTable} idRow={idRow} />
   
    <TabsPermission showModal={showTabPermissionModal} toggleModal={tabPermissionModal} tittle={'Role'} refreshTable={refreshTable} idRow={idRow} />
    <PagesPermission showModal={showPagePermissionModal} toggleModal={pagePermissionModal} tittle={'Role'} refreshTable={refreshTable} idRow={idRow} />
    <ActionsPermission showModal={showRoleActionsModal} toggleModal={roleActionModal} tittle={'Role'} refreshTable={refreshTable} idRow={idRow} />

      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={10}
        cacheBlockSize={10}
        />
      </div>

      
    </div>
  );
}

export default RoleTable;
