
import { dateViewerFormatter } from '../../dynamic/dateFormatter';
import { statusFormatter } from '../../components/formatters/statusFormatter';
import { totalFormatter, finalFormatter } from '../../components/formatters/numberFormatter';


  let orderColumnDefs = [
    { title: 'id',         dataIndex: 'id',         width: '2%', hidden: true },
    { title: 'barcode',    dataIndex: 'barcode',    width: '12%' },
    { title: 'createdAt',  dataIndex: 'createdAt',  width: '10%', render: dateViewerFormatter },
    { title: 'client',     dataIndex: 'client',     width: '11%', render: (client) => (client?.name + ' ' + client?.phone) },
    { title: 'customer',   dataIndex: 'name',       width: '12%', render: (name, record) => (name + ' ' + record?.phone) },
    { title: 'address',    dataIndex: 'subRegion',  width: '25%', render: (subRegion, record) => (subRegion?.region?.name + '-' + subRegion?.name + ': ' + record?.address) },
    { title: 'status',     dataIndex: 'mainStatus', width: '15%', render: statusFormatter },
    { title: 'Total',      dataIndex: 'total',      width: '17%', render: totalFormatter },
    { title: 'delivery',   dataIndex: 'delivery',   width: '8%' },
    { title: 'fees',       dataIndex: 'fees',       width: '7%' },
    { title: 'conditions', dataIndex: 'conditions', width: '10%' },
    { title: 'notes',      dataIndex: 'notes',      width: '10%' },
  ];

  let orderFinalColumnDefs = [
    { title: 'id',         dataIndex: 'id',         width: '2%', hidden: true },
    { title: 'barcode',    dataIndex: 'barcode',    width: '12%' },
    { title: 'createdAt',  dataIndex: 'createdAt',  width: '10%', render: dateViewerFormatter },
    { title: 'client',     dataIndex: 'client',     width: '11%', render: (client) => (client?.name + ' ' + client?.phone) },
    { title: 'customer',   dataIndex: 'name',       width: '12%', render: (name, record) => (name + ' ' + record?.phone) },
    { title: 'status',     dataIndex: 'mainStatus', width: '15%', render: statusFormatter },
    { title: 'Total',      dataIndex: 'total',      width: '17%', render: totalFormatter },
    { title: 'Final',      dataIndex: 'total',      width: '17%', render: finalFormatter },
    { title: 'delivery',   dataIndex: 'finalDelivery',   width: '8%' },
    { title: 'fees',       dataIndex: 'fees',       width: '7%' },
    { title: 'driver',       dataIndex: 'cost',       width: '7%' },
    { title: 'notes',      dataIndex: 'settleNotes',      width: '10%' },
  ];

export  { orderColumnDefs, orderFinalColumnDefs };