const ExcelJS = require('exceljs');
const FileSaver = require('file-saver');

const exportToExcel = (allData, fileName) => {
    const data = allData.transformedData;
    const summary = allData.summary || '';

    // Check if data is provided and is an array with at least one element
    if (!data || !Array.isArray(data) || data.length === 0) {
        console.error('Invalid data provided for exporting to Excel.');
        return; // Exit function if data is invalid
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Collect all headers from data and summary
    const dataHeaders = Object.keys(data[0]);

    const summaryHeaders = Object.keys(summary) || null;
    const headers = [...new Set([...dataHeaders, ...summaryHeaders])];


    // Add headers to the worksheet
    worksheet.columns = headers.map(header => ({
        header,
        key: header,
        width: 15 // Example width, adjust as needed
    }));

    // Add data rows to the worksheet
    data.forEach(item => {
        const row = headers.map(header => item[header] || '');
        worksheet.addRow(row);
    });

    // Create a summary row aligned with the headers
    const summaryRow = headers.map(header => summary[header] !== undefined ? summary[header] : '');

    // Add the summary row to the worksheet
    worksheet.addRow(summaryRow);

    // Return the promise from writeBuffer() to handle the buffer
    return workbook.xlsx.writeBuffer().then(buffer => {
        // Convert buffer to Blob and save/download using FileSaver
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `${fileName}.xlsx`);

        console.log(`Excel file ${fileName}.xlsx saved/downloaded successfully.`);
    }).catch(err => {
        console.error('Error exporting Excel:', err);
        throw err; // Optionally rethrow or handle the error
    });
};


export default exportToExcel;




