import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import IsOrderFilter from '../../components/filters/isOrderFilter';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import DropDownFilter from '../../components/filters/dropDownFilter';


import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { creditFormatter, debitFormatter } from '../../components/formatters/defFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function ExpenseInvoice(props) {


  const { api, userData, settingData, accountData, refAccountData, refWareHouseData, wareHouseData, devData } = useContext(DataContext);
  const userRole = userData.role;
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const roundTo = settingData.roundTo;
  const defaultWareHouseId = userData?.role?.defaultWareHouseId;

  const table = 'transaction';
  const title = 'ExpenseInvoice';


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', type: 'ExpenseInvoice', accountId: null, fromDate: today, toDate: today, wareHouseId: defaultWareHouseId });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const [cart, setCart] = useState([]);
  const [transaction, setTransaction] = useState(false);


  const [account, setAccount] = useState([]);
  const [wareHouse, setWareHouse] = useState([]);

  const cartItem = {
    name: '',
    quantity: 1,
    price: 0,
    netPrice: 0,
    totalPrice: 0,
    cost: 0,
    details: '',
  };


  //Get Accounts 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refAccountData();
        await refWareHouseData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  //Get accounts by Type
  const accountDataType = accountData?.filter(account => account.type === 'Expenses');

  // Account Options
  const accountOptions = accountDataType?.map(item => ({
    value: item.id,
    label: `${item.name} [${item.phone}]`,
  }))

  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  // idRow Change
  useEffect(() => {
    const accountId = accountOptions.find(option => option.value === idRow?.accountId) || [];;
    setAccount(accountId);

    const wareHouseId = wareHouseOptions.find(option => option.value === idRow?.wareHouseId) || [];;
    setWareHouse(wareHouseId);

    setTransaction(
      {
        isOut: true,
        date: idRow?.date || today,
        isCredit: idRow?.isCredit || false,
        total: idRow?.total || '',
        details: idRow?.details || '',
        accountId: idRow?.accountId || account,
        wareHouseId: idRow?.wareHouseId || wareHouse,
        createdBy: createdBy,
        type: 'ExpenseInvoice',
        discount: 0,
      }
    )
    setCart(idRow.cart || [cartItem])
  }, [idRow]);

  // Add new row
  const handleAddRow = () => {
    setCart([...cart, cartItem]);
  };

  // Delete VarientRow
  const handleDeleteRow = (event) => {
    event.preventDefault()
    if (cart.length > 1) {
      const updatedRows = cart.slice(0, -1);
      setCart(updatedRows);
    } else {
      toast.error('you need at least one item')
    }
  };


  // handle row changes
  const handleRowChange = (index, value, field) => {
    const updatedCart = [...cart];

    if (field == 'name') {
      updatedCart[index].name = value;

    } else if (field == 'price') {
      updatedCart[index].price = parseFloat(value);
      updatedCart[index].netPrice = parseFloat(value);
      updatedCart[index].totalPrice = parseFloat(value);

    } else if (field == 'details') {
      updatedCart[index].details = value;
    }

    setCart(updatedCart);
  }


  // calculate Total
  useEffect(() => {
    let discount;
    const total = cart.reduce((acc, item) => acc += parseFloat(item.totalPrice), 0);
    if(transaction.discount){
      discount = decimalFormatter(total - (total * transaction.discount / 100), 2)
    }else{
      discount = total;
    }
    
    setTransaction((prev) => ({
      ...prev,
      total: discount,
    }));
  }, [cart, transaction.discount]);


  const resetForm = () => {
    setIdRow([]);
    setAccount([])
    setTransaction({
      createdBy: createdBy,
      type: 'Payment',
      details: "",
      isOut: false,
    })
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handle select change
  const handleSelectChange = (selectedOption, name) => {
    if (name == 'accountId') {
      setAccount(selectedOption)
    } else
      if (name == 'wareHouseId') {
        setWareHouse(selectedOption)
      }
    setTransaction((prev) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
  };


  const addModal = () => {
    setIdRow([]);
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }




  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data?.forEach(item => {

      const newItem = {
        id: item.id,
        refNumber: item.refNumber,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.isOut ? -item.total : item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
      };

      transformedData.push(newItem);
    });

    const summary = {
      TotalCash: getSummary?.totalCash,
      TotalCredit: getSummary?.totalCredit,
      Balance: getSummary?.totalCash - getSummary?.totalCredit,
    }

    return { transformedData, summary };
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%', sorter: (a, b) => a.age - b.age },
    { title: 'Ref#', dataIndex: 'refNumber', width: '6%' },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: 'Total In', dataIndex: 'total', width: '8%', render: debitFormatter },
    { title: 'Total Out', dataIndex: 'total', width: '8%', render: creditFormatter },
    { title: 'Discount %', dataIndex: 'discount', width: '8%', render: numberFormatter },
    { title: 'Payment', dataIndex: 'isCredit', render: paymentTypeFormatter },
  ];

  useEffect(() => {

    newColumnDefs.push(
      {
        title: 'View',
        width: '5%',
        render: (_, record) => (
          <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
        ),
      },
      {
        title: 'Edt',
        width: '5%',
        render: (_, record) => (
          <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
        ),
      },
      {
        title: 'Del',
        width: '5%',
        render: (_, record) => (
          <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />
        ),
      }
    );
    setColumnDefs(newColumnDefs);

  }, []);

  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

        {/* Account */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Account<span style={{ color: 'red' }}> *</span></Form.Label>
          <Select
            options={accountOptions}
            isSearchable={true}
            placeholder="Select"
            onChange={(e) => handleSelectChange(e, 'accountId')}
            value={account}
            required
          />
        </Form.Group>



        {/* Date */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Date<span style={{ color: 'red' }}> *</span></Form.Label>
          <input type="date" className="form-control" name='date'
            onChange={(e) => handleInputChange(e, 'text')}
            value={transaction.date}
            required
          ></input>
        </Form.Group>

      </Row>

      <Row className="mb-3">


        {/* Account */}
        <Form.Group as={Col} sm={6}>
          <Form.Label className='formLabel'>WareHouse<span style={{ color: 'red' }}> *</span></Form.Label>
          <Select
            options={wareHouseOptions}
            isSearchable={true}
            placeholder="Select"
            onChange={(e) => handleSelectChange(e, 'wareHouseId')}
            value={wareHouse}
            required
          />
        </Form.Group>

        {/* ID Number */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Reference #</Form.Label>
          <input type="text" className="form-control" placeholder='Optional' name='refNumber'
            onChange={(e) => handleInputChange(e, 'text')}
            value={transaction.refNumber}
          ></input>
        </Form.Group>



        {/* Payment */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Payment<span style={{ color: 'red' }}> *</span></Form.Label>
          <select name="isCredit" className="form-control"
            onChange={(e) => handleInputChange(e, 'boolean')}
            value={transaction.isCredit}>
            <option value='false' >Cash</option>
            <option value='true'>Credit</option>
          </select>
        </Form.Group>


      </Row>

      {/* Cart Array  */}
      <div>
        {cart.map((row, index) => (
          <div key={index} style={{ marginTop: '4%' }}>
            {/* Labels */}
            {index === 0 && (
              <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label>Name</Form.Label>

                </Form.Group>

                <Form.Group as={Col} md={2}>
                  <Form.Label>Price</Form.Label>
                </Form.Group>


                <Form.Group as={Col} md={4}>
                  <Form.Label>Details</Form.Label>
                </Form.Group>

              </Row>

            )}


            <Row className="mb-3" style={{ marginTop: '-20px' }}>


              <Form.Group as={Col} md={6}>
                <Form.Control type="text" value={cart[index].name}
                  onChange={(e) => handleRowChange(index, e.target.value, 'name')}
                />
              </Form.Group>


              {/* Price */}
              <Form.Group as={Col} md={2}>
                <Form.Control type="number" min={0} step='any' value={cart[index].price}
                  onChange={(e) => handleRowChange(index, e.target.value, 'price')}
                  required
                />
              </Form.Group>


              {/* Details */}
              <Form.Group as={Col} md={4}>
                <Form.Control as="textarea" rows={1}
                  value={cart[index].details}
                  onChange={(e) => handleRowChange(index, e.target.value, 'details')}
                />
              </Form.Group>

            </Row>



            <Row key={index + 100} className="mb-3">
              {index === cart.length - 1 && (
                <Form.Group
                  as={Col}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}
                >
                  <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddRow} />
                  <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteRow(e)} />

                </Form.Group>
              )}
            </Row>
          </div>
        ))}
      </div>




      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Details</Form.Label>
          <textarea className="form-control" name="details" id="details"
            value={transaction.details}
            onChange={handleInputChange}></textarea>
        </Form.Group>

      </Row>


    </div>
  )

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        <>
        </>

      </div>
    </div>

  );

  const modalFooter = (
    <div style={{ textAllign: 'center' }}>

      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          <a style={{ color: 'green', fontWeight: 'bold', fontSize: '25px' }}>
            Total: {numberFormatter(transaction.total, 2)}{settingData.currency}
          </a>

        </Form.Group>
      </Row>
    </div>
  )

  return (
    <div className='card'>


      <div>

        <SearchFilter setFilter={setFilter} />
        <DatePicker setFilter={setFilter} filter={filter} />
        <AddButton addModal={addModal} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />

      </div>


      {(isLoading || loadingCounter < 1) &&
          <Loader />
      }


      {(!isLoading && loadingCounter == 1) &&
        <>
          <div>



          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
            selection={true}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, transaction }}
          />

          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ cart, transaction }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ transactionId: idRow.id, cart, transaction }}
          />


        </>
      }

    </div>
  )
}

export default ExpenseInvoice;
