import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';


const Serial = (props) => {

  const { showModal, toggleModal, setSerial} = props;

  
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    const form = document.getElementById("addForm");
    const data = new FormData(form);
    const serial = data.get('serial');
    if(serial){
      setSerial(serial)
      toggleModal();
    }
    else{
      toast.error('please enter serial')
    }
    
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Enter Serial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form  id='addForm' onSubmit={handleSubmit}>

 
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Serial</label>
      <div className="col-sm-10">
      <input type="text" name='serial'  id='serial' className="form-control" autoFocus/>
      </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Okay" ></input>
      </div>


      </form>
      </Modal.Body>
      </Modal>
      )
}


export default Serial;