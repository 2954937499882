import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { numberFormatter } from '../../../dynamic/numberFormatter';

import { DataContext } from '../../../init/getData';
import { FcPortraitMode, FcAbout, FcInTransit, FcHome } from "react-icons/fc";
import Loader from '../../../components/loader/loader';
import { ConfigProvider, Card, Divider } from 'antd';
import { datePrintFormatter } from '../../../dynamic/dateFormatter';
import { print } from "../../../dynamic/print";

import { Button } from 'antd';

const EndOfDayReport = (props) => {

  const { userData, settingData, api, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;


  const reportRef = useRef(null);

  const { showModal, toggleModal, idRow, refreshTable, wareHouse } = props;

  const [data, setData] = useState([]);
  const [loadingEffect, setLoadingEffect] = useState(true);


  useEffect(() => {
    if (showModal && idRow) {
      getReport();
    }
  }, [showModal]);


  const getReport = async () => {

    const data = {
      id: idRow.id,
      wareHouseId: wareHouse.id,
    }

    let res = await api.get(`balanceAndVariance/getReport`, { params: data });
    if (res.data.status) {
      setData(res.data);
    } else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }


  const report = (
    <ConfigProvider
      theme={{
        token: { colorPrimary: '#4a90e2' },
        components: {
          Card: {
            colorBgContainer: 'white',
            headerBg: '#39aaa5',
            headerFontSize: '20px',
            colorTextHeading: 'white',
          },
        },
      }}
    >
      <Card
        title={<div style={{ textAlign: 'center', fontSize: 40 }}>Cash Flow</div>}
        bordered={true}
        style={{ width: '100%', alignSelf: 'center' }}
        headStyle={{ textAlign: 'center' }}
        ref={reportRef}
      >
        <div style={{ width: '80%', margin: '0 auto' }}>


          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{devData.wareHouseName}: </h5>
            <h5>{wareHouse?.name}</h5>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5>Opening Balance</h5>
            <h5 style={{ color: idRow?.openingBalance >= 0 ? 'green' : 'red' }}>
              {numberFormatter(idRow?.openingBalance, 2)}{settingData.currency}</h5>
          </div>


          <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />


          <br />
          <br />
          <br />

          {/*  Sales  */}
          <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Sales</h5>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Total Sales:</h5>
              <h5>{numberFormatter(data?.summary?.totalSales, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Total Sales Cash:</h5>
              <h5>{numberFormatter(data?.summary?.totalSalesCash, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Total Sales Credit:</h5>
              <h5>{numberFormatter(data?.summary?.totalSalesCredit, 2)}{settingData.currency}</h5>
            </div>

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Sales Cash</h5>
              <h5 style={{ color: data?.summary?.totalSalesCash >= 0 ? 'green' : 'red' }}>{numberFormatter(data?.summary?.totalSalesCash, 2)}{settingData.currency}</h5>
            </div>



            {/*  Purchase  */}
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Purchase</h5>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>


              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Purchases:</h5>
                <h5>{numberFormatter(data?.summary?.totalPurchase, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Purchase Cash:</h5>
                <h5>{numberFormatter(data?.summary?.totalPurchaseCash, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Purchase Credit:</h5>
                <h5>{numberFormatter(data?.summary?.totalPurchaseCredit, 2)}{settingData.currency}</h5>
              </div>

              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Purchase Cash</h5>
                <h5 style={{ color: data?.summary?.totalPurchaseCash >= 0 ? 'green' : 'red' }}>{numberFormatter(data?.summary?.totalPurchaseCash, 2)}{settingData.currency}</h5>
              </div>

            </div>



            {/*  Expenses  */}
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Expenses</h5>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>


              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Expenses:</h5>
                <h5>{numberFormatter(data?.summary?.totalExpense, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Expenses Cash:</h5>
                <h5>{numberFormatter(data?.summary?.totalExpenseCash, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Expenses Credit:</h5>
                <h5>{numberFormatter(data?.summary?.totalExpenseCredit, 2)}{settingData.currency}</h5>
              </div>

              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Expenses Cash</h5>
                <h5 style={{ color: data?.summary?.totalExpenseCash >= 0 ? 'green' : 'red' }}>{numberFormatter(data?.summary?.totalExpenseCash, 2)}{settingData.currency}</h5>
              </div>

            </div>



            {/*  Payments and Recieve  */}
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Payments and Receive</h5>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>


              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Payments:</h5>
                <h5>{numberFormatter(data?.summary?.totalPayments, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total Receive:</h5>
                <h5>{numberFormatter(data?.summary?.totalReceive, 2)}{settingData.currency}</h5>
              </div>

              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Total</h5>
                <h5 style={{ color: data?.summary?.totalReceive - data?.summary?.totalPayments >= 0 ? 'green' : 'red' }}>
                  {numberFormatter(data?.summary?.totalReceive - data?.summary?.totalPayments, 2)}{settingData.currency}</h5>
              </div>

            </div>


            <Divider style={{ marginTop: '10%', marginBottom: '5px' }} />


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '25px' }}>Balance:</h5>
              <h5 style={{ color: parseFloat(idRow.openingBalance) + parseFloat(data?.summary?.totalIn - data?.summary?.totalOut) > 0 ? 'green' : 'red', fontSize: '25px' }}>
                {numberFormatter(parseFloat(idRow.openingBalance) + parseFloat(data?.summary?.totalIn - data?.summary?.totalOut), 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '25px' }}>Closing Balance:</h5>
              <h5 style={{ color: parseFloat(idRow.closingBalance) > 0 ? 'green' : 'red', fontSize: '25px' }}>
                {numberFormatter(parseFloat(idRow.closingBalance), 2)}{settingData.currency}</h5>
            </div>

            <Divider style={{ marginTop: '10%', marginBottom: '5px' }} />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '25px' }}>Variance:</h5>
              <h5 style={{ color: parseFloat(idRow.total) > 0 ? 'green' : 'red', fontSize: '25px' }}>
                {numberFormatter(parseFloat(idRow.total), 2)}{settingData.currency}</h5>
            </div>


          </div>

        </div>
      </Card>
    </ConfigProvider>
  );



  return (
    <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal}>
      <Modal.Header style={{ background: "#39aaa5", display: "flex", justifyContent: "center" }}>
        <div>
          <Modal.Title style={{ color: "white" }}> Transaction ID :  {idRow.id}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>

        {loadingEffect &&
          <Loader />
        }

        {!loadingEffect &&
          <>
            {report}
          </>
        }


      </Modal.Body>


      <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button type="button" className="btn btn-secondary" style={{ backgroundColor: '#1f2937' }} onClick={toggleModal}>Close</button>

          <ReactToPrint trigger={() => <button type="button" style={{ backgroundColor: '#1d7790' }} className="btn btn-primary">Print</button>} content={() => reportRef.current} />
   
        </div>
      </div>

    </Modal>
  );
};

export default EndOfDayReport;
