import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import DatePicker from '../../components/filters/datePicker';
import { ConfigProvider, Card, Divider } from 'antd';
import { DataContext } from '../../init/getData';
import Loader from '../../components/loader/loader';
import { numberFormatter } from '../../components/formatters/numberFormatter';
import toast from 'react-hot-toast';
import DropDownFilter from '../../components/filters/dropDownFilter';

import { BiExport } from "react-icons/bi";

const IncomeStatement = (props) => {

  const { userData, settingData, api, wareHouseData, devData } = useContext(DataContext);

  const defaultWareHouseId = userData?.role?.defaultWareHouseId;

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [salesDiscounts, setSalesDiscounts] = useState(0);
  const [purchaseDiscounts, setPurchaseDiscounts] = useState(0);
  const [expenses, setExpenses] = useState([]);
  const [expensesBalance, setExpensesBalance] = useState(0);

  const today = new Date().toISOString().split('T')[0];
  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today, wareHouseId: defaultWareHouseId });

  const grossProfit = totalSales - (salesDiscounts + totalCost) + purchaseDiscounts;

  const componentRef = useRef(null);

  const getWareHouseName = wareHouseData.filter(warehouse => warehouse.id === filter.wareHouseId);
  const wareHouseName = getWareHouseName[0]?.name;

  useEffect(() => {
    if (loadingCounter == 1) {
      getIncomeStatement();
    }
  }, [filter.fromDate, filter.toDate, filter.wareHouseId, loadingCounter]);


  const getIncomeStatement = async () => {
    let data = {
      startRow: null,
      endRow: null,
      filter: filter,
    };

    const response = await api.get(`/incomeStatement/getall`, { params: data });
    if (response) {
      setTotalSales(response?.data?.summary?.totalSales + response?.data?.summary?.saleslDiscounts);
      setTotalCost(response?.data?.summary?.totalCost);
      setSalesDiscounts(response?.data?.summary?.saleslDiscounts);
      setExpensesBalance(response?.data?.summary?.totalExpenseBalance);
      setExpenses(response?.data?.summary?.expenseAccounts);
      setPurchaseDiscounts(response?.data?.summary?.pruchaseDiscounts);
    }
  };

  const card = (
    <ConfigProvider
      theme={{
        token: { colorPrimary: '#4a90e2' },
        components: {
          Card: {
            colorBgContainer: 'white',
            headerBg: '#39aaa5',
            headerFontSize: '20px',
            colorTextHeading: 'white',
          },
        },
      }}
    >
      <Card
        title={<div style={{ textAlign: 'center', fontSize: 40 }}>Income Statement</div>}
        bordered={true}
        style={{ width: '100%', alignSelf: 'center' }}
        headStyle={{ textAlign: 'center' }} 
        ref={componentRef}
      >
        <div style={{ width: '80%', margin: '0 auto' }}>


          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Date: </h5>
            <h5>{filter.fromDate} to {filter.toDate}</h5>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{devData.wareHouseName}: </h5>
            <h5>{wareHouseName}</h5>
          </div>


          <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />


          <br />
          <br />
          <br />


          <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Sales</h5>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Sales Revenue:</h5>
              <h5>{numberFormatter(totalSales, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Sales Discounts:</h5>
              <h5>{numberFormatter(salesDiscounts, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Cost of goods sold:</h5>
              <h5>{numberFormatter(totalCost, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Purchase Discounts:</h5>
              <h5>{numberFormatter(purchaseDiscounts, 2)}{settingData.currency}</h5>
            </div>

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Gross Profit:</h5>
              <h5 style={{ color: 'green' }}>{numberFormatter(grossProfit, 2)}{settingData.currency}</h5>
            </div>

            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Operating Expenses</h5>
            {expenses?.map((expense, index) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>{expense.name}:</h5>
                <h5>{numberFormatter(expense.totalInvoice, 2)}{settingData.currency}</h5>
              </div>
            ))}

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Total:</h5>
              <h5 style={{ color: 'red' }}>{numberFormatter(-expensesBalance, 2)}{settingData.currency}</h5>
            </div>


            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Final</h5>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Gross Profit:</h5>
                <h5>{numberFormatter(grossProfit, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Expenses:</h5>
                <h5>{numberFormatter(-expensesBalance, 2)}{settingData.currency}</h5>
              </div>

              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ color: 'green' }}>Net Income:</h5>
                <h5 style={{ color: 'green' }}>{numberFormatter(grossProfit - expensesBalance, 2)}{settingData.currency}</h5>
              </div>
            </div>
          </div>

        </div>
      </Card>
    </ConfigProvider>
  );

  return (
    <div className='card'>


      <div>
        <DatePicker setFilter={setFilter} filter={filter} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />

      </div>

      {loadingCounter < 1 &&
        <Loader />
      }
      {loadingCounter == 1 && (
        <>

          <div>
            <br />
            <div style={{ width: '60%', margin: '0 auto' }}>
              {card}
            </div>
            <ReactToPrint trigger={() => <BiExport size={30} color={'#2b7b76'} style={{ cursor: 'pointer' }} />} content={() => componentRef.current} />
          </div>

        </>
      )}
    </div>
  );
};

export default IncomeStatement;
