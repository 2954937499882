import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';


import BalanceAndVariance from './balanceAndVariance/balanceAndVariance';


import { DataContext } from '../init/getData';

import { FcPackage, FcOrgUnit, FcSettings, FcApproval, FcSurvey, FcHome } from "react-icons/fc";

function CashManagementTab(props) {

  const navigate = useNavigate();
  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('balanceAndVariance');

  useEffect(() => {
    navigate('/cashManagementTab/balanceAndVariance');
  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        {userRole.productPage &&
          <NavLink to="balanceAndVariance" onClick={() => setActiveTab('balanceAndVariance')}>
            <div className={`${activeTab === 'balanceAndVariance' ? 'tab-one active-link' : 'tab-one'}`} style={{width: 'auto'}}>
              <FcPackage size={33} />
              <span className="tab-text">Balance And Variance</span>
            </div>
          </NavLink>
        }


      </div>


      <Routes>

        <Route path='balanceAndVariance' element={<BalanceAndVariance />} />

      </Routes>

    </div>
  );
}

export default CashManagementTab;
