import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';


import Production from './production/production';
import StockValue from './stockValue/stockValue';
import StockActivity from './stockActivity/stockActivity';


import { DataContext } from '../init/getData';

import { FcPackage, FcOrgUnit, FcSettings, FcApproval, FcSurvey, FcHome } from "react-icons/fc";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { MdOutlineWarehouse } from "react-icons/md";

function ProductionTab(props) {

  const navigate = useNavigate();
  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('production');

  useEffect(() => {

    navigate('/productionTab/production');

  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        <NavLink to="production" onClick={() => setActiveTab('production')}>
          <div className={`${activeTab === 'production' ? 'tab-one active-link' : 'tab-one'}`}>
            <MdOutlineWarehouse size={30} />
            <span className="tab-text">Production</span>
          </div>
        </NavLink>


        <NavLink to="stockValue" onClick={() => setActiveTab('stockValue')}>
          <div className={`${activeTab === 'stockValue' ? 'tab-one active-link' : 'tab-one'}`}>
            <FcPackage size={30} />
            <span className="tab-text">Finished Goods</span>
          </div>
        </NavLink>


        <NavLink to="stockActivity" onClick={() => setActiveTab('stockActivity')}>
          <div className={`${activeTab === 'stockActivity' ? 'tab-one active-link' : 'tab-one'}`}>
            <FaArrowRightArrowLeft size={25} />
            <span className="tab-text">Stock Activity</span>
          </div>
        </NavLink>


      </div>


      <Routes>

        <Route path='production' element={<Production />} />
        <Route path='stockValue' element={<StockValue />} />
        <Route path='stockActivity' element={<StockActivity />} />

      </Routes>

    </div>
  );
}

export default ProductionTab;
