import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import toast from 'react-hot-toast';
import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';

import { DataContext } from '../../../init/getData';

function TransferModal(props) {

  const { api, userData } = useContext(DataContext);


  let { showModal, toggleModal, refreshTable, idRow, wareHouseOptions, selectedWareHouse } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);
  const [wareHouse, setWareHouse] = useState(false);

  const [transfer, setTransfer] = useState({ createdBy: userData.id });
  const [validated, setValidated] = useState(false);

  const selectedWareHouseId = selectedWareHouse.value;

  useEffect(() => {
    setTransfer(prev => ({ ...prev, productId: idRow.id, fromWareHouse: selectedWareHouse }));
  }, [idRow, selectedWareHouse]);

 
  wareHouseOptions = wareHouseOptions.filter(option => option.value !== selectedWareHouseId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);
    const form = document.getElementById("From");
    if (form.checkValidity() === false) { return };
    try {

      let res = await api.post(`/stockControl/transfer`, JSON.stringify(transfer))
      if (res.data.status) {
        toggleModal();
        if (refreshTable) { refreshTable() };
        resetForm();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setLoadingEffect(false);
    } catch (error) {
      toast.error("Failed Contact Support");
    } finally {
      setLoadingEffect(false);
      setValidated(false);
    }
  }

  // handle select change
  const resetForm = () => {
    setWareHouse({});
    setTransfer({ createdBy: userData.id })
  };

  // handle select change
  const handleSelectChange = (selectedOption, name) => {
    setWareHouse(selectedOption)
    setTransfer((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  // Generic handler for input changes
  const handleInputChange = (e, type) => {
    let { name, value } = e.target;
    if (type == 'number') {
      value = parseFloat(value)
    } else if (type == 'boolean') {
      value = JSON.parse(value)
    }
    setTransfer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  
  const closeModal = () => {
    toggleModal();
    if(resetForm){resetForm()};
    setValidated(false);
  }


  return (
    <Modal show={showModal} onHide={toggleModal} backdrop='static'>
      <Modal.Header style={{ background: "#39aaa5" }}>
        <Modal.Title className="arabicText" style={{ color: "white" }}>Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="arabicText">

      <Form validated={validated} id='From' onSubmit={handleSubmit}>


        <Row className="mb-3" >

          {/* Supplier */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>To wareHouse<span style={{ color: 'red' }}> *</span></Form.Label>
            <Select
              options={wareHouseOptions}
              isSearchable={true}
              placeholder="Select"
              onChange={(e) => handleSelectChange(e, 'wareHouseId')}
              value={wareHouse}
              required
            />
          </Form.Group>


        </Row>

        <Row className="mb-3" >
          {/* ID Number */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Quantity<span style={{ color: 'red' }}> *</span></Form.Label>
            <input type="number" step="any" className="form-control" name='quantity' required 
              onChange={(e) => handleInputChange(e, 'text')}
              value={transfer.quantity}
            ></input>
          </Form.Group>
        </Row>

        <Row className="mb-3" >
          {/* Details */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Details</Form.Label>
            <Form.Control as="textarea" rows={3}
              value={transfer.details}
              onChange={(e) => handleInputChange(e, 'text')}
            />
          </Form.Group>
        </Row>



        <div className="modal-footer">
          <Button type="primary" size="large" onClick={closeModal} danger>Close</Button>
          <Button type="primary" size="large" loading={loadingEffect} htmlType="submit"
            onClick={() => setValidated(true)}
            style={{ marginLeft: '10px', backgroundColor: '#1d7790' }}>Save</Button>
        </div>

        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default TransferModal;
