import toast, {Toaster} from 'react-hot-toast';



const saveOrder = async(api, tableId, cart, rate, closePaymentDone, toggleModal, total) => {

  let data = {
    tableId: tableId,
    cart: cart,
    rate: rate,
  }

  let printData = {
    tableId: tableId,
    cart: cart,
    total: total,
    rate: rate,
  }


  let res = await api.post(`dineIn/saveCart`, JSON.stringify(data));
  if(res.data.status){
  
    await printKitchen(api, printData);
    toast.success(res.data.message);
    closePaymentDone();
    toggleModal();
  }else{
    toast.error(res.data.message);
  }



 

  

}

const printKitchen = async(api, printData) => {
  let print = await api.post(`print/printKitchen`, JSON.stringify(printData));
  if(print.data.status){
  }else{
    toast.error(print.data.message);
  }

}


const editOrder = async(api, orderId, cart, total, discount, isTva, tva, rate, closePaymentDone) => {

  if(cart.length == 0){
    toast.error('cart is empty')
    return
  }
    
  let data = {
    orderId: orderId,
    cart: cart,
    total: total,
    discount: discount,
    tva: isTva? tva : 0,
    rate: rate,
  }
  let res = await api.post(`order/editCart`, JSON.stringify(data));
  if(res.data.status){
    toast.success(res.data.message);
    closePaymentDone();
  }else{
    toast.error(res.data.message);
  }

}

export {
  saveOrder,
};