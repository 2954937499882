import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';


import toast from 'react-hot-toast';
import Menu from '../menu/menu';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { DataContext } from '../init/getData';

import { Bar, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import DropDownFilter from '../components/filters/dropDownFilter';

Chart.register(CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Legend);




const Dashboard = (props) => {

  const { userData, settingData, devData, api } = useContext(DataContext);

  const [filter, setFilter] = useState({ year: moment().year() });
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [monthlySales, setMonthlySales] = useState([]);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Sales',
        data: monthlySales,
        backgroundColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };



  useEffect(() => {

    getData();

  }, [filter]);


  const getData = async () => {

    let data = {
      filter: filter,
    };

    await api.get(`/dashBoard/getSales`, { params: data }).then(response => {
      if (!response.data.status) {
        toast.error('Failed to get Data')
      } else {
        setMonthlySales(response.data?.monthlyTransactions)
      }
    }
    )

  }

  return (
    <div className='card' style={{ height: '99vh' }}>
      <span className='tittle' >Dashboard</span>

      <div style={{ marginTop: '20px' }}>
        <DatePicker
          picker="year"
          defaultValue={moment()}  // Set default value to the current year
          onChange={(date) => {
            if (date) {
              const year = date.year(); // Extract the year from the moment object
              setFilter((prevFilter) => ({
                ...prevFilter,
                year: year  // Set the selected year in the filter
              }));
            }
          }}
        />


        <DropDownFilter
          setFilter={setFilter}
          filterBy={'wareHouseId'}
          placeHolder={'All ' + devData.wareHouseName}
          filter={filter}
          setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)}
        />
      </div>



      <div style={{ display: 'flex' }}>
        <div style={{ width: '65%', height: '700px', paddingTop: '1%', paddingLeft: '2%' }}>
          <Bar data={data} options={options} />
        </div>


        <div style={{ width: '35%', height: '600px', paddingTop: '1%', paddingLeft: '5%' }}>
          <Pie data={data} options={options} />
        </div>
      </div>


    </div>

  );
}


export default Dashboard;