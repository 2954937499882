import React, { useState, useEffect, useRef, createContext } from 'react';




const Loader = () => {

 
  return (
    <>
    <div className="loginloader">
    </div>
    <h3 style={{marginLeft: '46%', marginTop: '45vh', position: 'absolute'}}>Loading Data ...</h3>
   </>
  );
}
export default Loader;
