import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';



function DataFilter(props) {

  const { selectedOption, setFilter, dataOptions, filterBy, placeHolder } = props;


  return (
    <div >


      <Select
        className='dropDownMedium'
        options={dataOptions}
        isSearchable={true}
        required
        placeholder={placeHolder}
        value={selectedOption}
        onChange={(selectedOption) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            [filterBy]: selectedOption.value
          }))
        }
      />


    </div>
  )
}

export default DataFilter;
