import React, { useState, useEffect, useContext } from 'react';


import AddModal from '../../../components/modals/addModal';
import EditModal from '../../../components/modals/editModal';

import Loader from '../../../components/loader/loader';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../../init/getData';




function SerializedProduct(props) {

  let {showAddModal, addModal, showEditModal, editModal, idRow, filter, refreshTableFunction } = props;


  const { userData, settingData, serverApi, api, refCategory, categoryData, refProductData, productData } = useContext(DataContext);
  const userRole = userData.role;
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  // Category Options
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  categoryOptions.push({ value: null, label: 'All Categories' })


  const [productUnits, setProductUnits] = useState([]);
  const [category, setCategory] = useState([]);
  const [serializedProduct, setSerializedProduct] = useState({});

  const product = serializedProduct;

  // idRow Change
  useEffect(() => {
    const categoryId = categoryOptions.find(option => option.value === idRow?.categoryId);
    setCategory(categoryId);
    setSerializedProduct(
      {
        createdBy: createdBy,
        type: idRow?.type || 'serialized',
        name: idRow?.name,
        barcode: idRow?.barcode,
        categoryId: categoryId?.value || filter.categoryId,
        sku: idRow?.sku,
        isStock: idRow?.isStock,
        isSale: idRow?.isSale,
        isTva: idRow?.isTva,
        cost: idRow?.cost || 0,
        price: idRow?.price || 0,
        wholeSalePrice: idRow.wholeSalePrice || 0,
        productUnits: idRow?.productUnits,
        color: idRow?.color || "#b6e3f4",
        details: idRow?.details,
        unit: idRow?.unit || 'Pcs',
      }
    )
    setProductUnits(idRow?.units || [])

  }, [idRow]);

  const table = 'product';
  const title = 'Serialized Product';



  // Add VarientRow
  const handleAddRow = () => {
      setProductUnits([...productUnits, { unit: '', value: '', productId: idRow?.id }]);
  };

  // Delete VarientRow
  const handleDeleteRow = (event) => {
    event.preventDefault()
      if (productUnits.length > 0) {
        const updatedRows = productUnits.slice(0, -1);
        setProductUnits(updatedRows);
      } else {
        toast.error('you need at least one variant')
      }
  };

  // Change Unit Array
  const handleUnitChange = (index, field, value) => {
    const updatedUnits = [...productUnits];
    const parsedValue = field === 'value' ? parseFloat(value) : value;
    updatedUnits[index] = { ...updatedUnits[index], [field]: parsedValue };
    setProductUnits(updatedUnits);
  }



  const resetForm = () => {
    setSerializedProduct({ createdBy: createdBy, type: 'serialized', categoryId: filter.categoryId || null, unit: 'Pcs' })
    setCategory('')
    setProductUnits([])
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
      setSerializedProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setCategory(selectedOption)
      setSerializedProduct((prevProduct) => ({
        ...prevProduct,
        [name]: selectedOption.value,
      }));
  };

  
  const formGeneral = {
    label: `General`,
    key: 1,
    children:

      <>

        {/* BarCode  */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Barcode </Form.Label>
            <Form.Control type="text" name='barcode' placeholder="optional"
              value={serializedProduct?.barcode || ''}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Name  */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="text" name='name'
              value={serializedProduct?.name || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          {/* Sku  */}
          {(filter.categoryId && !idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>SKU</Form.Label>
              <Form.Control type="text" name="sku"
                value={serializedProduct?.sku || ''}
                onChange={handleInputChange} />
            </Form.Group>
          }

        </Row>

        <Row className="mb-3" style={{ marginTop: '4%' }}>

          {/* Category  */}
          {(!filter.categoryId || idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>Category<span style={{ color: 'red' }}> *</span></Form.Label>
              <Select
                style={{ width: '100px' }}
                options={categoryOptions.filter(option => option.value !== null)}
                isSearchable={true}
                placeholder="Select"
                value={category}
                onChange={e => handleSelectChange(e, 'categoryId')}
                required
              />
            </Form.Group>
          }


          {/* Sku  */}
          {(!filter.categoryId || idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>SKU</Form.Label>
              <Form.Control type="text" name="sku"
                value={serializedProduct?.sku || ''}
                onChange={handleInputChange} />
            </Form.Group>
          }


        </Row>

        <Row className="mb-3" style={{ marginTop: '2%' }}>

          {/* Sale */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Salable<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isSale'
              value={serializedProduct?.isSale}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>


          {/* Stockable */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Stockable<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isStock'
              value={serializedProduct?.isStock}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>
        </Row>

        <Row className="mb-3" style={{ marginTop: '4%' }}>

          {/* Cost */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Cost<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="number" step="any" name='cost'
              value={serializedProduct?.cost}
              onChange={handleInputChange} />
          </Form.Group>

          {/* WholeSalePrice */}
          {userRole.wholeSale &&
            <Form.Group as={Col} >
              <Form.Label className='formLabel'>WholeSale Price</Form.Label>
              <Form.Control type="number" name="wholeSalePrice"
                value={serializedProduct?.wholeSalePrice}
                onChange={handleInputChange} />
            </Form.Group>
          }


          {/* Price */}
          <Form.Group as={Col} >
            <Form.Label className='formLabel'>Price<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="number" name="price"
              value={serializedProduct?.price}
              onChange={handleInputChange} />
          </Form.Group>
        </Row>

        <Row className="mb-3" style={{ marginTop: '2%' }}>

          {/* Details */}
          <Form.Group as={Col} sm={6}>
            <Form.Label className='formLabel'>Details</Form.Label>
            <Form.Control as="textarea" name="details"
              value={serializedProduct?.details || ''}
              onChange={handleInputChange} />
          </Form.Group>

          {/* isTva */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>TVA<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isTva'
              value={serializedProduct?.isTva}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>


          <Form.Group as={Col}>
            <Form.Label className='formLabel' htmlFor="exampleColorInput">Box Color</Form.Label>
            <Form.Control
              type="color"
              name="color"
              title="Choose your color"
              value={serializedProduct.color}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>
      </>


  };

  const secondaryUnit = {
    label: `Units`,
    key: 2,
    children: (
      <>
        <Row className="mb-3">

          {/* Product Unit  */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Product Unit</Form.Label>
            <Form.Control type="text" name="unit"
              value={serializedProduct?.unit}
              onChange={handleInputChange} />
          </Form.Group>

        </Row>

        {productUnits.map((row, index) => (
          <div key={index}>
            {/* Render the labels only for the first row */}
            {index === 0 && (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='formLabel'>Unit</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='formLabel'>Value</Form.Label>
                </Form.Group>
              </Row>
            )}

            <Row className="mb-3" style={{ marginTop: '-20px' }}>

              <Form.Group as={Col}>
                <Form.Control type="text" name="unit"
                  onChange={(e) => handleUnitChange(index, 'unit', e.target.value)}
                  value={row.unit} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control type="number" step="any" name="value"
                  onChange={(e) => handleUnitChange(index, 'value', e.target.value)}
                  value={row.value} />
              </Form.Group>



            </Row>

            <Row key={index + 100} className="mb-3">

            </Row>
          </div>
        ))}

        <Form.Group
          as={Col}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddRow} />
          <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteRow(e)} />
        </Form.Group>
      </>
    )
  }



  const formView = (
    <div style={{ userSelect: 'none' }}>

      <Tabs
        defaultActiveKey="1"
        centered
        items={[
          {
            label: `General`,
            key: 1,
            children:

              <>

                {/* BarCode  */}
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Barcode </Form.Label>
                    <Form.Control type="text" name='barcode' placeholder="optional"
                      value={serializedProduct?.barcode || ''}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  {/* Name  */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="text" name='name'
                      value={serializedProduct?.name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  {/* Sku  */}
                  {(filter.categoryId && !idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>SKU</Form.Label>
                      <Form.Control type="text" name="sku"
                        value={serializedProduct?.sku || ''}
                        onChange={handleInputChange} />
                    </Form.Group>
                  }

                </Row>

                <Row className="mb-3" style={{ marginTop: '4%' }}>

                  {/* Category  */}
                  {(!filter.categoryId || idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>Category<span style={{ color: 'red' }}> *</span></Form.Label>
                      <Select
                        style={{ width: '100px' }}
                        options={categoryOptions.filter(option => option.value !== null)}
                        isSearchable={true}
                        placeholder="Select"
                        value={category}
                        onChange={e => handleSelectChange(e, 'categoryId')}
                        required
                      />
                    </Form.Group>
                  }


                  {/* Sku  */}
                  {(!filter.categoryId || idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>SKU</Form.Label>
                      <Form.Control type="text" name="sku"
                        value={serializedProduct?.sku || ''}
                        onChange={handleInputChange} />
                    </Form.Group>
                  }


                </Row>

                <Row className="mb-3" style={{ marginTop: '2%' }}>

                  {/* Sale */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Salable<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isSale'
                      value={serializedProduct?.isSale}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>


                  {/* Stockable */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Stockable<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isStock'
                      value={serializedProduct?.isStock}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>
                </Row>

                <Row className="mb-3" style={{ marginTop: '4%' }}>

                  {/* Cost */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Cost<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="number" step="any" name='cost'
                      value={serializedProduct?.cost}
                      onChange={handleInputChange}
                      required />
                  </Form.Group>

                  {/* WholeSalePrice */}
                  {userRole.wholeSale &&
                    <Form.Group as={Col} >
                      <Form.Label className='formLabel'>WholeSale Price</Form.Label>
                      <Form.Control type="number" name="wholeSalePrice"
                        value={serializedProduct?.wholeSalePrice}
                        onChange={handleInputChange} 
                        required />
                    </Form.Group>
                  }


                  {/* Price */}
                  <Form.Group as={Col} >
                    <Form.Label className='formLabel'>Price<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="number" name="price"
                      value={serializedProduct?.price}
                      onChange={handleInputChange} 
                      required />
                  </Form.Group>
                </Row>

                <Row className="mb-3" style={{ marginTop: '2%' }}>

                  {/* Details */}
                  <Form.Group as={Col} sm={6}>
                    <Form.Label className='formLabel'>Details</Form.Label>
                    <Form.Control as="textarea" name="details"
                      value={serializedProduct?.details || ''}
                      onChange={handleInputChange} />
                  </Form.Group>

                  {/* isTva */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>TVA<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isTva'
                      value={serializedProduct?.isTva}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>


                  <Form.Group as={Col}>
                    <Form.Label className='formLabel' htmlFor="exampleColorInput">Box Color</Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      title="Choose your color"
                      value={serializedProduct.color}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Row>
              </>


          }
        ]}

      />


    </div >);


  const modalFooter = (
    <div>

    </div>
  );



  return (
    <>

     

          <AddModal
            showModal={showAddModal}
            toggleModal={() => addModal('serialized')}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ product, productUnits }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={() => editModal('serialized', null)}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, product, productUnits }}
          />

    </>
  )
}

export default SerializedProduct;
