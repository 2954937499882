import React, { useState, useEffect, useContext } from 'react';


function CustomButton(props) {

  const { method, tittle, color } = props;


  return (
    <div>
      <button onClick={method} type="button" style={{backgroundColor: color, color: 'white', float: 'right'}} className="btn "  >
        {tittle}
      </button>

    </div>
  )
}

export default CustomButton;
