
  
  
  function numberFormatter(number, decimal) {
    number = parseFloat(number);
    decimal = parseFloat(decimal);
    const roundedNumber = Number(number.toFixed(decimal)); // Round to two decimal places
    let formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(decimal);
    formattedNumber = parseFloat(formattedNumber).toLocaleString();
    return formattedNumber;
  }

  
  export {
    numberFormatter,
  };