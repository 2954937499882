import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import Transaction from './transaction/transaction';
import PurchaseInvoice from './purchaseInvoice/purchaseInvoice';
import SalesInvoice from './salesInvoice/salesInvoice';
import ExpenseInvoice from './expenseInvoice/expenseInvoice';
import BankTransaction from './bankTransaction/bankTransaction';
import DeliveryTransaction from './deliveryTransacion/deliveryTransacion';

import { NavDropdown } from 'react-bootstrap';

import { FcBusinessman, FcManager, FcPortraitMode, FcAutomotive, FcInternal, FcShipped, FcDonate, FcKindle } from "react-icons/fc";


import { DataContext } from '../init/getData';

function TransactionsTab(props) {

  const { settingData, userData, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('customer');
  const [salesHeader, setSalesHeader] = useState('Sales Invoice');
  const [purchaseHeader, setPurchaseHeader] = useState('Purchase Invoice');
  const [accountHeader, setAccountHeader] = useState('Customer');


  useEffect(() => {
    if (userRole.customerAccountPage) {
      navigate('/transactionsTab/customer');
    }

  }, []);



  const changeSalesHeader = (header, navigateTo) => {
    setSalesHeader(header)
    navigate(navigateTo);
  }

  const changePurchaseHeader = (header, navigateTo) => {
    setPurchaseHeader(header)
    navigate(navigateTo);
  }

  const changeAccountHeader = (header, navigateTo) => {
    setAccountHeader(header)
    navigate(navigateTo);
  }


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>


        {userRole.purchaseInvoicePage &&
          <div  className={`${activeTab === 'customer' ? 'tab-four active-link' : 'tab-four'}`} style={{ width: '17%' }}>
            <FcBusinessman size={30} color={'#ffccbc'} />
            <NavDropdown title={<span className="navDropdown">Account: {accountHeader}</span>}  onClick={() => setActiveTab('customer')} >
              {userRole.customerAccountPage &&
                <NavDropdown.Item onClick={() => changeAccountHeader('Customer', 'customer')} style={{ color: 'white !important' }}>Customer</NavDropdown.Item>
              }
              {userRole.supplierAccountPage &&
                <NavDropdown.Item onClick={() => changeAccountHeader('Supplier', 'supplier')} style={{ color: 'white !important' }}>Supplier</NavDropdown.Item>
              }
              {userRole.expenseAccountPage &&
                <NavDropdown.Item onClick={() => changeAccountHeader('Expense', 'expenses')} style={{ color: 'white !important' }}>Expense</NavDropdown.Item>
              }
              {userRole.deliveryAccountPage &&
                <NavDropdown.Item onClick={() => changeAccountHeader(devData.deliveryName, 'delivery')} style={{ color: 'white !important' }}>{devData.deliveryName}</NavDropdown.Item>
              }
              <NavDropdown.Item onClick={() => changeAccountHeader('Bank', 'bank')} style={{ color: 'white !important' }}>Bank</NavDropdown.Item>

            </NavDropdown>
          </div>
        }



        {userRole.salesInvoicePage &&
          <div onClick={() => setActiveTab('salesInvoice')} className={`${activeTab === 'salesInvoice' ? 'tab-seven active-link' : 'tab-seven'}`} style={{ width: '15%' }}>
            <FcKindle size={30} color={'#ffccbc'} />
            <NavDropdown title={<span className="navDropdown">{salesHeader}</span>} >
              <NavDropdown.Item onClick={() => changeSalesHeader('Sales Invoice', 'salesInvoice')} style={{ color: 'white !important' }}>Sales Invoice</NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeSalesHeader('Sales Return', 'salesReturn')} style={{ color: 'white !important' }}>Sales Return</NavDropdown.Item>
            </NavDropdown>
          </div>
        }

        {userRole.purchaseInvoicePage &&
          <div onClick={() => setActiveTab('purchaseInvoice')} className={`${activeTab === 'purchaseInvoice' ? 'tab-two active-link' : 'tab-two'}`} style={{ width: '15%' }}>
            <FcKindle size={30} color={'#ffccbc'} />
            <NavDropdown title={<span className="navDropdown">{purchaseHeader}</span>} >
              <NavDropdown.Item onClick={() => changePurchaseHeader('Purchase Invoice', 'purchaseInvoice')} style={{ color: 'white !important' }}>Purchase Invoice</NavDropdown.Item>
              <NavDropdown.Item onClick={() => changePurchaseHeader('Purchase Return', 'purchaseReturn')} style={{ color: 'white !important' }}>Purchase Return</NavDropdown.Item>
            </NavDropdown>
          </div>
        }


        {userRole.expenseInvoicePage &&
          <NavLink to="expenseInvoice" onClick={() => setActiveTab('expenseInvoice')}>
            <div className={`${activeTab === 'expenseInvoice' ? 'tab-four active-link' : 'tab-four'}`} style={{ width: '15%' }}>
              <FcKindle size={30} />
              <span className="tab-text">Expense Invoice</span>
            </div>
          </NavLink>
        }

      </div>


      <Routes>



        <Route path='customer' element={<Transaction type={'Customer'} />} />
        <Route path='supplier' element={<Transaction type={'Supplier'} />} />
        <Route path='employee' element={<Transaction type={'Employee'} />} />
        <Route path='expenses' element={<Transaction type={'Expenses'} />} />

        
        <Route path='delivery' element={<DeliveryTransaction/>} />
        <Route path='bank' element={<BankTransaction/>} />

        <Route path='salesInvoice' element={<SalesInvoice type={'SalesInvoice'} isOut={false} />} />
        <Route path='salesReturn' element={<SalesInvoice type={'SalesReturn'} isOut={true} />} />

        <Route path='purchaseInvoice' element={<PurchaseInvoice type={'PurchaseInvoice'} isOut={true} />} />
        <Route path='purchaseReturn' element={<PurchaseInvoice type={'PurchaseReturn'} isOut={false} />} />
        <Route path='expenseInvoice' element={<ExpenseInvoice />} />

      </Routes>

    </div>
  );
}

export default TransactionsTab;
