import React, { useRef, useEffect, useState } from 'react';
import { roundedNumberFormatter, numberFormatter } from '../formatters/numberFormatter';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import JsBarcode from 'jsbarcode';
import moment from 'moment';



const ComponentToPrint = React.forwardRef((props, ref) => {


  const { settingData, userName, devData, printData } = props;

  const invoiceId = printData?.invoiceId;
  const createdAt = printData?.createdAt;
  const date = printData?.date;
  const customer = printData?.customer;
  const delivery = printData?.delivery;
  const cart = printData?.cart;
  const total = printData?.total;
  const discount = printData?.discount;

  const [barcodeDataUrl, setBarcodeDataUrl] = useState('');

  const styles = {
    receipt: {
      width: `${settingData?.invoiceWidth}%`,
      margin: '0%',
      padding: '1%',
      border: '2px solid #e0e0e0',
      borderRadius: '10px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '10px',
      borderBottom: '1px solid #414448',
      color: 'black',
    },
    logo: {
      width: '100px',
      height: 'auto',
    },
    details: {
      marginBottom: '8px',
      borderBottom: '1px solid #414448',
      fontSize: '1rem',
      color: 'black',
    },
    detailsFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'left',
      color: 'black',
    },
    items: {
      marginBottom: '30px',
      paddingBottom: '15px',
      color: 'black',
    },
    table: {
      width: '100%',
      marginTop: '20px',
      fontSize: '1rem',
    },
    th: {
      paddingTop: '20px',
      textAlign: 'left',
      fontWeight: 'bold',
      color: 'black',
    },
    td: {
      textAlign: 'left',
      color: 'black',
      maxWidth: '180px',  // Set a maximum width (adjust as needed)
      wordWrap: 'break-word',  // Break words to the next line if necessary
      whiteSpace: 'normal',  // Allow text to wrap to the next line 
    },
    discount: {
      borderTop: '1px solid #414448',
      fontSize: '1.1rem',
      color: 'black'
    },
    summary: {
      marginTop: '-10px',
      fontSize: '1.1rem',
      color: 'black'
    },
    total: {
      fontWeight: '700',
      fontSize: '1.2rem',
    },
    footer: {
      textAlign: 'center',
      fontSize: '0.9em',
      color: 'black',
      paddingTop: '10px',
      borderTop: '1px solid #ddd',
    },
    barcode: {
      display: 'block',
      margin: '10px auto',
      width: '150px', // Adjusted size to be smaller
      height: '50px', // Adjusted height to be thinner
    },
    date: {
      textAlign: 'center',
      fontSize: '0.9rem',
      marginBottom: '10px',
      color: 'black',
    },
  };

  let totalTvapaid = 0;
  if (cart) {
    for (const product of cart) {
      if (product.isTva) {
        totalTvapaid += parseFloat((product.totalPrice * settingData?.tva / 100))
      }
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, invoiceId, {
      format: 'CODE128',
      width: 1, // Thinner barcode
      height: 40, // Smaller height
      displayValue: false,
    });
    setBarcodeDataUrl(canvas.toDataURL());
  }, [invoiceId]);

  return (
    <div ref={ref} style={styles.receipt}>
      <header style={styles.header}>
        <h1>{settingData?.printingName}</h1>
        <p>{settingData?.printingAddress}</p>
        <p>Phone: {settingData?.printingPhone}</p>
      </header>

      <section style={styles.details}>
        <div style={styles.detailsFlex}>
          <p><strong>Served By:</strong> {userName}</p>
          <p><strong>Invoice ID:</strong> {invoiceId}</p>
        </div>
      </section>



      {(customer?.name || delivery?.name) && (
        <section style={styles.details}>
          {customer?.name && (
            <>
              <div style={styles.detailsFlex}>
                <p><strong>Customer:</strong> {customer.name}</p>
                <p><strong>Phone:</strong> {customer.phone}</p>
              </div>
              <p><strong>Address:</strong> {customer.address}</p>
            </>
          )}

          {delivery?.name && (
            <>
              <div style={styles.detailsFlex}>
                <p><strong>{devData?.deliveryName}:</strong> {delivery.name}</p>
                <p><strong>Phone:</strong> {delivery.phone}</p>
              </div>
            </>
          )}
        </section>
      )}




      <section style={styles.items}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Item</th>
              <th style={styles.th}>Qty</th>
              <th style={styles.th}>Dis</th>
              <th style={styles.th}>Price</th>
            </tr>
          </thead>
          <tbody>
            {cart && cart.map((product, index) => (
              <React.Fragment key={product.id}>
                <tr>
                  <td style={styles.td}>{product.name}</td>
                  <td style={styles.td}>{numberFormatter(product.quantity, 2)}</td>
                  <td style={styles.td}>{numberFormatter(product.discount, 2)}%</td>
                  {!settingData?.isRated &&
                    <td style={styles.td}>{numberFormatter(product.totalPrice, 2)} {settingData?.currency}</td>
                  }
                  {settingData?.isRated &&
                    <td style={styles.td}>{roundedNumberFormatter(product.totalPrice * settingData?.rate, 2)} {settingData?.ratedCurrency}</td>
                  }
                </tr>
                {product.details && (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'left', paddingLeft: '20px' }}>
                      {product.details}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </section>

      <section style={styles.discount}>
        <div style={styles.detailsFlex}>
          <p><strong>Discount:</strong></p>
          <p>{discount}%</p>
        </div>
      </section>

      {settingData?.defaultTva &&
        <section style={styles.summary}>
          <div style={styles.detailsFlex}>
            <p><strong>TVA {settingData?.tva}%:</strong></p>
            {!settingData?.isRated &&
              <p>{numberFormatter(totalTvapaid)}{settingData?.currency}</p>
            }
            {settingData?.isRated &&
              <p>{roundedNumberFormatter(totalTvapaid * settingData?.rate)}{settingData?.ratedCurrency}</p>
            }
          </div>
        </section>
      }

      <section style={styles.summary}>
        <div style={styles.detailsFlex}>
          <p className={styles.total}>
            <strong>Total:</strong>
          </p>
          <p style={{ textAlign: 'right', color: 'black' }}>
            {settingData?.isRated &&
              <p>{roundedNumberFormatter(total * settingData?.rate, 2)} {settingData?.ratedCurrency}</p>
            }
            {settingData?.showRateOnInvoice && settingData?.rateMultiply &&
              <p style={{ textAlign: 'right', color: 'black' }}>
                {roundedNumberFormatter(total * settingData?.rate, 2)} {settingData?.ratedCurrency}</p>
            }
            {settingData?.showRateOnInvoice && !settingData?.rateMultiply &&
              <p style={{ textAlign: 'right', color: 'black' }}>{numberFormatter(total / settingData?.rate, 2)} {settingData?.ratedCurrency}</p>
            }
            {numberFormatter(total, 2)} {settingData?.currency}
          </p>

        </div>
      </section>

      <footer style={styles.footer}>
        {/* Display current date above the barcode */}
        <p style={styles.date}>{createdAt? dateTableFormatter(createdAt) : date}</p>
        {barcodeDataUrl && (
          <img src={barcodeDataUrl} alt="Barcode" style={styles.barcode} />
        )}
        <p style={{ textAlign: 'center', color: 'black' }}>By Riser Software</p>
        <p style={{ textAlign: 'center', color: 'black', marginTop: '-20px' }}>Whatsapp: 78937719</p>
        <p style={{ textAlign: 'center', color: 'black', marginTop: '-20px' }}>www.riserteam.com</p>
      </footer>
    </div>
  );
});

export default ComponentToPrint;