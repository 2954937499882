import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DataContext } from '../../../init/getData';

const PrinterAdd = (props) => {

  const { api, userData, refreshPrinter } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;
  
  const { showModal, toggleModal, tittle, refreshTable } = props;

  const [loadingEffect, setLoadingEffect] = useState(false);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);
    const form = document.getElementById("Form");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    
    let res = await api.post(`/printer/add`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshPrinter();
      form.reset();
      refreshTable();
      toggleModal();
    }else{
      toast.error(res.data.message);
    }
 
    setLoadingEffect(false);
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add {tittle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Form onSubmit={handleSubmit} id='Form'>


    <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <input type="text"  name="name" id="name" className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
            <Form.Label>interface</Form.Label>

            <select className="form-control" id='interface' name='interface' >
            <option value='USB'>USB</option>
            <option value='LAN'>LAN</option>
            </select>

          </Form.Group>

        </Row>



      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
        <Form.Label>Type</Form.Label>

        <select className="form-control" id='type' name='type' >
        <option value='invoice'>invoice</option>
        <option value='section'>section</option>
        </select>

      </Form.Group>

        <Form.Group as={Col}>
            <Form.Label>IpAddress</Form.Label>
            <input type="text"  name="ipAddress" id="ipAddress" className="form-control"></input>
          </Form.Group>

        </Row>


        <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Tittle</Form.Label>
          <input type="text"  name="tittle" id="tittle" className="form-control"></input>
        </Form.Group>

      <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <input type="text"  name="phone" id="phone" className="form-control"></input>
        </Form.Group>

      </Row>




    <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>Save Data
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>
      </div>

      </Form>

      </Modal.Body>
      </Modal>
      )
}


export default PrinterAdd;