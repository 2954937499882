import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import Sales from './sales/sales';

import { DataContext } from '../init/getData';
import { Badge } from 'antd';
import { FcPackage, FcOrgUnit, FcPlus, FcApproval, FcShipped, FcInTransit } from "react-icons/fc";

function SalesTab(props) {

  const navigate = useNavigate();
  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [totalNew, setTotalNew] = useState(0);
  const [totalProcessing, setTotalProcessing] = useState(0);
  const [totalDone, setTotalDone] = useState(0);
  const [totalAll, setTotalAll] = useState(0);
  const [activeTab, setActiveTab] = useState('sales');

  useEffect(() => {

    navigate('/orderTab/sales');

  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        <NavLink to="sales" onClick={() => setActiveTab('sales')}>
          <div className={`${activeTab === 'sales' ? 'tab-one active-link' : 'tab-one'}`}>
            <FcPlus size={25} />
            <span className="tab-text">Sales</span>
            <div style={{ position: 'absolute', top: '1%', left: '13%' }}><Badge count={totalNew} /></div>
          </div>
        </NavLink>

        {userRole.useOrderAction &&
          <NavLink to="orders" onClick={() => setActiveTab('orders')}>
            <div className={`${activeTab === 'orders' ? 'tab-two active-link' : 'tab-two'}`} >
              <FcInTransit size={35} style={{ color: '#ff9800' }} />
              <span className="tab-text">Orders</span>
              <div style={{ position: 'absolute', top: '1%', left: '28%' }}><Badge count={totalProcessing} /></div>
            </div>
          </NavLink>
        }




      </div>


      <Routes>

        <Route path='sales' element={<Sales type={['Sale', 'Return']} isOrder={false} setTotalNew={setTotalNew} />} />
        <Route path='orders' element={<Sales type={['Sale', 'Return']} isOrder={true} setTotalProcessing={setTotalProcessing} />} />

      </Routes>

    </div>
  );
}

export default SalesTab;
