import React, { useState, useEffect, useContext } from 'react';


import AddModal from '../../../components/modals/addModal';
import EditModal from '../../../components/modals/editModal';

import Loader from '../../../components/loader/loader';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../../init/getData';




function ProductionProduct(props) {

  let {showAddModal, addModal, showEditModal, editModal, idRow, filter, refreshTableFunction } = props;


  const { userData, settingData, serverApi, api, refCategory, categoryData, refProductData, productData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoadingCompound, setIsLoadingCompound] = useState(true);

  const createdBy = parseInt(userData.id);



    useEffect(() => {
      if(showAddModal || showEditModal){
      getProductsForCompound();
      }
    }, [showAddModal, showEditModal]);

  // get data for compound
  const getProductsForCompound = async () => {
    try {
      setIsLoadingCompound(true);
      await refProductData();
      setIsLoadingCompound(false);
    } catch (error) {
      console.error('Error refreshing data Contact Support');
    }
  };

  const forCompoundProducts = productData.filter(product => (product.type == 'simple' || product.type == 'serialized'));

  // Category Options
  const compoundProductOptions = forCompoundProducts.map(item => ({
    value: item.id,
    label: item.name + '[' + item.barcode + ']',
    cost: parseFloat(item.cost),
  }));

  // Category Options
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  categoryOptions.push({ value: null, label: 'All Categories' })


  const [productUnits, setProductUnits] = useState([]);
  const [productCompounds, setProductCompounds] = useState([]);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState('');
  const [compoundProduct, setCompoundProduct] = useState({});

  const product = compoundProduct;

  // idRow Change
  useEffect(() => {
    const categoryId = categoryOptions.find(option => option.value === idRow?.categoryId);
    setCategory(categoryId);
    setProductUnits(idRow?.units || [])
    setCompoundProduct(
      {
        createdBy: createdBy,
        type: idRow?.type || 'production',
        name: idRow?.name || '',
        barcode: idRow?.barcode,
        categoryId: categoryId?.value || filter.categoryId,
        sku: null,
        isStock: true,
        isSale: idRow?.isSale,
        isTva: idRow?.isTva,
        cost: idRow?.cost || 0,
        price: idRow?.price || 0,
        wholeSalePrice: idRow?.wholeSalePrice || 0,
        color: idRow?.color || "#b6e3f4",
        details: idRow?.details,
        unit: idRow?.unit || 'Pcs',
      }
    )
    if (idRow.productCompounds && !isLoadingCompound) {
      const updatedProductCompounds = idRow.productCompounds?.map(compound => {
        const matchingOption = compoundProductOptions.find(option => option.value === compound.compoundId);
        return { ...compound, cost: matchingOption ? matchingOption.cost : compound.cost };
      });
      setProductCompounds(updatedProductCompounds)
    } else {
      setProductCompounds([{ value: '', productId: idRow?.id, cost: '', compoundId: '' }])
    }
  }, [idRow, isLoadingCompound]);

  const table = 'product';
  const title = 'Production Product';



  // Add VarientRow
  const handleAddRow = () => {
      setProductCompounds([...productCompounds, { value: '', productId: idRow?.id, cost: '', compoundId: '' }]);
  };

  // Delete VarientRow
  const handleDeleteRow = (event) => {
    event.preventDefault()
      if (productCompounds.length > 1) {
        const updatedRows = productCompounds.slice(0, -1);
        setProductCompounds(updatedRows);
      } else {
        toast.error('you need at least one compound')
      }
  };

  // Change Unit Array
  const handleUnitChange = (index, field, value) => {
    const updatedUnits = [...productUnits];
    const parsedValue = field === 'value' ? parseFloat(value) : value;
    updatedUnits[index] = { ...updatedUnits[index], [field]: parsedValue };
    setProductUnits(updatedUnits);
  }

  // Change Compound Array
  const handleCompoundChange = (index, field, value) => {
    if (field == 'compoundId') {
      const cost = parseFloat(value.cost);
      value = value.value;
      const updatedCompounds = [...productCompounds];
      const parsedValue = field === 'value' ? parseFloat(value) : value;
      updatedCompounds[index] = { ...updatedCompounds[index], [field]: parsedValue, cost: cost };
      setProductCompounds(updatedCompounds);
    } else {
      const updatedCompounds = [...productCompounds];
      const parsedValue = field === 'value' ? parseFloat(value) : value;
      updatedCompounds[index] = { ...updatedCompounds[index], [field]: parsedValue };
      setProductCompounds(updatedCompounds);
    }

  }

  // change compound cost
  useEffect(() => {
    let totalCost = 0;
    if (productCompounds && productCompounds.length > 0) {
      for (const product of productCompounds) {
        totalCost += parseFloat(product.cost * product.value);
      }
      setCompoundProduct((prevProduct) => ({
        ...prevProduct,
        cost: totalCost,
      }));
    }
  }, [productCompounds]);




  const resetForm = () => {
    setCompoundProduct({ createdBy: createdBy, type: 'production', categoryId: filter.categoryId || null, unit: 'Pcs' })
    setProductCompounds([{ value: '', productId: '', cost: '', compoundId: '' }]);
    setCategory('')
    setProductUnits([])
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
      setCompoundProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setCategory(selectedOption)
      setCompoundProduct((prevProduct) => ({
        ...prevProduct,
        [name]: selectedOption.value,
      }));
    
  };



  const formView = (

    <div>

      {isLoadingCompound &&
        <Loader />
      }

      {!isLoadingCompound &&
        <>
          <Tabs
            defaultActiveKey="1"
            centered
            items={[
              {
                label: `General`,
                key: 1,
                children: (
                  <>

                    {/* BarCode  */}
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Barcode </Form.Label>
                        <Form.Control type="text" name='barcode' placeholder="optional"
                          value={compoundProduct?.barcode}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      {/* Name  */}
                      <Form.Group as={Col}>
                        <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control type="text" name='name'
                          value={compoundProduct?.name}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>

                    </Row>


                    <Row className="mb-3" style={{ marginTop: '4%' }}>

                      {/* Category  */}
                      {(!filter?.categoryId || idRow?.categoryId) &&
                        <Form.Group as={Col} sm={6}>
                          <Form.Label className='formLabel'>Category<span style={{ color: 'red' }}> *</span></Form.Label>
                          <Select
                            style={{ width: '100px' }}
                            options={categoryOptions.filter(option => option.value !== null)}
                            isSearchable={true}
                            placeholder="Select"
                            value={category}
                            onChange={e => handleSelectChange(e, 'categoryId')}
                            required
                          />
                        </Form.Group>
                      }

                      {/* Salable */}
                      <Form.Group as={Col} >
                        <Form.Label className='formLabel'>Salable<span style={{ color: 'red' }}> *</span></Form.Label>
                        <select className="form-control" name='isSale'
                          value={compoundProduct?.isSale}
                          onChange={handleInputChange}>
                          <option value='true'>yes</option>
                          <option value='false'>no</option>
                        </select>
                      </Form.Group>

                    </Row>

                    <Row className="mb-3" style={{ marginTop: '5%' }}>

                      {/* Details */}
                      <Form.Group as={Col} sm={6}>
                        <Form.Label className='formLabel'>Details</Form.Label>
                        <Form.Control as="textarea" name="details"
                          value={compoundProduct?.details || ''}
                          onChange={handleInputChange} />
                      </Form.Group>

                      {/* isTva */}
                      <Form.Group as={Col}>
                        <Form.Label className='formLabel'>TVA<span style={{ color: 'red' }}> *</span></Form.Label>
                        <select className="form-control" name='isTva'
                          value={compoundProduct?.isTva}
                          onChange={handleInputChange}>
                          <option value='true'>yes</option>
                          <option value='false'>no</option>
                        </select>
                      </Form.Group>


                      {/* Product Unit  */}
                      <Form.Group as={Col} >
                        <Form.Label className='formLabel'>Product Unit</Form.Label>
                        <Form.Control type="text" name="unit"
                          value={compoundProduct?.unit}
                          onChange={handleInputChange} />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label className='formLabel' htmlFor="exampleColorInput">Box Color</Form.Label>
                        <Form.Control
                          type="color"
                          name="color"
                          title="Choose your color"
                          value={compoundProduct.color}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>
                  </>
                )
              },
              {
                label: `Compounds`,
                key: 2,
                children: (
                  <>


                    <Row className="mb-3">

                      {/* Cost */}
                      <Form.Group as={Col}>
                        <Form.Label className='formLabel'>Cost<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control type="number" step="any" name='cost'
                          value={compoundProduct?.cost}
                          onChange={handleInputChange}
                          required />
                      </Form.Group>

                      {/* WholeSalePrice */}
                      {userRole.wholeSale &&
                        <Form.Group as={Col} >
                          <Form.Label className='formLabel'>WholeSale Price</Form.Label>
                          <Form.Control type="number" name="wholeSalePrice"
                            value={compoundProduct?.wholeSalePrice}
                            onChange={handleInputChange} 
                            required />
                        </Form.Group>
                      }


                      {/* Price */}
                      <Form.Group as={Col} >
                        <Form.Label className='formLabel'>Price<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control type="number" name="price"
                          value={compoundProduct?.price}
                          onChange={handleInputChange} 
                          required />
                      </Form.Group>
                    </Row>
                    {
                      productCompounds?.map((row, index) => (
                        <div key={index}>
                          {/* Render the labels only for the first row */}
                          {index === 0 && (
                            <Row className="mb-3">
                              <Form.Group as={Col}>
                                <Form.Label className='formLabel'>Product</Form.Label>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label className='formLabel'>Value</Form.Label>
                              </Form.Group>
                            </Row>
                          )}

                          <Row className="mb-3" style={{ marginTop: '-20px' }}>

                            <Form.Group as={Col} sm={6}>
                              <Select
                                style={{ width: '100px' }}
                                options={compoundProductOptions.filter(option => option.value !== null)}
                                onChange={(e) => handleCompoundChange(index, 'compoundId', e)}
                                value={compoundProductOptions.find(option => option.value === row.compoundId) || []}
                                isSearchable={true}
                                placeholder="Select"
                                required
                              />
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Control type="number" step="any" name="value"
                                onChange={(e) => handleCompoundChange(index, 'value', e.target.value)}
                                value={row.value} />
                            </Form.Group>



                          </Row>

                          <Row key={index + 100} className="mb-3">

                          </Row>
                        </div>
                      ))
                    }

                    < Form.Group
                      as={Col}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddRow} />
                      <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteRow(e)} />
                    </Form.Group>
                  </>
                )
              },
            ]}

          />
        </>}

    </div >);


  const modalFooter = (
    <div>
   
    </div>
  );



  return (
    <>

     

          <AddModal
            showModal={showAddModal}
            toggleModal={() => addModal('production')}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ product, productUnits, productCompounds }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={() => editModal('production', null)}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, product, productUnits, productCompounds }}
          />

    </>
  )
}

export default ProductionProduct;
