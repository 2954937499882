import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import { numberFormatter } from '../../dynamic/numberFormatter';

const Net = (props) => {

  const { showModal, toggleModal, setNet} = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let value= parseFloat(document.getElementById('value').value);
    if (!isNaN(value)) {
      setNet(value);
    toggleModal();
    }
    else{
      toast.error('please enter value')
    }
  }
  


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Change Net</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>



      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Price</label>
      <div className="col-sm-10">
      <input type="number" step="any" defaultValue='' name="value" id="value" className="form-control" autoFocus></input>
      </div>
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Change Net"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default Net;