import React, { useState, useEffect, useContext  } from 'react';


function SearchFilter(props) {

  const {setFilter} = props;


  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setFilter(prevFilter => ({...prevFilter, search: e.target.value}));
    }
  };
  

  const handleSearchChange = (e) => {
    if(e.target.value == ''){
      setFilter(prevFilter => ({...prevFilter, search: e.target.value}));
    }
  };


 
  return (
    <div >

        <input type="search" className="form-control search" placeholder="Search..." 
        onKeyDown={(e) => handleSearch(e)}
        onChange={(e) => handleSearchChange(e)}>
        </input>

    </div>
  )
}

export default SearchFilter;
