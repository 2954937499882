import React, { useState, useEffect, createContext } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import CartData from './cartData';
import Loader from './loader';

import { link } from '../dynamic/api';

export const DataContext = createContext();
export const CartContext = createContext();

function GetData(props) {

  const { setAccess, userName, token, expireyDate, localApi, serverApi, devSetting } = props;


  let api;
  if (!devSetting?.isServer && userName !== 'dev') {
    api = serverApi;
  } else {
    api = localApi;
  }

  const [loaderEffect, setLoaderEffect] = useState(true);


  const [salableData, setSalableData] = useState([]);

  const [productData, setProductData] = useState([]);
  const [refProduct, setRefProduct] = useState([]);

  const [accountData, setAccountData] = useState([]);


  const [settingData, setSettingData] = useState(0);
  const [refSetting, setRefSetting] = useState(0);



  const [deliveryData, setDeliveryData] = useState([]);

  const [tablesData, setTablesData] = useState([]);
  const [refTablesData, setRefTablesData] = useState([]);


  const [printerData, setPrinterData] = useState([]);
  const [refPrinter, setRefPrinter] = useState([]);


  const [userData, setUserData] = useState('');

  const [allUserData, setAllUserData] = useState([]);

  const [wholeSale, setWholeSale] = useState('');

  //new Edition
  const [chartData, setChartData] = useState([]);

  const [devData, setDevData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [wareHouseData, setWareHouseData] = useState([]);


  // Data to get
  useEffect(() => {
    refDevData();
  }, []);


  const refreshProduct = () => {
    setRefProduct(!refProduct)
  }

  const refreshPrinter = () => {
    setRefPrinter(!refPrinter)
  }

  const refreshTables = () => {
    setRefTablesData(!refTablesData)
  }

  const refreshSetting = () => {
    setRefSetting(!refSetting)
  }





  // Get Printer Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = { isActive: true };
        const response = await api.get(`/printer/getAll`, { params: data });
        if (response) {
          setPrinterData(response.data.data);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
    fetchData();
  }, [refPrinter]);


  // Get Tables Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = { isActive: true };
        const response = await api.get(`/tables/getAll`, { params: data });
        if (response) {
          setTablesData(response.data.data);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
    fetchData();
  }, [refTablesData]);



  // Tempor run
  useEffect(() => {
    refWareHouseData();
    refCategory();
  }, []);


  // Get All User Data
  const refAllUserData = async () => {
    try {
      const data = { filter: { isActive: 'true' } };
      const response = await api.get(`/user/getAll`, { params: data });
      if (response) {
        setAllUserData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };




    // Get Role
    const refRole = async () => {
      try {
        let data = { filter: { isActive: 'true' } };
        const response = await api.get(`/role/getAll`, { params: data });
        if (response) {
          setRoleData(response.data.data);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
  


  // Get Category
  const refCategory = async () => {
    try {
      let data = { filter: { isActive: 'true' } };
      const response = await api.get(`/category/getAll`, { params: data });
      if (response) {
        setCategoryData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };




  // Get User Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = { userName: userName };
        const response = await api.get(`/user/getUser`, { params: data });
        if (response) {
          setUserData(response.data.data);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
    fetchData();
  }, []);



  //Ref Account data
  const refAccountData = async (type) => {
    try {
      let data = { filter: { isActive: 'true', isDropDown: 'true' , type: type} };
      const response = await api.get(`/account/getAll`, { params: data });
      if (response) {
        setAccountData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

  //Ref Delivery data
  const refDeliveryData = async () => {
    try {
      let data = { filter: { isActive: 'true' } };
      const response = await api.get(`/delivery/getAll`, { params: data });
      if (response) {
        setDeliveryData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  //Ref product data
  const refProductData = async () => {
    try {
      let data = { filter: { isActive: 'true' } };
      const response = await api.get(`/product/getAll`, { params: data });
      if (response) {
        setProductData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

  // Get wareHouses
  const refWareHouseData = async () => {
    try {
      let data = { filter: { isActive: 'true' } };
      const response = await api.get(`/wareHouse/getAll`, { params: data });
      if (response) {
        setWareHouseData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };




  // Get Settings
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/Setting/getAll`);
        if (response) {
          setSettingData(response.data.data[0]);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
    fetchData();
  }, [refSetting]);



  const refSalableData = async (wholeSale) => {
    if (userData && settingData) {
      let data = {
        filter: {
          isActive: true,
          wareHouseId: userData.role.wareHouseSaleId,
        }
      };
      await api.get(`/product/getSalable`, { params: data }).then((results) => {
        if (results) {
          const products = results.data.data;
          if(wholeSale){
            products.forEach(item => {
              item.price = item.wholeSalePrice;
            });
          }          
          setSalableData(products);
        }
      }).catch((error) => {
        toast.error('' + error);
      });
    }
  }



  // Chart Data
  const refChartData = async (search) => {
    try {
      let data = { filter: { search: search } };
      const response = await api.get(`/chart/getAll`, { params: data });
      if (response) {
        setChartData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Get DevData using localApi
  const refDevData = async () => {
    try {
      let data = { isActive: true };
      const response = await localApi.get(`/dev/getAll`, { params: data });
      if (response) {
        setDevData(response.data.data[0]);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };



  return (
    <div>

      <DataContext.Provider
        value={{
          setLoaderEffect,
          expireyDate,
          api,
          userData,
          productData,
          refreshProduct,

          settingData,
          refreshSetting,
          roleData,
          setAccess,

          tablesData,
          refreshTables,
          printerData,
          refreshPrinter,

          // new structure
          refChartData,
          chartData,

          salableData,
          refSalableData,

          devData,
          refDevData,

          serverApi,
          localApi,

          categoryData,
          refCategory,

          roleData,
          refRole,

          refProductData,
          productData,

          wareHouseData,
          refWareHouseData,


          accountData,
          refAccountData,

          refDeliveryData,
          deliveryData,

          refAllUserData,
          allUserData,

          devSetting,


        }}>


        {loaderEffect &&
          <Loader />
        }


        {userData && settingData &&
          <CartData setAccess={setAccess} settingData={settingData} userData={userData} />
        }



      </DataContext.Provider>




    </div>

  );
}


export default GetData;