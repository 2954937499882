import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import { NavDropdown } from 'react-bootstrap';

import StockActivity from './stockActivity/stockActivity';
import Warranty from './warranty/warranty';
import StockValue from './stockValue/stockValue';
import StockControl from './stockControl/stockControl';

import { DataContext } from '../init/getData';

import { FcSettings, FcSurvey, FcSalesPerformance, FcApproval, FcDeployment, FcComboChart } from "react-icons/fc";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { MdOutlineDiscount } from "react-icons/md";

function StockManagementTab(props) {

  const navigate = useNavigate();

  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('stockActivity');

  useEffect(() => {
    if (userRole.stockActivityPage) {
      navigate('/stockManagementTab/stockActivity');
    }
  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>


        {userRole.stockActivityPage &&
          <NavLink to="stockActivity" onClick={() => setActiveTab('stockActivity')}>
            <div className={`${activeTab === 'stockActivity' ? 'tab-two active-link' : 'tab-two'}`}>
              <FaArrowRightArrowLeft size={25} />
              <span className="tab-text">Stock Activity</span>
            </div>
          </NavLink>
        }


        {userRole.stockValuePage &&
          <NavLink to="stockValue" onClick={() => setActiveTab('stockValue')}>
            <div className={`${activeTab === 'stockValue' ? 'tab-three active-link' : 'tab-three'}`}>
              <FcSalesPerformance size={30} />
              <span className="tab-text">Stock Value</span>
            </div>
          </NavLink>
        }


        {(settingData.serializedProduct && userRole.warrantyPage) &&
          <NavLink to="warranty" onClick={() => setActiveTab('warranty')}>
            <div className={`${activeTab === 'warranty' ? 'tab-four active-link' : 'tab-four'}`} >
              <FcApproval size={33} />
              <span className="tab-text">Warranty</span>
            </div>
          </NavLink>
        }

        {userRole.stockControlPage &&
          <NavLink to="stockControl" onClick={() => setActiveTab('stockControl')}>
            <div className={`${activeTab === 'stockControl' ? 'tab-seven active-link' : 'tab-seven'}`} >
              <FcSettings size={30} />
              <span className="tab-text">Stock Control</span>
            </div>
          </NavLink>
        }


      </div>


      <Routes>

        <Route path='stockActivity' element={<StockActivity />} />
        <Route path='stockValue' element={<StockValue />} />
        <Route path='warranty' element={<Warranty />} />
        <Route path='stockControl' element={<StockControl />} />

      </Routes>

    </div>
  );
}

export default StockManagementTab;
