import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

const TableEdit = (props) => {

  const { api, userData, refreshTables } = useContext(DataContext);

  const {showModal, toggleModal, idRow, refreshTable, tittle} = props;


  const [loadingEffect, setLoadingEffect] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingEffect(true);
    e.preventDefault();
    const form = document.getElementById("editGroup");
      
    const formData = new FormData(form);
    formData.append("id", idRow.id)
    const data = Object.fromEntries(formData);

    if(!data['name']){
      toast.error('please enter name')
      setLoadingEffect(false);
      return;
    }

    if(!data['position']){
      toast.error('please enter position')
      setLoadingEffect(false);
      return;
    }
    
    
    let res = await api.post(`/tables/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      toggleModal();
      refreshTables();
      form.reset();
      refreshTable();
      setLoadingEffect(false);
    }else{
      toast.error(res.data.message);
      setLoadingEffect(false);
    }
  }

 
    return (
      <Modal show={showModal} backdrop="static" onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleSubmit} encType="multipart/form-date" id="editGroup">
          <div className="modal-body">

          <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-10">
          <input type="text" name="name" defaultValue={idRow.name} id="name" className="form-control"></input>
          </div>
          </div>

          <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">Position</label>
          <div className="col-sm-10">
          <input type="number" name="position" id="position" defaultValue={idRow.position} className="form-control"></input>
          </div>
          </div>

          <div className="mb-3 row">
          <label  class="col-sm-2 col-form-label">Details</label>
          <div className="col-sm-10">
          <textarea className="form-control" defaultValue={idRow.details} name="details" id="details"></textarea>
          </div></div>

          </div>
  
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>Save Data
          {loadingEffect && 
          <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
            }
          </button>
          </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default TableEdit;
