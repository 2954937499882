import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';


const Detail = (props) => {

  const { showModal, toggleModal, addDetails, selectedRow} = props;

  const [details, setDetails] = useState();

  useEffect(() => {
    if (selectedRow) {
      setDetails(selectedRow.details);
    }
  }, [selectedRow]);
  

  
  const addDetail = async (e) => {
    addDetails(selectedRow, details);
    toggleModal(); 
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Enter Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form  id='addForm'>

 
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Details</label>
      <div className="col-sm-10">
      <textarea rows="2"  className="form-control" value={details} onChange={(e) => setDetails(e.target.value)}></textarea>
      </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={addDetail}>
          Add
        </button>
      </div>


      </form>
      </Modal.Body>
      </Modal>
      )
}


export default Detail;