import React, { useState, useEffect, useContext  } from 'react'



import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';
import SalesModal from './salesModal';

function DineIn() {

  const { api, userData, tablesData, settingData } = useContext(DataContext);
  
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(false);


  const selectTable = (tableId) => {
    
    if(tableId){
      setSelectedTable(tableId);
      setShowSalesModal(!showSalesModal)
    } else{
      setShowSalesModal(!showSalesModal)
      setSelectedTable('');
    }   
   
  }


 

  
  return (
    <div className='card'>

    
{tablesData && (    

<div className='card-container' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))', gap: '10px' }}>
  {Array.from({ length: Math.max(...tablesData.map(table => table.position)) }).map((_, index) => {
    const table = tablesData.find(table => table.position === String(index + 1));
    return (
      <div
        className={table ? 'card' : ''}
        onClick={table ? () => selectTable(table) : null}
        key={index}
        style={{
          border: table ? '1px solid #000' : 'none', 
          margin: '0px',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
          height: '100px',
          cursor: 'pointer',
          gridColumn: `${(index % 11) + 1} / span 1`, // Adjust column position
          gridRow: `${Math.floor(index / 11) + 1} / span 1`, // Adjust row position
        }}
      >
        {table && (
          <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>{table.name}</div>
        )}
      </div>
    );
  })}
</div>
)}  
 
      {showSalesModal &&
      <SalesModal showModal={showSalesModal} toggleModal={() => setShowSalesModal(!showSalesModal)}  tableId={selectedTable}  />
      }
    </div>
  )
}

export default DineIn;
