import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';


import GeneralSetting from './generalSettings';
import UserHeader from './user/userHeader';
import License from './license/license';
import PiSetting from './piSetting/piSetting';
import ProductSetting from './productSetting/productSetting';
import SaleSetting from './saleSetting/saleSetting';
import BackUp from './backUp/backUp';
import Barcode from './barcode/barcode';
import Role from './role/role';
import User from './user/user';

import { FcSupport, FcManager, FcPackage, FcReadingEbook, FcPrint, FcTimeline, FcExport } from "react-icons/fc";
import { IoMdCart } from "react-icons/io";
import { BiBarcodeReader } from "react-icons/bi";

import { DataContext } from '../init/getData';


function SettingTab(props) {

  const { settingData, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const navigate = useNavigate();

  const [showBackUpModal, setShowBackUpModal] = useState(false);
  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    console.log(userRole)
    if (userRole.generalPage) {
      navigate('/settingTab/general');
    }
  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        {userRole.generalPage &&
          <NavLink to="general" onClick={() => setActiveTab('general')}>
            <div className={`${activeTab === 'general' ? 'tab-one active-link' : 'tab-one'}`} style={{ width: '10%' }}>
              <FcSupport size={35} />
              <span className="tab-text">General</span>
            </div>
          </NavLink>
        }


        {userRole.userPage &&
          <NavLink to="user" onClick={() => setActiveTab('user')}>
            <div className={`${activeTab === 'user' ? 'tab-two active-link' : 'tab-two'}`} style={{ width: '10%' }}>
              <FcManager size={35} />
              <span className="tab-text">Users</span>
            </div>
          </NavLink>
        }

        {userRole.rolePage &&
          <NavLink to="role" onClick={() => setActiveTab('role')}>
            <div className={`${activeTab === 'role' ? 'tab-three active-link' : 'tab-three'}`} style={{ width: '10%' }}>
              <FcReadingEbook size={35} />
              <span className="tab-text">Roles</span>
            </div>
          </NavLink>
        }

        {userRole.saleSettingPage &&
          <NavLink to="sales" onClick={() => setActiveTab('sales')}>
            <div className={`${activeTab === 'sales' ? 'tab-four active-link' : 'tab-four'}`} style={{ width: '10%' }}>
              <IoMdCart size={35} />
              <span className="tab-text">Sales</span>
            </div>
          </NavLink>
        }

        {userRole.piSettingPage &&
          <NavLink to="purchaseInvoice" onClick={() => setActiveTab('purchaseInvoice')}>
            <div className={`${activeTab === 'purchaseInvoice' ? 'tab-four active-link' : 'tab-four'}`} style={{ width: '10%' }}>
              <FcPrint size={35} />
              <span className="tab-text">Purchase Invoice</span>
            </div>
          </NavLink>
        }

        {userRole.productSettingPage &&
          <NavLink to="products" onClick={() => setActiveTab('products')}>
            <div className={`${activeTab === 'products' ? 'tab-one active-link' : 'tab-one'}`} style={{ width: '10%' }}>
              <FcPackage size={33} />
              <span className="tab-text">Products</span>
            </div>
          </NavLink>
        }


        {userRole.lisencePage &&
          <NavLink to="license" onClick={() => setActiveTab('license')}>
            <div className={`${activeTab === 'license' ? 'tab-six active-link' : 'tab-six'}`} style={{ width: '10%' }}>
              <FcTimeline size={35} />
              <span className="tab-text">License</span>
            </div>
          </NavLink>
        }

        <NavLink to="barcode" onClick={() => setActiveTab('barcode')}>
          <div className={`${activeTab === 'barcode' ? 'tab-two active-link' : 'tab-two'}`} style={{ width: '10%' }}>
            <BiBarcodeReader size={30} />
            <span className="tab-text">Barcode</span>
          </div>
        </NavLink>


        <div className="tab-seven" style={{ width: '10%', cursor: 'pointer' }} onClick={() => setShowBackUpModal(!showBackUpModal)} >
          <FcExport size={30} />
          <span className="tab-text">Back Up</span>
        </div>


      </div>


      <BackUp showModal={showBackUpModal} toggleModal={() => setShowBackUpModal(!showBackUpModal)} />



      <Routes>

        <Route path='general' element={<GeneralSetting />} />
        <Route path='sales' element={<SaleSetting />} />
        <Route path='purchaseInvoice' element={<PiSetting />} />
        <Route path='products' element={<ProductSetting />} />
        <Route path='user' element={<User />} />
        <Route path='role' element={<Role />} />
        <Route path='license' element={<License />} />
        <Route path='barcode' element={<Barcode />} />

      </Routes>

    </div>
  );
}

export default SettingTab;
