import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import EndOfDayReport from './modals/report';
import EndOfDay from './modals/endOfDay';
import DropDownFilter from '../../components/filters/dropDownFilter';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { FcKindle } from "react-icons/fc";

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';




function BalanceAndVariance(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, devData, wareHouseData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const defaultWareHouseId = userRole.wareHouseSaleId;


  const today = new Date().toISOString().split('T')[0];
  const type = ['EndOfDay']


  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({
    search: '', isActive: 'true', fromDate: today,
    toDate: today, isOrder: false, type: type, wareHouseId: null, isBank: false
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEndOfDayModal, setShowEndOfDay] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const [name, setName] = useState('');
  const [category, setCategory] = useState([]);

  const table = 'transaction';
  const title = 'Balance And Variance';

  const selectedWareHouse = wareHouseData.find(option => option.id == filter.wareHouseId);


  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const resetForm = () => {
    setCategory({ createdBy: createdBy })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const reportModal = (e) => {
    if(!filter.wareHouseId){
      toast.error('please select '+ devData.wareHouseName)
      return
    }
    if (!showReportModal) {
      setIdRow(e);
    }
    setShowReportModal(!showReportModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    console.log('ya ali')
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const endOfDayModal = () => {
    setShowEndOfDay(!showEndOfDayModal);
  }

  // idRow Change
  useEffect(() => {
    setCategory(
      {
        name: idRow?.name || '',
        createdBy: createdBy,
      }
    )
  }, [idRow]);


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        name: item.name,
      };

      transformedData.push(newItem);
    });

    return { transformedData };
  };



  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'openingBalance', dataIndex: 'openingBalance', width: '10%', render: numberFormatter },
    { title: 'Balance', dataIndex: 'balance', width: '7%', render: numberFormatter },
    { title: 'closingBalance', dataIndex: 'closingBalance', width: '10%', render: numberFormatter },
    { title: 'Variance', dataIndex: 'total', width: '10%', render: numberFormatter },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'cum Balance', dataIndex: 'cumulativeBalance', render: numberFormatter },
    { title: 'User', dataIndex: 'created', render: (created) => (created?.name) },
  ];


  useEffect(() => {

    newColumnDefs.push(
      {
        title: 'Rpt',
        width: '4%',
        render: (_, record) => (
          <FcKindle size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => reportModal(record)} />
        ),
      },
      {
        title: 'Del',
        width: '4%',
        render: (_, record) =>
          <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />
      }
    );


    setColumnDefs(newColumnDefs);


  }, [data]); // Include `data` in the dependency array


  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={category.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Row>

    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        <span className='table-footer footer-green'>Total In: {numberFormatter(data?.summary?.totalIn, 2)} {settingData.currency}</span>
        <span className='table-footer footer-red'>Total Out: {numberFormatter(data?.summary?.totalOut, 2)} {settingData.currency}</span>
        <span className='table-footer footer-one'>Variance: {numberFormatter(data?.summary?.totalIn - data?.summary?.totalOut, 2)} {settingData.currency}</span>
      </div>
    </div>

  );


  return (
    <div className='card'>
      <div>

        <SearchFilter setFilter={setFilter} />
        <DatePicker setFilter={setFilter} filter={filter} />
        <CustomButton tittle={'End Of Day'} method={endOfDayModal} color={'#e64753'} addModal={addModal} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
      </div>

      {loadingCounter < 1 &&
        <Loader />
      }


      {loadingCounter == 1 &&
        <>

          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />


          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ category }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, category }}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, transaction: {type: 'EndOfDay'}}}
          />


          <EndOfDay
            showModal={showEndOfDayModal}
            toggleModal={endOfDayModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            api={api}
            wareHouseId={filter.wareHouseId}
            createdBy={createdBy}
            settingData={settingData}
          />


          <EndOfDayReport
            showModal={showReportModal}
            toggleModal={reportModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            api={api}
            wareHouse={selectedWareHouse}
            createdBy={createdBy}
            settingData={settingData}
          />

        </>
      }

    </div>
  )
}

export default BalanceAndVariance;
