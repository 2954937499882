import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { DataContext } from '../../../init/getData';
import toast from 'react-hot-toast';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { numberFormatter } from '../../../components/formatters/numberFormatter';
import WareHouse from '../../../accountTab/wareHouse/wareHouse';



function EndOfDay(props) {

  const { api, showModal, toggleModal, settingData, table, refreshTable, idRow, refreshData, data, createdBy, wareHouseId } = props;
  const [closingBalance, setClosingBalance] = useState(0);
  const [loadingEffect, setLoadingEffect] = useState(false);

  const { devData } = useContext(DataContext);




  //Excel Data
  const endOfDay = async () => {

    if(!closingBalance && closingBalance !== 0){
      toast.error('please enter balance')
      return
    }

    if(!wareHouseId){
      toast.error('please select ' + devData.wareHouseName)
      return
    }


    setLoadingEffect(true);

    const data = {
      createdBy: createdBy,
      wareHouseId: wareHouseId,
      closingBalance: closingBalance,
    }

    try {
      let res = await api.post(`balanceAndVariance/endOfDay`, data);

      if (res.data.status) {
        toast.success(res.data.message);
        toggleModal();
        setClosingBalance(0);
        refreshTable();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Failed Contact Support");
    } finally {
      setLoadingEffect(false);
    }

  };

  return (
    <Modal show={showModal} onHide={toggleModal} backdrop='static'>
      <Modal.Header style={{ background: "#e6434f" }}>
        <Modal.Title className="arabicText" style={{ color: "white" }}>End Of Day</Modal.Title>
      </Modal.Header>
      <Modal.Body className="arabicText">

        <Row className="mb-3">
          {/* Name  */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Ending Balance<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="text" name='name'
              value={closingBalance}
              onChange={e => setClosingBalance(e.target.value)}
              required
            />
          </Form.Group>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <p style={{ margin: 0, flexGrow: 1, color: 'green', fontWeight: 'bold', paddingLeft: '6px' }}> {numberFormatter(closingBalance)}{settingData.currency}</p>
          <div>
            <Button type="primary" size="large" onClick={toggleModal} style={{ backgroundColor: '#6d747d' }}>Cancel</Button>
            <Button type="primary" size="large" loading={loadingEffect} onClick={endOfDay} style={{ marginLeft: '10px' }} danger>Yes</Button>
          </div>
        </div>
      </Modal.Footer>


    </Modal>
  );
}

export default EndOfDay;
