import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import moment from 'moment';




import { DataContext } from '../../init/getData';
import { dateViewerFormatter} from '../../dynamic/dateFormatter';
import { isOutFormatter} from '../../dynamic/colorFormatter';

import toast from 'react-hot-toast';

const dataFormat = require("../../dynamic/date");


function CostChangesTable(props) {

  const { api, userData, settingData } = useContext(DataContext);

  const { fromDate, toDate, showAddModal, addModal, search, setTotal } = props;

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);
  const timeoutRef = useRef(null);  

//-----------------------------------------------------------------




 
  useEffect(() => {

  let newColumnDefs = [
    { field: 'id', flex: 0.35},
    { field: 'createdAt', valueFormatter: dateViewerFormatter , flex: 0.85},
    { field: 'product.barcode', headerName : 'Barcode', flex: 0.7},
    { field: 'product.name', headerName : 'Name', flex: 0.65},
    { field: 'oldQuantity', headerName : 'Old Qty', flex: 0.55},
    { field: 'oldCost', flex: 0.55},
    { field: 'quantity', headerName : 'P-Qty', flex: 0.45},
    { field: 'purchaseCost', headerName : 'P-Cost', flex: 0.5},
    { field: 'newCost', flex: 0.6},
    { field: 'method', flex: 0.65},
    { field: 'transactionId', headerName : 'Invoice', flex: 0.55},
    { field: 'total', headerName : 'Total ' + settingData.currency , cellRenderer: isOutFormatter, flex: 0.6},
  ];

  setColumnDefs(newColumnDefs); 

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

    }, [fromDate, toDate]);


  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
        search: search,
        fromDate: fromDate,
        toDate: toDate,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/costChanges/getAll`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        setTotal( response.data.total)
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [fromDate, toDate]);


useEffect(() => { 

  if (search.length > 0) {
  setSearchState(true);

  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  timeoutRef.current = setTimeout(() => {

  var datasource = {
  getRows(params) {

  let data = {
  search: search,
  fromDate: fromDate,
  toDate: toDate,
  };

  api.get(`/stockValue/getall`, {params: data}).then(response => {
  params.successCallback(response.data.data, response.data.totalRecords);
  if(!response.data.status){
  toast.error('Failed to get Data')
  }})}}
  gridApi.api.setServerSideDatasource(datasource);
  }, 1000);
  }else{
  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  if (searchState) onGridReady(gridApi);
  }
  }, [search]);
  
  
  return (
    <div>     
    
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '70vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={60}
        cacheBlockSize={60}
        />
      </div>

      
    </div>
  );
}

export default CostChangesTable;
