import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function WareHouse(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, devData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true' });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);


  const [name, setName] = useState('');
  const [wareHouse, setWareHouse] = useState([]);

  const table = 'wareHouse';
  const title = devData.wareHouseName;


  const addModal = () => {
    const recordCount = data.data ? data.data.length : 0; // Access the data property of the object
    console.log("Current records count:", recordCount);
    console.log("Limit:", devData.wareHouseCreationLimit);
  
    if (recordCount >= devData.wareHouseCreationLimit) {
      toast.error(`You have exceeded the maximum number of ${devData.wareHouseName}`);
      return;
    }
    
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }
  
  

  const resetForm = () => {
    setWareHouse({ createdBy: createdBy })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // idRow Change
  useEffect(() => {
    setWareHouse(
      {
        name: idRow?.name || '',
        createdBy: createdBy,
      }
    )
  }, [idRow]);


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setWareHouse((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        name: item.name,
      };

      transformedData.push(newItem);
    });

    return {transformedData};
  };

  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '5%', sorter: (a, b) => a.age - b.age },
    { title: 'Name', dataIndex: 'name' },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '5%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={wareHouse.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Row>

    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (
    <></>
  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />
            <AddButton addModal={addModal} />

          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ wareHouse }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, wareHouse }}
          />


        </>
      }

    </div>
  )
}

export default WareHouse;
