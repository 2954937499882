import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';

export const selectedMissionContext = createContext();

function RecoverModal(props) {

  const { api, showModal, toggleModal, title, table, refreshTable, idRow, refreshData } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);

  const { settingData } = useContext(DataContext);

  const trigger = async () => {
    setLoadingEffect(true);
    try {
      let data = { id: idRow.id }
      let res = await api.post(`${table}/recover`, JSON.stringify(data));
      if (res.data.status) {
        toast.success(res.data.message);
        toggleModal();
        if (refreshTable) { refreshTable() };
        if (refreshData) { refreshData() };
      } else {
        toast.error(res.data.message);
      }
      setLoadingEffect(false);
    } catch (error) {
      toast.error("Failed Contact Support");
    } finally {
      setLoadingEffect(false);
    }
  }


  return (
    <Modal show={showModal} onHide={toggleModal} backdrop='static' className='Delete_modal'>
      <Modal.Header style={{ background: "#1d7790" }}>
        <Modal.Title className="arabicText" style={{ color: "white" }}>Recover {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="arabicText">
        Record will be recovered, are you sure ?
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button type="primary" size="large" onClick={toggleModal} style={{ backgroundColor: '#6d747d' }} >cancel</Button>
          <Button type="primary" size="large" loading={loadingEffect} onClick={trigger}
            style={{ marginLeft: '10px', backgroundColor: '#1d7790' }} danger>Yes</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default RecoverModal;
