import React, { useState, useEffect, useContext  } from 'react';



import { Flex, Spin } from 'antd';


function Loader(props) {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
    <Flex align="center" justify="center" gap="middle">
      <Spin size="large" />
    </Flex>
  </div>
  )
}

export default Loader;
