import React, { useState, useEffect, useContext  } from 'react';


function IsActiveFilter(props) {

  const {setFilter} = props;

 
  return (
    <div >

         
<select name="groupId" className="form-control dropDown" id="groupId" 
        onChange={ (e) => setFilter(prevFilter => ({
          ...prevFilter,
          isActive: e.target.value
        }))
         }
         >
          <option value='true' >Active</option>
          <option value='false'>Trashed</option>
        </select>
        

    </div>
  )
}

export default IsActiveFilter;
