import React, { useState, useEffect, useContext  } from 'react';

import UserTable from './userTable';

import toast from 'react-hot-toast';






function UserHeader() {

  
 
  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');

  const [search, setSearch] = useState('');



  const addModal = () => {
    setShowAddModal(prevshowAddModal => !prevshowAddModal);
  }

  

const isActiveChange = (e) => {
    setIsActive(e);
};



  
  return (
    <div className='card'>

    
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>


        
        <select name="groupId" className="form-control dropDown" id="groupId" onChange={(e) => isActiveChange(e.target.value)} >
          <option value='true' >Active</option>
          <option value='false'>Deleted</option>
        </select>
      

            <button onClick={addModal} type="button" className="btn btn-add">
              Add
            </button>
         


      </div>
    
      <UserTable
      showAddModal={showAddModal}
      addModal={addModal}
      isActive={isActive}
      search={search} />
      

    </div>
  )
}

export default UserHeader;
