import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { userId } from "../../dynamic/token";

const Void = (props) => {

  const { showModal, toggleModal, closePaymentDone} = props;

  
  const clear = async (e) => {
    
    closePaymentDone();
    toggleModal();

  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Clear All Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: '10px'}}>
      Are you sure ?
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={clear}>
          Yes Clear
        </button>
       
      </div>



      </Modal.Body>
      </Modal>
      )
}


export default Void;