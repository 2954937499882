import React, { useState, useEffect, useContext  } from 'react';



import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';

function License() {

  const {settingData, userData, setAccess, expireyDate} = useContext(DataContext);
 
  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');

  const [search, setSearch] = useState('');





  
  return (
    <div className='card' style={{height : '88vh'}}>

     <a>Licensed For : </a> 
     <a>Expirey Date: {expireyDate}</a> 

    </div>
  )
}

export default License;
