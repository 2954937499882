import React, { useState, useEffect, useContext  } from 'react';


function AddButton(props) {

  const {addModal} = props;

 
  return (
    <div >

  
        <button onClick={addModal} type="button" className="btn btn-add"  >
          Add
          </button>

    </div>
  )
}

export default AddButton;
