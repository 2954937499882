import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';

import { DataContext } from '../../init/getData';

function DropDownFilter(props) {

  const { setFilter, filterBy, placeHolder, setLoadingCounter, filter } = props;

  const { userData, settingData, api, wareHouseData, refWareHouseData,
    refCategory, categoryData, refProductData, productData,
    refAllUserData, allUserData, accountData, refAccountData, deliveryData, refDeliveryData } = useContext(DataContext);

  let getData;
  let filterSelected;

  //Get Data 
  useEffect(() => {
    const fetchData = async () => {
      try {

        if (filterBy == 'wareHouseId') {
          await refWareHouseData();
        } else
          if (filterBy == 'categoryId') {
            await refCategory();
          } else
            if (filterBy == 'productId') {
              await refProductData();
            } else
              if (filterBy == 'createdBy') {
                await refAllUserData();
              } else
                if (filterBy == 'accountId') {
                  await refAccountData();
                } else
                  if (filterBy == 'deliveryId') {
                    await refAccountData();
                  } else
                    if (filterBy == 'bankId') {
                      await refAccountData();
                    }

        setLoadingCounter();
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, [filter.accountType, filterBy]);

  if (filterBy == 'wareHouseId') {
    getData = wareHouseData;
    filterSelected = filter.wareHouseId;
  } else
    if (filterBy == 'categoryId') {
      getData = categoryData;
      filterSelected = filter.categoryId;
    } else
      if (filterBy == 'productId') {
        getData = productData;
        filterSelected = filter.productId;
      } else
        if (filterBy == 'createdBy') {
          getData = allUserData;
          filterSelected = filter.createdBy;
        } else
          if (filterBy == 'accountId') {
            if(filter.accountType){
            getData = accountData?.filter(account => account.type === filter.accountType);
            }else{
              getData = accountData;
            }
            filterSelected = filter.accountId;
          } else
            if (filterBy == 'deliveryId') {
              getData = accountData?.filter(account => account.type === 'Delivery');
              filterSelected = filter.deliveryId;
            } else
              if (filterBy == 'bankId') {
                getData = accountData?.filter(account => account.type === 'Bank');
                filterSelected = filter.bankId;
              }

  // WareHouse Options
  const dataOptions = getData?.map(item => ({
    value: item.id,
    label: (filterBy == 'accountId' || filterBy == 'bankId') ? item.name + ' [' + item.phone + '] ' : item.name,
  }))

  dataOptions.push({ value: null, label: placeHolder })
  const selectedValue = dataOptions.filter(option => option.value === filterSelected);

  return (
    <div >


      <Select
        className='dropDownMedium'
        options={dataOptions}
        isSearchable={true}
        required
        placeholder={placeHolder}
        value={selectedValue}
        onChange={(selectedValue) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            [filterBy]: selectedValue.value
          }))
        }
      />


    </div>
  )
}

export default DropDownFilter;