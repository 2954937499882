import React, {useState, useEffect} from 'react';


function DatePicker(props) {

  const { setFilter, filter } = props;

  const fromDateChange = (date) => {
      setFilter(prevFilter => ({
        ...prevFilter,
        fromDate: date,
      }));
    }


    const toDateChange = (date) => {
      setFilter(prevFilter => ({
        ...prevFilter,
        toDate: date,
      }));
    }
  

  return (
    <div>

        <input type="date" name="fromDate" id="fromDate"  className="form-control date"
        value={filter.fromDate} onChange={(e) => fromDateChange(e.target.value)} >
        </input>


        <input type="date" name="fromDate" id="fromDate"  className="form-control date"
        value={filter.toDate}  onChange={(e) => toDateChange(e.target.value)} >
        </input>



    </div>
  );
}

export default DatePicker;
