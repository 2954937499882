import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch, Collapse, Button, Spin } from 'antd';


import Select from 'react-select';

import { DataContext } from '../../init/getData';

const { Panel } = Collapse;

function DevGeneralSetting() {

  const { api, userData, refreshSetting, settingData, refDevData, devData } = useContext(DataContext);  
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const [loadingEffect, setLoadingEffect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  //Types  
  const [wareHouseName, setWareHouseName] = useState(false);
  const [deliveryName, setDeliveryName] = useState(false);
  const [wareHouseCreationLimit, setWareHouseCreationLimit] = useState(false);
  const [userCreationLimit, setUserCreationLimit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refDevData();
        setIsLoading(false)
      } catch (error) {
        console.error('Error Getting Data');
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    if (devData) {
      setWareHouseName(devData.wareHouseName)
      setDeliveryName(devData.deliveryName)
      setWareHouseCreationLimit(devData.wareHouseCreationLimit)
      setUserCreationLimit(devData.userCreationLimit)
    }
  }, [devData]);


  const handleSubmit = async () => {

    setLoadingEffect(true);

    let settings = {
      wareHouseName,
      deliveryName,
      wareHouseCreationLimit,
      userCreationLimit,
    }

    const res = await api.post(`/dev/edit`, JSON.stringify({ settings }))
    if (res.data.status) {
      toast.success(res.data.message);
      refDevData();
    }
    else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }



  const NamingForm = (
    <>

      {/*  Default Date */}
      <Row className="mb-3">
        <Form.Label column sm={2}>
          Delivery Name
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" value={deliveryName} onChange={(e) => setDeliveryName(e.target.value)}>
            <option value="Delivery"> Delivery </option>
            <option value="Service"> Service </option>
            <option value="Barber"> Barber </option>
          </select>
        </Col>
      </Row>


      {/*  Default Date */}
      <Row className="mb-3">
        <Form.Label column sm={2}>
          WareHouse Name
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" value={wareHouseName} onChange={(e) => setWareHouseName(e.target.value)}>
            <option value="WareHouse"> WareHouse </option>
            <option value="Branch"> Branch </option>
            <option value="Project"> Project </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Warehouse Craetion Limit:
        </Form.Label>
        <Col sm={4}>
          <input
            type="number"
            className="form-control"
            name="wareHouseCreationLimit"
            placeholder="Enter Warehouse Limit"
            value={wareHouseCreationLimit}
            onChange={(e) => setWareHouseCreationLimit(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          User Craetion Limit:
        </Form.Label>
        <Col sm={4}>
          <input
            type="number"
            className="form-control"
            name="userCreationLimit"
            placeholder="Enter User Creation Limit"
            value={userCreationLimit}
            onChange={(e) => setUserCreationLimit(e.target.value)}
          />
        </Col>
      </Row>





    </>
  )


  const items = [
    {
      key: '1',
      label: 'Naming',
      children: <>{NamingForm}</>,
    }

  ];


  return (
    <div
      className="card" style={{ userSelect: 'none', height: '86vh', display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}
    >
      <Spin size="large" spinning={isLoading} style={{ margin: 'auto' }} />

      {!isLoading &&
        <>
          <Collapse className="custom-collapse" style={{ width: '100%' }} defaultActiveKey={['1']}>
            {items.map(item => (
              <Panel header={item.label} key={item.key} style={{ backgroundColor: '#d8e1e8' }}>
                {item.children}
              </Panel>
            ))}
          </Collapse>

          <Button type="primary" size="large" loading={loadingEffect} onClick={handleSubmit}

            style={{ marginLeft: '10px', backgroundColor: '#1d7790' }}>Save</Button>
        </>
      }

    </div>
  );
}

export default DevGeneralSetting;
