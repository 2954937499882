import React, { useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';


import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const ScaleEdit = (props) => {

  const { api, userData } = useContext(DataContext);

  const {showModal, toggleModal, idRow, refreshTable, tittle} = props;

  const {refreshCategory} = useContext(DataContext);

  
 

  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("Form");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    data['id'] = idRow.id;
    
    let res = await api.post(`/scale/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }

    refreshCategory();
    form.reset();
    refreshTable();
  }

 
    return (
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit} id='Form'>


          <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <input type="text"  name="name" id="name" defaultValue={idRow.name} className="form-control"></input>
          </Form.Group>

          <Form.Group as={Col}>
        <Form.Label>Barcode length</Form.Label>
        <input type="number"  name="digits" id="digits" defaultValue={idRow.digits} className="form-control"></input>
        </Form.Group>

        </Row>



        <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
        <Form.Label>Flag</Form.Label>
        <input type="number"  name="flag" id="flag" defaultValue={idRow.flag} className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
        <Form.Label>Code</Form.Label>
        <input type="number"  name="code" id="code" defaultValue={idRow.code} className="form-control"></input>
        </Form.Group>

        </Row>


        <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
        <Form.Label>Total</Form.Label>
        <input type="number"  name="price" id="price" defaultValue={idRow.price} className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
        <Form.Label>Weight</Form.Label>
        <input type="number"  name="weight" id="weight" defaultValue={idRow.weight} className="form-control"></input>
        </Form.Group>

        </Row>



        <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
        <Form.Label>Money Point</Form.Label>
        <input type="number"  name="moneyPoint" id="moneyPoint" defaultValue={idRow.moneyPoint} className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
        <Form.Label>Weight Point</Form.Label>
        <input type="number"  name="weightPoint" id="weightPoint" defaultValue={idRow.weightPoint} className="form-control"></input>
        </Form.Group>

        <Form.Group as={Col}>
        <Form.Label>Count Point</Form.Label>
        <input type="number"  name="countPoint" id="countPoint" defaultValue={idRow.countPoint} className="form-control"></input>
        </Form.Group>

        </Row>




          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                Close
              </button>
              <input type="submit" className="btn btn-primary" value="Edit Data"></input>
            </div>

            </Form>

        </Modal.Body>
      </Modal>
    );
  };
  
  export default ScaleEdit;
