import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

const RoleTrash = (props) => {

const { api, userData, refreshRole } = useContext(DataContext);
const userId = userData.id;
const userName = userData.name;
const userRole = userData.role.name;


const { showModal, toggleModal, idRow, refreshTable, tittle } = props;
const {refreshCustomer} = useContext(DataContext);

  const trigger = async () => {
    let data = {id: idRow.id}
    let res = await api.post(`role/trash`, JSON.stringify(data));
    if(res.data.status){
      toast.success(res.data.message);
      toggleModal();
      refreshRole();
      refreshTable();
    }else{
      toast.error(res.data.message);
    }
        
  
  }
  

    
  return (
    <Modal show={showModal} onHide={toggleModal} className='Delete_modal'>
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>Trash {tittle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {tittle} Will be Moved to trash ,
      are you sure ?
      
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>NO</button>
        <input type="button" className="btn btn-primary" onClick={trigger} value="Yes !!"></input>
      </Modal.Footer>
    </Modal>
  );

  }
export default RoleTrash;
