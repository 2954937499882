import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';

export const selectedMissionContext = createContext();

function DeleteModal(props) {

  const { api, showModal, toggleModal, title, table, refreshTable, idRow, refreshData, data } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);

  const { devData } = useContext(DataContext);




  const trigger = async () => {
    setLoadingEffect(true);
    try {
      let res = await api.post(`${table}/delete`, JSON.stringify(data));
      if (res.data.status) {
        toggleModal();
        if (refreshTable) { refreshTable() };
        if (refreshData) { refreshData() };
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setLoadingEffect(false);
    } catch (error) {
      toast.error("Failed Contact Support");
    } finally {
      setLoadingEffect(false);
    }
  }


  return (
    <Modal show={showModal} onHide={toggleModal} backdrop='static'>
      <Modal.Header style={{ background: "#e6434f" }}>
        <Modal.Title className="arabicText" style={{ color: "white" }}>Delete {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="arabicText">
        Record will be deleted, are you sure ?
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button type="primary" size="large" onClick={toggleModal} style={{ backgroundColor: '#6d747d' }} >cancel</Button>
          <Button type="primary" size="large" loading={loadingEffect} onClick={trigger}
            style={{ marginLeft: '10px' }} danger>Yes</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
