import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { numberFormatter } from '../../dynamic/numberFormatter';
import ReactToPrint from 'react-to-print';
import { DataContext } from '../../init/getData';
import { FcPortraitMode, FcAbout, FcInTransit, FcHome } from "react-icons/fc";

import { datePrintFormatter } from '../../dynamic/dateFormatter';
import { print } from "../../dynamic/print";

import { Button } from 'antd';

const ReportModal = (props) => {

  const { userData, settingData, serverApi, localApi, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;




  let api;
  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }


  const { showModal, toggleModal, idRow, report, reportRef } = props;


  const [loadingEffect, setLoadingEffect] = useState(false);



  return (
    <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal}>
      <Modal.Header style={{ background: "#39aaa5", display: "flex", justifyContent: "center" }}>
        <div>
          <Modal.Title style={{ color: "white" }}> Report </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>

        {report}
      </Modal.Body>

      <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
 
          <div style={{ display: 'flex', gap: '10px' }}>
            <button type="button" className="btn btn-secondary" style={{ backgroundColor: '#1f2937' }} onClick={toggleModal}>Close</button>
            
            <ReactToPrint trigger={() => <button type="button" style={{ backgroundColor: '#1d7790' }} className="btn btn-primary" >Print</button>} 
            content={() => reportRef.current} />
          </div>
        </div>

    </Modal>
  );
};

export default ReportModal;
